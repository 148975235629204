import Route from "./data_models/Route";

export const fallbackRoute: Route = {
  pathname: "/"
};

export const artistViewRoute: Route = {
  pathname: "/artist/:artistId"
};

export interface ArtistViewRouteProps {
  artistId: string;
}

export const permalinkRoute: Route = {
  pathname: "/:permalink"
};

export interface PermalinkRouteProps {
  permalink: string;
}

export const adminResidentAdvisorScraperRoute: Route = {
  pathname: "/admin/ra-scraper"
};
