import ReactGA from "react-ga";
import React from "react";
import SerializedEvent from "../../../data_models/Event/SerializedEvent";
import useClub from "../../../api/useClub";
import VisualCard from "../VisualCard/VisualCard";
import { usePlayEventSetPreviews } from "../../../redux/player_state/player_actions";
import { useFocusedOnEvent } from "../../../redux/top_level_state/top_level_actions";
import {
  isHookLoading,
  isHookErrorResponse
} from "../../../api/HookReturnValue";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import ErrorComponent from "../../ErrorComponent/ErrorComponent";
import GoogleAnalyticsCategory from "../../../data_models/enums/GoogleAnalyticsCategory";
import { useOpenEventInformationModal } from "../../../redux/modal_state/modal_actions";
import { useReduxSelector } from "../../../redux/redux_hooks";
import { getImageWithCrediteeToShowForEvent } from "../../../data_models/Event/event_functions";
import { isSetPlayable } from "../../../data_models/SetInformation/set_functions";
import artists from "../../../static_db/artists";

interface IProps {
  event: SerializedEvent;
}

export default function EventCardContainer({ event }: IProps) {
  const playEventAudio = usePlayEventSetPreviews();
  const focusOnEvent = useFocusedOnEvent();
  const openEventInformationModal = useOpenEventInformationModal();
  const useClubResult = useClub(event.clubId);
  const activeTrackPlayerState = useReduxSelector(
    store => store.player.activeTrackPlayerState
  );

  const isThisEventFocused =
    !!activeTrackPlayerState &&
    activeTrackPlayerState.currentSetPreviewInformation.eventId === event.id;
  const isPlayerPaused =
    !!activeTrackPlayerState && activeTrackPlayerState.isPaused;

  const onPlayClickHandler = () => {
    ReactGA.event({
      category: GoogleAnalyticsCategory.PLAYER_GOOGLE_ANALYTICS_CATEGORY,
      action: "Play Event",
      label: event.id
    });

    focusOnEvent(event.id);
    playEventAudio(event);
  };

  const viewMoreEventInformation = () => openEventInformationModal(event.id);

  if (isHookLoading(useClubResult)) {
    return <LoadingSpinner />;
  }

  if (isHookErrorResponse(useClubResult)) {
    return <ErrorComponent error={useClubResult} />;
  }

  const club = useClubResult;

  const isThereAtLeastOnePlayableSet = event.sets.some(set =>
    isSetPlayable(set, artists)
  );

  return (
    <VisualCard
      imageUrl={getImageWithCrediteeToShowForEvent(event, club).url}
      mainTitle={event.name}
      subTitle={"at " + club.name}
      onImageClick={viewMoreEventInformation}
      onMainTitleClick={viewMoreEventInformation}
      playButtonProps={{
        onPlayButtonClick: onPlayClickHandler,
        showPauseButton: isThisEventFocused && !isPlayerPaused,
        isDisabled: !isThereAtLeastOnePlayableSet,
        tooltip: getCardPlayPauseButtonTooltip({
          isThereAtLeastOnePlayableSet,
          isThisEventFocused,
          isPlayerPaused
        })
      }}
    />
  );
}

const getCardPlayPauseButtonTooltip = ({
  isThereAtLeastOnePlayableSet,
  isThisEventFocused,
  isPlayerPaused
}: {
  isThereAtLeastOnePlayableSet: boolean;
  isThisEventFocused: boolean;
  isPlayerPaused: boolean;
}): string => {
  if (!isThereAtLeastOnePlayableSet) {
    return "No preview available";
  }

  if (!isThisEventFocused) {
    return "Click to hear what is playing at the event";
  }

  if (isPlayerPaused) {
    return "Resume preview";
  } else {
    return "Pause preview";
  }
};
