import React from "react";
import ClassNamePropType from "../../data_models/react_prop_types/ClassNamePropType";
import SvgWithDefaultStyler from "./SvgWithDefaultStyle";

export default function DoorManIcon({ className }: ClassNamePropType) {
  return (
    <SvgWithDefaultStyler
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      xmlSpace="preserve"
      className={className}
    >
      <switch>
        <foreignObject
          requiredExtensions="http://ns.adobe.com/AdobeIllustrator/10.0/"
          x="0"
          y="0"
          width="1"
          height="1"
        />
        <g>
          <g>
            <path d="M94.5,2.5H40.9c-1.4,0-2.5,1.1-2.5,2.5v27.1c0.3,0.1,0.5,0.2,0.7,0.3l0.1,0l0.1,0c0,0,1.1,0.4,2.7,0.4     c0.8,0,1.5-0.1,2.3-0.3c0.9-0.2,1.7-0.5,2.4-0.9c0.8-0.3,1.5-0.6,2.3-0.8c0.2,0,0.4-0.1,0.5-0.1c1.4,0,2.7,0.8,3.5,2.2     c0.8,1.3,0.6,2.5,0.4,3.3c-0.7,2.1-3.1,3.1-4.8,3.8l-0.2,0.1c-1.1,0.4-3.8,1.5-7.4,1.5c-0.9,0-1.7-0.1-2.6-0.2V95     c0,1.4,1.1,2.5,2.5,2.5h53.6c1.4,0,2.5-1.1,2.5-2.5V5C97,3.6,95.9,2.5,94.5,2.5z M91.6,92.1h-41L70.3,82c0.1,0,0.1-0.1,0.2-0.1     c0.3-0.2,0.6-0.5,0.8-0.8c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.2,0.1-0.4,0.1-0.7V20.2c0-0.9-0.5-1.8-1.4-2.3L50.6,7.9h41V92.1z      M63.1,51.6v-3.3c0-1.1,0.9-1.9,1.9-1.9c1.1,0,1.9,0.9,1.9,1.9v3.3c0,1.1-0.9,1.9-1.9,1.9C64,53.6,63.1,52.7,63.1,51.6z" />
            <path d="M47.9,38.5c1.2-0.4,5-1.9,3.5-4.5c-0.6-1-1.4-1.4-2.3-1.3C48,33,46.6,34,44.6,34.4c-3.6,0.8-6.1-0.2-6.1-0.2     c-0.9-0.3-1.8-0.8-2.8-1.4c-1-0.6-1.9-1.5-3-2.3c-1.1-0.9-2.4-1.8-4-2.5c-0.9-0.4-2-0.7-3-0.9c-1.5,0.9-3.2,1.4-5,1.4     c-1.9,0-3.6-0.5-5.1-1.5c-0.1,0-0.2,0-0.3,0.1c-1.6,0.3-3.4,0.8-5,2c-0.8,0.6-1.8,1.6-2.3,2.4c-0.6,1-0.9,2.4-1,2.9     c-0.1,0.5-0.7,3.5-0.7,3.5L3,53.5c-0.3,1.2,0.5,2.5,1.7,2.8c1.3,0.3,2.6-0.4,2.9-1.7l3.9-15.5l1.1-4.4c0,0,0,0,0,0L11,55.3     c-0.1,1.4,0.6,2.7,1.8,3.3c-0.8,11.9-1.6,23.7-2.3,35.6c-0.1,1.6,1.1,3,2.7,3.2c1.7,0.2,3.2-1,3.4-2.7     c1.3-10.9,2.4-21.8,3.6-32.8c0.4,0,0.8,0,1.2,0L25,94.7c0.2,1.6,1.6,2.8,3.2,2.7c1.7-0.1,3-1.6,2.8-3.2l-2.4-35.5     c1.1-0.6,1.9-1.9,1.8-3.3l-1.8-20.8c0.2,0.1,0.4,0.3,0.6,0.4c1,0.8,2.1,1.6,3.3,2.5c1.3,0.8,2.8,1.6,4.4,2.1     c0.4,0.1,0.8,0.2,1.2,0.3C43.1,40.6,46.7,39,47.9,38.5z" />
            <circle cx="20.7" cy="19" r="7.7" />
          </g>
        </g>
      </switch>
    </SvgWithDefaultStyler>
  );
}
