import styled from "styled-components";

interface IProps {
  size?: number;
}

const LoadingSpinner = styled.div<IProps>`
  width: ${({ size }) => size || 64}px;
  height: ${({ size }) => size || 64}px;

  cursor: ${({ onClick }) => (onClick ? "pointer" : "default")};

  &:after {
    content: " ";
    display: block;
    width: ${({ size }) => (size || 64) * 0.8}px;
    height: ${({ size }) => (size || 64) * 0.8}px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid #000000;
    border-color: #000000 transparent #000000 transparent;
    animation: loading-spinner-keyframes 1.2s linear infinite;
  }
  @keyframes loading-spinner-keyframes {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default LoadingSpinner;
