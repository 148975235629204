import React, { ReactNode } from "react";
import {
  ChessboardModalStyler,
  ChessboardModalTopLeftArea,
  ChessboardModalHeaderArea,
  ChessboardModalBottomLeftArea,
  ChessboardModalMainAreaArea
} from "./ChessboardModal.styles";

interface IProps {
  topLeftComponent?: ReactNode;
  headerComponent?: ReactNode;
  bottomLeft?: ReactNode;
  mainAreaComponent?: ReactNode;
}

export default function ChessboardModal({
  topLeftComponent,
  headerComponent,
  bottomLeft,
  mainAreaComponent
}: IProps) {
  return (
    <ChessboardModalStyler>
      <ChessboardModalTopLeftArea>
        {topLeftComponent}
      </ChessboardModalTopLeftArea>
      <ChessboardModalHeaderArea>{headerComponent}</ChessboardModalHeaderArea>
      <ChessboardModalBottomLeftArea>{bottomLeft}</ChessboardModalBottomLeftArea>
      <ChessboardModalMainAreaArea>
        {mainAreaComponent}
      </ChessboardModalMainAreaArea>
    </ChessboardModalStyler>
  );
}
