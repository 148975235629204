import findById from "../utils/findById";
import cities from "../static_db/cities";
import {
  dataByIdNotFound,
  permalinkableDataNotFound
} from "../utils/error_builders/server_error_response_builders";
import moment from "moment";
import events from "../static_db/events";
import ServerErrorResponse from "../data_models/ServerErrorResponse";
import { argumentOfTypeUndefined } from "../utils/error_builders/share_error_builders";
import SerializedCity from "../data_models/City/SerializedCity";
import SerializedEvent from "../data_models/Event/SerializedEvent";
import findByIdOrThrow from "../utils/findByIdOrThrow";
import clubs from "../static_db/clubs";
import { HookReturnValue } from "./HookReturnValue";

interface CityIdParams {
  cityId: string;
}

interface CityPermalinkParams {
  cityPermalink: string;
}

type ReturnType = HookReturnValue<{
  city: SerializedCity;
  events: SerializedEvent[];
}>;

const isCityIdParams = (args: unknown): args is CityIdParams =>
  (args as CityIdParams).cityId !== undefined;

const getCity = (
  args: CityIdParams | CityPermalinkParams,
  localCities: SerializedCity[]
) => {
  let city: SerializedCity | undefined;
  let error: ServerErrorResponse | undefined;

  if (isCityIdParams(args)) {
    city = findById(localCities, args.cityId);

    if (!city) {
      error = dataByIdNotFound(args.cityId);
    }
  } else {
    city = localCities.find(city =>
      city.permalinks.includes(args.cityPermalink)
    );

    if (!city) {
      error = permalinkableDataNotFound(args.cityPermalink);
    }
  }

  return { city, error };
};

const useCityViewData = (
  args: CityIdParams | CityPermalinkParams
): ReturnType => {
  const { city, error } = getCity(args, cities);

  if (error || city === undefined) {
    if (!error) {
      throw argumentOfTypeUndefined("city");
    }

    return error;
  }

  const now = moment();
  const clubsInCity = city.clubIds.map(clubId =>
    findByIdOrThrow(clubs, clubId)
  );
  const ongoingOrLaterEventsInCity = events.filter(
    event =>
      clubsInCity.find(club => club.id === event.clubId) &&
      now.isBefore(event.lastTimeStamp)
  );

  return {
    city,
    events: ongoingOrLaterEventsInCity
  };
};

export default useCityViewData;
