import { HookReturnValue } from "./HookReturnValue";
import SerializedArtist from "../data_models/Artist/SerializedArtist";
import findByIdOrThrow from "../utils/findByIdOrThrow";
import artists from "../static_db/artists";

const useArtists = (
  artistIds: string[] | null
): HookReturnValue<SerializedArtist[]> => {
  // When the parameter is null this hook is still pending input from another hook
  if (artistIds === null) {
    return null;
  }

  const requestedArtists = artistIds.map(artistId =>
    findByIdOrThrow(artists, artistId)
  );

  return requestedArtists;
};

export default useArtists;
