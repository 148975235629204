import React from "react";
import styled from "styled-components";
import Icon, { IconKey } from "../icons/Icon";
import UnstyledButton from "./UnstyledButton";
import { disabledGrey } from "../../styles/colors";

interface IStyledProps {
  isDisabled?: boolean;
}

const StyledIconButtonContainer = styled(UnstyledButton)<IStyledProps>`
  cursor: ${({ isDisabled }) => (isDisabled ? "default" : "pointer")};
`;

const DisableableIcon = styled(Icon)<IStyledProps>`
  && {
    fill: ${({ isDisabled }) => (isDisabled ? disabledGrey : "default")};
  }
`;

type IProps = {
  onClick: () => void;
  className?: string;
  iconKey: IconKey;
  tooltip?: string;
} & IStyledProps;

const IconButton = ({
  isDisabled,
  iconKey,
  onClick,
  className,
  tooltip
}: IProps) => {
  return (
    <StyledIconButtonContainer
      isDisabled={isDisabled}
      onClick={isDisabled ? undefined : onClick}
      title={tooltip}
    >
      <DisableableIcon
        iconKey={iconKey}
        className={className}
        isDisabled={isDisabled}
      />
    </StyledIconButtonContainer>
  );
};

export default IconButton;
