import React from "react";
import { IEventInformationModalProps } from "./EventInformationModal";
import {
  HookReturnValue,
  isHookRequestedResult,
} from "../../../api/HookReturnValue";
import useEvent from "../../../api/useEvent";
import useClub from "../../../api/useClub";
import LineupDisplayer from "./LineupDisplayer";
import {
  usePlaySetPreview,
  usePlayEventSetPreviews,
} from "../../../redux/player_state/player_actions";
import { useReduxSelector } from "../../../redux/redux_hooks";
import EventMainInformationDisplayer from "./EventMainInformationDisplayer";
import {
  EventInformationModalVenueImageStyler,
  EventInformationModalEventImage,
} from "./EventInformationModal.styles";
import { getImageWithCrediteeToShowForEvent } from "../../../data_models/Event/event_functions";
import artists from "../../../static_db/artists";
import { createIdDictionary } from "../../../utils/createIdDictionary";

const useEventInformationModalProps = (
  eventId: string
): HookReturnValue<IEventInformationModalProps> => {
  // Hooks
  const useEventResponse = useEvent(eventId);
  const venueIdOrNull = isHookRequestedResult(useEventResponse)
    ? useEventResponse.clubId
    : null;
  const useVenueResponse = useClub(venueIdOrNull);
  const activeTrackPlayerState = useReduxSelector(
    ({ player: { activeTrackPlayerState } }) => activeTrackPlayerState
  );
  const playSetPreview = usePlaySetPreview();
  const playEventSetPreviews = usePlayEventSetPreviews();

  // Handling hook error or loading states
  if (!isHookRequestedResult(useEventResponse)) {
    return useEventResponse;
  }

  if (!isHookRequestedResult(useVenueResponse)) {
    return useVenueResponse;
  }

  // Transform/renaming data

  const event = useEventResponse;
  const venue = useVenueResponse;

  // Here we also need to make sure the track is actually playing
  const isPlayerFocusedAndPaused = activeTrackPlayerState
    ? activeTrackPlayerState.isPaused
    : false;
  const focusedSetPreviewId =
    activeTrackPlayerState &&
    activeTrackPlayerState.currentSetPreviewInformation.setId;

  const eventArtistsDictionary = createIdDictionary(artists);

  const printableEventSets = event.sets.map((set) => {
    const setArtists = set.artistIds.map(
      (artistId) => eventArtistsDictionary[artistId]
    );

    return {
      setId: set.id,
      setTitle: setArtists.map(({ name }) => name).join(" & "),
      setImageUrl:
        setArtists[0].imageUrl || "https://pixy.org/download/4764586/",
      isPlayable: setArtists[0].representableMixSoundcloudTrackId !== null,
    };
  });

  const imageToShowForEvent = getImageWithCrediteeToShowForEvent(event, venue);

  // Create callbacks
  const onLineupAllPlayClick = () => playEventSetPreviews(event);

  const onSetPlayClick = (setId: string) => playSetPreview(setId);

  // Rendering components
  const eventImageComponent = (
    <EventInformationModalEventImage
      imageUrl={imageToShowForEvent.url}
      creditee={imageToShowForEvent.creditee}
    />
  );

  const lineupComponent = (
    <LineupDisplayer
      onAllPlayClick={onLineupAllPlayClick}
      onSetPlayClick={onSetPlayClick}
      focusedSetId={focusedSetPreviewId}
      isPlayerPaused={isPlayerFocusedAndPaused}
      eventSets={printableEventSets}
    />
  );

  const mainAreaComponent = (
    <EventMainInformationDisplayer
      doorTicketPrice={event.doorPrice}
      onlineTicketInformation={event.onlineTicketInformation}
      venueDescription={venue.description}
      venueName={venue.name}
      highlightedSets={printableEventSets.slice(0, 2)}
      venueImageComponents={[
        <EventInformationModalVenueImageStyler
          imageUrl={venue.firstImage.url}
          creditee={venue.firstImage.creditee}
        />,
        <EventInformationModalVenueImageStyler
          imageUrl={venue.secondImage.url}
          creditee={venue.secondImage.creditee}
        />,
        <EventInformationModalVenueImageStyler
          imageUrl={venue.thirdImage.url}
          creditee={venue.thirdImage.creditee}
        />,
      ]}
      onSetPlayClick={(setId) => playSetPreview(setId)}
      isPlayerFocusedAndPaused={isPlayerFocusedAndPaused}
      setIdThatIsFocused={focusedSetPreviewId}
    />
  );

  return {
    eventImageComponent,
    eventName: event.name,
    venueName: venue.name,
    lineupComponent,
    mainAreaComponent,
  };
};

export default useEventInformationModalProps;
