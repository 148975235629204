import React from "react";
import BlankModal from "../BlankModal/BlankModal";
import ModalContentExitCrossStyler from "../ModalContentExitCrossStyler";
import ServerErrorResponse from "../../../data_models/ServerErrorResponse";
import ErrorComponent from "../../ErrorComponent/ErrorComponent";
import OnRequestCloseProp from "../../../data_models/react_prop_types/OnRequestCloseProp";

type IProps = {
  error: ServerErrorResponse;
} & OnRequestCloseProp;

export default function ErrorMessageModal({ error, onRequestClose }: IProps) {
  return (
    <BlankModal>
      <ModalContentExitCrossStyler onCrossClick={onRequestClose}>
        <ErrorComponent error={error} />
      </ModalContentExitCrossStyler>
    </BlankModal>
  );
}
