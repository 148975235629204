import {
  css,
  FlattenInterpolation,
  ThemedStyledProps
} from "styled-components";

interface Bootstrap4MediaQueriesProps<T> {
  extraSmallCss?: FlattenInterpolation<T>;
  smallCss?: FlattenInterpolation<T>;
  mediumCss?: FlattenInterpolation<T>;
  largeCss?: FlattenInterpolation<T>;
  extraLargeCss?: FlattenInterpolation<T>;
}

// Bootstrap 4 breakpoint:
// Extra Small  Small	    Medium	    Large	    Exra large
// min: none    min: 576px	min: 768px	min: 992px	min: 1200px
// Source: https://getbootstrap.com/docs/4.0/layout/overview/#responsive-breakpoints
const generateBootstrap4MediaQueries = <T extends object = {}>({
  extraSmallCss,
  smallCss,
  mediumCss,
  largeCss,
  extraLargeCss
}: Bootstrap4MediaQueriesProps<ThemedStyledProps<T, any>>) => css<T>`
  ${extraSmallCss}

  @media (min-width: 576px) {
    ${smallCss}
  }
  @media (min-width: 768px) {
    ${mediumCss}
  }
  @media (min-width: 992px) {
    ${largeCss}
  }
  @media (min-width: 1200px) {
    ${extraLargeCss}
  }
`;

export default generateBootstrap4MediaQueries;
