import IDable from "../data_models/IDable";
import { objectByIdNotFoundInArray } from "./error_builders/share_error_builders";
import findById from "./findById";

const findByIdOrThrow = <T extends IDable>(array: T[], id: string): T => {
  const result = findById(array, id);

  if (!result) {
    throw objectByIdNotFoundInArray(id);
  }

  return result;
};

export default findByIdOrThrow;
