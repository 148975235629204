import React, { FC } from "react";
import Next from "./Next";
import Play from "./Play";
import Pause from "./Pause";
import Stop from "./Stop";
import ChillingIcon from "./ChillingIcon";
import DanceFloorIcon from "./DanceFloorIcon";
import DoorManIcon from "./DoorManIcon";
import FoodIcon from "./FoodIcon";
import PhoneIcon from "./PhoneIcon";
import QueueIcon from "./QueueIcon";
import ArrowDown from "./ArrowDown";
import BirthdayCakeIcon from "./BirthdayCakeIcon";
import Cross from "./Cross";
import PlayCircle from "./PlayCircle";
import MovieTicket from "./MovieTicket";
import ResidentAdvisorIcon from "./ResidentAdvisorIcon";
import FacebookIcon from "./FacebookIcon";
import ClassNamePropType from "../../data_models/react_prop_types/ClassNamePropType";
import PauseCircle from "./PauseCircle";
import ExpandIcon from "./ExpandIcon";
import CollapseIcon from "./CollapseIcon";

export enum IconKey {
  SkipNext = "skip_next",
  PlayPlain = "play_plain",
  PlayCircle = "play_circle",
  PauseCircle = "pause_circle",
  Pause = "pause",
  Stop = "stop",
  ArrowDown = "arrow_down",
  Chill = "chill",
  DanceFloor = "dance_floor",
  DoorMan = "door_man",
  Food = "food",
  Phone = "phone",
  Queue = "queue",
  BirtdayCake = "birtday_cake",
  Cross = "cross",
  MovieTicket = "movie_ticket",
  ResidentAdvisor = "resident_advisor",
  Facebook = "facebook",
  ExpandIcon = "expand_icon",
  CollapseIcon = "collapse_icon"
}

const IconDictionary: {
  [key: string]: FC<{
    className?: string;
  }>;
} = {
  [IconKey.SkipNext]: Next,
  [IconKey.PlayPlain]: Play,
  [IconKey.PlayCircle]: PlayCircle,
  [IconKey.PauseCircle]: PauseCircle,
  [IconKey.Pause]: Pause,
  [IconKey.Stop]: Stop,
  [IconKey.ArrowDown]: ArrowDown,
  [IconKey.Chill]: ChillingIcon,
  [IconKey.DanceFloor]: DanceFloorIcon,
  [IconKey.DoorMan]: DoorManIcon,
  [IconKey.Food]: FoodIcon,
  [IconKey.Phone]: PhoneIcon,
  [IconKey.Queue]: QueueIcon,
  [IconKey.BirtdayCake]: BirthdayCakeIcon,
  [IconKey.Cross]: Cross,
  [IconKey.MovieTicket]: MovieTicket,
  [IconKey.ResidentAdvisor]: ResidentAdvisorIcon,
  [IconKey.Facebook]: FacebookIcon,
  [IconKey.ExpandIcon]: ExpandIcon,
  [IconKey.CollapseIcon]: CollapseIcon
};

type IProps = {
  iconKey: IconKey;
} & ClassNamePropType;

export default function Icon({ iconKey, ...svgProps }: IProps) {
  const IconSvg = IconDictionary[iconKey];

  return <IconSvg {...svgProps} />;
}
