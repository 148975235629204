import React from "react";
import {
  ReduxModalState,
  ModalStateType
} from "../../redux/modal_state/modalReducer";
import InspectImageModalContentContainer from "./InspectImageModal/InspectImageModalContainer";
import ErrorMessageModalContainer from "./ErrorMessageModal/ErrorMessageModalContainer";
import EventInformationModalContainer from "./EventInformationModal/EventInformationModalContainer";

export default function ModalContent(modalState: ReduxModalState) {
  switch (modalState.type) {
    case ModalStateType.SHOW_IMAGE:
      return <InspectImageModalContentContainer {...modalState} />;
    case ModalStateType.ERROR:
      return <ErrorMessageModalContainer {...modalState} />;
    case ModalStateType.EVENT_INFORMATION:
      return <EventInformationModalContainer {...modalState} />;
    case ModalStateType.CLOSED:
      return null;
  }
}
