import { useDispatch } from "react-redux";
import { Dispatch } from "react";

export const SET_FOCUSED_EVENT = "SET_FOCUSED_EVENT";

interface SetFocusEventAction {
  type: typeof SET_FOCUSED_EVENT;
  focusedEventId: string | null;
}

export type TopLevelActions = SetFocusEventAction;

export const useFocusedOnEvent = () => {
  const dispatch = useDispatch<Dispatch<SetFocusEventAction>>();
  return (eventId: string | null) => {
    dispatch({ type: SET_FOCUSED_EVENT, focusedEventId: eventId });
  };
};

export const useClearFocusedEvent = () => {
  const focusOnEvent = useFocusedOnEvent();
  return () => {
    focusOnEvent(null);
  };
};
