import SerializedEvent from "../data_models/Event/SerializedEvent";
import moment, { Moment } from "moment";

interface DateWithEvents {
  date: Moment;
  events: SerializedEvent[];
}

const groupEventsByDateTodayOrAfter = (
  events: SerializedEvent[]
): DateWithEvents[] =>
  events.reduce<DateWithEvents[]>((carry, event) => {
    if (moment(event.startDateTime).isBefore(moment(), "day")) {
      // Only show events that start today or later for now, since we only show events on their startdate
      return carry;
    }

    const sameDateIndex = carry.findIndex(dateWithEvents =>
      dateWithEvents.date.isSame(event.startDateTime, "day")
    );

    if (sameDateIndex >= 0) {
      const dateWithEvents = carry[sameDateIndex];
      const newCarry = [...carry];
      newCarry[sameDateIndex] = {
        date: dateWithEvents.date,
        events: [...dateWithEvents.events, event]
      };

      return newCarry;
    } else {
      const newCarry: DateWithEvents[] = [
        ...carry,
        {
          date: moment(event.startDateTime),
          events: [event]
        }
      ];
      return newCarry;
    }
  }, []);

export default groupEventsByDateTodayOrAfter;
