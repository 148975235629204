import * as React from "react";
import { Switch, Route, Redirect } from "react-router";
import {
  artistViewRoute,
  permalinkRoute,
  adminResidentAdvisorScraperRoute
} from "../routes";
import ArtistView from "./views/ArtistView/ArtistView";
import PermalinkView from "./views/PermalinkView";
import { berlin } from "../static_db/cities";
import ResidentAdvisorScraperView from "./views/admin_views/ResidentAdvisorScraperView";

const PageRoutes = () => {
  return (
    <Switch>
      <Route path={artistViewRoute.pathname} component={ArtistView} />
      <Route
        path={adminResidentAdvisorScraperRoute.pathname}
        component={ResidentAdvisorScraperView}
      />
      <Route path={permalinkRoute.pathname} component={PermalinkView} />
      <Redirect to={"/" + berlin.activePermalink} />
    </Switch>
  );
};

export default PageRoutes;
