import React from "react";
import SvgWithDefaultStyler from "./SvgWithDefaultStyle";
import ClassNamePropType from "../../data_models/react_prop_types/ClassNamePropType";

export default function PhoneIcon({ className }: ClassNamePropType) {
  return (
    <SvgWithDefaultStyler
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      xmlSpace="preserve"
      className={className}
    >
      <path d="M62.7,86.9H37.3c-3.6,0-6.5-2.9-6.5-6.5V19.6c0-3.6,2.9-6.5,6.5-6.5h25.5c3.6,0,6.5,2.9,6.5,6.5v60.8  C69.2,84,66.3,86.9,62.7,86.9z M37.3,16.2c-1.9,0-3.4,1.5-3.4,3.4v60.8c0,1.9,1.5,3.4,3.4,3.4h25.5c1.9,0,3.4-1.5,3.4-3.4V19.6  c0-1.9-1.5-3.4-3.4-3.4H37.3z" />
      <path d="M50,82.6c-2.7,0-4.8-2.2-4.8-4.8c0-2.7,2.2-4.8,4.8-4.8c2.7,0,4.8,2.2,4.8,4.8C54.8,80.4,52.7,82.6,50,82.6z M50,76  c-1,0-1.8,0.8-1.8,1.8c0,1,0.8,1.8,1.8,1.8c1,0,1.8-0.8,1.8-1.8C51.8,76.8,51,76,50,76z" />
      <path d="M54.2,22h-8.5c-0.8,0-1.5-0.7-1.5-1.5c0-0.8,0.7-1.5,1.5-1.5h8.5c0.8,0,1.5,0.7,1.5,1.5C55.7,21.3,55.1,22,54.2,22z" />
    </SvgWithDefaultStyler>
  );
}
