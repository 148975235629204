import React, { ReactNode } from "react";
import ChessboardModal from "../ChessboardModal/ChessboardModal";
import {
  EventInformationModalHeaderStyler,
  EventInformationModalEventNameStyler,
  EventInformationModalAtVenueCopyStyler
} from "./EventInformationModal.styles";

export interface IEventInformationModalProps {
  eventImageComponent: ReactNode;
  lineupComponent: ReactNode;
  mainAreaComponent: ReactNode;

  eventName: string;
  venueName: string;
}

export default function EventInformationModal({
  mainAreaComponent,
  eventImageComponent,
  lineupComponent,

  eventName,
  venueName
}: IEventInformationModalProps) {
  return (
    <ChessboardModal
      topLeftComponent={eventImageComponent}
      headerComponent={
        <EventInformationModalHeaderStyler>
          <EventInformationModalEventNameStyler>
            {eventName}
          </EventInformationModalEventNameStyler>
          <EventInformationModalAtVenueCopyStyler>
            at {venueName}
          </EventInformationModalAtVenueCopyStyler>
        </EventInformationModalHeaderStyler>
      }
      bottomLeft={lineupComponent}
      mainAreaComponent={mainAreaComponent}
    />
  );
}
