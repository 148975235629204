import React from "react";
import { RouteComponentProps } from "react-router";
import { PermalinkRouteProps } from "../../routes";
import usePermalinkedDataType from "../../api/usePermalinkedDataType";
import PermalinkDataType from "../../data_models/enums/PermalinkDataType";
import CityViewContainer from "./CityView/CityViewContainer";
import { isHookErrorResponse, isHookLoading } from "../../api/HookReturnValue";
import ErrorComponent from "../ErrorComponent/ErrorComponent";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import NonExistentPermalinkView from "./NonExistentPermalinkView";

type IProps = RouteComponentProps<PermalinkRouteProps>;

/**
 * Special component that takes a permalink that can be different types (e.g. City, Club) and handles what to render
 */
export default function PermalinkView({ match }: IProps) {
  const {
    params: { permalink }
  } = match;

  const permalinkedDataTypeResponse = usePermalinkedDataType(permalink);

  if (isHookErrorResponse(permalinkedDataTypeResponse)) {
    return <ErrorComponent error={permalinkedDataTypeResponse} />;
  } else if (isHookLoading(permalinkedDataTypeResponse)) {
    return <LoadingSpinner />;
  }

  if (permalinkedDataTypeResponse === PermalinkDataType.CITY) {
    return <CityViewContainer cityPermalink={permalink} />;
  }

  return <NonExistentPermalinkView nonExistentPermalink={permalink} />;
}
