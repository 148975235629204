import { combineReducers } from "redux";
import errorReducer, { ReduxErrorState } from "./error_state/errorReducer";
import playerReducer, { ReduxPlayerState } from "./player_state/playerReducer";
import { ReduxErrorAction } from "./error_state/error_actions";
import { ReduxPlayerAction } from "./player_state/player_actions";
import { focusedEventIdReducer } from "./top_level_state/top_level_reducers";
import { TopLevelActions } from "./top_level_state/top_level_actions";
import modalReducer, { ReduxModalState } from "./modal_state/modalReducer";

export type ReduxState = {
  error: ReduxErrorState;
  player: ReduxPlayerState;
  focusedEventId: string | null;
  modal: ReduxModalState;
};

export type ReduxAction =
  | ReduxErrorAction
  | ReduxPlayerAction
  | TopLevelActions;

const reducers = combineReducers<ReduxState, ReduxAction>({
  error: errorReducer,
  player: playerReducer,
  focusedEventId: focusedEventIdReducer,
  modal: modalReducer
});

export default reducers;
