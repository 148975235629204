import styled from "styled-components";
import { defaultCopyFontFamily } from "../../../styles/font_styles";

export const EventMainInformationDisplayerStyler = styled.div`
  padding: 16px;
  display: grid;
  grid-row-gap: 24px;
`;

export const EventMainInformationDisplayerHighlightsTicketsStyler = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const EventMainInformationDisplayerHeaderAndContentStyler = styled.div`
  display: grid;
  grid-row-gap: 8px;
  grid-template-rows: auto 1fr;
`;

export const EventMainInformationDisplayerTicketHeaderAndContentStyler = styled(
  EventMainInformationDisplayerHeaderAndContentStyler
)`
  text-align: right;
`;

export const EventMainInformationDisplayerHeaderCopyStyler = styled.div`
  font-family: ${defaultCopyFontFamily};
  font-size: 24px;
  font-weight: bold;
`;

export const EventMainInformationDisplayerHighlightedSetsStyler = styled.div`
  margin-top: 8px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
`;

export const EventMainInformationDisplayerTicketsStyler = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-row-gap: 52px;
`;
export const EventMainInformationDisplayerTicketPriceStyler = styled.a`
  font-family: ${defaultCopyFontFamily};
  font-size: 46px;
`;
export const EventMainInformationDisplayerTicketTitleStyler = styled.div`
  font-family: ${defaultCopyFontFamily};
  font-size: 24px;
`;

export const EventMainInformationDisplayerVenueImagesStyler = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 200px);
  height: 128px;
  grid-column-gap: 16px;
`;

export const EventMainInformationDisplayerVenueDescriptionStyler = styled.div`
  font-family: ${defaultCopyFontFamily};
  font-size: 24px;
`;
