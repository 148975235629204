import styled, { css } from "styled-components";
import generateBootstrap4MediaQueries from "../style_mixins/bootstrap4MediaQueries";

const baseCardsGridCss = css`
  display: grid;
  grid-gap: 24px;
`;

const mobilePortraitCardsGridCss = css`
  ${baseCardsGridCss}
  grid-template-columns: 1fr;
`;

const defaultCardsGridCss = css`
  ${baseCardsGridCss}
  grid-template-columns: repeat(auto-fill, 256px);
`;

const CardsGrid = styled.div`
  ${generateBootstrap4MediaQueries({
    extraSmallCss: mobilePortraitCardsGridCss,
    mediumCss: defaultCardsGridCss
  })}
`;

export default CardsGrid;
