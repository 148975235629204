import ServerErrorResponse from "../../data_models/ServerErrorResponse";

export const permalinkableDataNotFound = (
  permalink: string
): ServerErrorResponse => ({
  httpCode: 404,
  message: `Couldn't find data by permalink "${permalink}".`
});

export const dataByIdNotFound = (id: string): ServerErrorResponse => ({
  httpCode: 404,
  message: `Couldn't find data by id "${id}".`
});
