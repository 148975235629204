import React, { ReactNode } from "react";
import ModalContentExitCrossStyler from "../ModalContentExitCrossStyler";
import styled from "styled-components";
import { defaultCopyFontFamily } from "../../../styles/font_styles";
import BlankModal from "../BlankModal/BlankModal";
import OnRequestCloseProp from "../../../data_models/react_prop_types/OnRequestCloseProp";

type IProps = {
  imageUrl: string;
  courtesyText: ReactNode;
} & OnRequestCloseProp;

const ModalContentStyler = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 32px 16px 32px;
`;

const InspectableImage = styled.img`
  margin-bottom: 16px;
`;

const CourtesyTextStyler = styled.div`
  font-size: 12px;
  font-family: ${defaultCopyFontFamily};
`;

export default function InspectImageModalContent({
  onRequestClose,
  courtesyText,
  imageUrl
}: IProps) {
  return (
    <BlankModal>
      <ModalContentExitCrossStyler onCrossClick={onRequestClose}>
        <ModalContentStyler>
          <ModalContentExitCrossStyler onCrossClick={onRequestClose} />
          <InspectableImage src={imageUrl} />
          <CourtesyTextStyler>{courtesyText}</CourtesyTextStyler>
        </ModalContentStyler>
      </ModalContentExitCrossStyler>
    </BlankModal>
  );
}
