import styled, { css } from "styled-components";
import generateBootstrap4MediaQueries from "../../style_mixins/bootstrap4MediaQueries";

const baseHeaderImageCss = css`
  width: 100%;
  object-fit: cover;
  object-position: top;
`;

const mobilePortraitHeaderImageCss = css`
  ${baseHeaderImageCss};
  height: 128px;
  margin-bottom: 16px;
`;

const defaultHeaderImageCss = css`
  height: 400px;
  margin-bottom: 40px;
`;

export const HeaderImageWithGroupedCardsViewHeaderImage = styled.img`
  ${generateBootstrap4MediaQueries({
    extraSmallCss: mobilePortraitHeaderImageCss,
    mediumCss: defaultHeaderImageCss
  })}
`;

export const HeaderImageWithGroupedCardsViewGrid = styled.div`
  display: grid;
  grid-row-gap: 20px;
`;
