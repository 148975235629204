import moment from "moment";
import SerializedEvent from "../data_models/Event/SerializedEvent";
import {
  aboutBlank,
  griessmuehle,
  wildeRenate,
  katerBlau,
  berghain,
} from "./clubs";
import {
  anthro,
  djRichard,
  ignota,
  ikaDuchna,
  imogen,
  konkurs,
  lakker,
  lawrence,
  louisahhh,
  sisselWincent,
  snts,
  tini,
  wallis,
  iHateModels,
  emmanuel,
  krtm,
  geinst,
  linnElisabet,
  charlton,
  xiorro,
  adrestia,
  buzzi,
  schwefelgelb,
  miranN,
  chami,
  conradVanOrton,
  ferdinandDreyssig,
  analogue,
  alberty,
  juliusHenze,
  dannySalas,
  jackJenson,
  swamThing,
  mothersBlacksheep,
  bamela,
  johannesTon,
  esteble,
  laetizia,
  midas104,
  maes,
  thorRixon,
  katka,
  niju,
  boston168,
  developer,
} from "./artists";

const events: SerializedEvent[] = [
  {
    name: "Klubnacht",
    id: "434ff568-90b3-48e9-9594-4e9f6ea45d22",
    clubId: berghain.id,
    startDateTime: moment("2019-10-05 23:59").format(),
    lastTimeStamp: moment("2019-10-07 06:00").format(),
    image: {
      url:
        "https://www.residentadvisor.net/images/events/flyer/2019/10/de-1005-1317770-1497315-front.jpg",
      creditee: "Unknown",
    },
    description: "PLACEHOLDER",
    highlightedSetIds: [
      "692c0842-6c4d-4369-82a8-7cec53cbb64a",
      "f0425d0e-8e85-4207-a5c7-06f5aaa8d262",
    ],
    sets: [
      {
        id: "692c0842-6c4d-4369-82a8-7cec53cbb64a",
        artistIds: [boston168.id],
        isLive: false,
        eventId: "434ff568-90b3-48e9-9594-4e9f6ea45d22",
        clubId: berghain.id,
      },
      {
        id: "f0425d0e-8e85-4207-a5c7-06f5aaa8d262",
        artistIds: [developer.id],
        isLive: false,
        eventId: "434ff568-90b3-48e9-9594-4e9f6ea45d22",
        clubId: berghain.id,
      },
    ],
  },
  {
    name: "Upperberry Gone Wild (24 Hours)",
    id: "814e0253-7533-4f38-975b-ceb540ed7d72",
    clubId: aboutBlank.id,
    startDateTime: moment().format(),
    lastTimeStamp: moment().add(6, "hours").format(),
    image: {
      url:
        "https://www.residentadvisor.net/images/events/flyer/2019/9/de-0906-1271525-1411059-front.jpg",
      creditee: "Unknown",
    },
    description: "Past Event",
    highlightedSetIds: [],
    sets: [
      {
        id: "fb3a1859-262b-4d09-bab0-48dc37d78d39",
        artistIds: [anthro.id],
        isLive: false,
        eventId: "814e0253-7533-4f38-975b-ceb540ed7d72",
        clubId: aboutBlank.id,
      },
      {
        id: "c9b81fae-391e-4e36-858c-90758b4f5fcc",
        artistIds: [djRichard.id],
        isLive: false,
        eventId: "814e0253-7533-4f38-975b-ceb540ed7d72",
        clubId: aboutBlank.id,
      },
      {
        id: "95151b85-26f5-4a1a-a20c-5bb9988931d8",
        artistIds: [ignota.id],
        isLive: false,
        eventId: "814e0253-7533-4f38-975b-ceb540ed7d72",
        clubId: aboutBlank.id,
      },
      {
        id: "9a52e9e8-5d26-48a6-9a5f-3074ccab7ca0",
        artistIds: [ikaDuchna.id],
        isLive: false,
        eventId: "814e0253-7533-4f38-975b-ceb540ed7d72",
        clubId: aboutBlank.id,
      },
      {
        id: "93534f2d-cf15-4b6e-a8f5-53996ea1ff63",
        artistIds: [imogen.id],
        isLive: false,
        eventId: "814e0253-7533-4f38-975b-ceb540ed7d72",
        clubId: aboutBlank.id,
      },
      {
        id: "dee10c04-d4fc-4833-941d-08d168024b32",
        artistIds: [konkurs.id],
        isLive: false,
        eventId: "814e0253-7533-4f38-975b-ceb540ed7d72",
        clubId: aboutBlank.id,
      },
      {
        id: "b5c49a67-57be-4ab0-b60b-cf471a64c685",
        artistIds: [lakker.id],
        isLive: false,
        eventId: "814e0253-7533-4f38-975b-ceb540ed7d72",
        clubId: aboutBlank.id,
      },
      {
        id: "86ffd95e-e033-421d-aafb-fa834678f26",
        artistIds: [lawrence.id],
        isLive: false,
        eventId: "814e0253-7533-4f38-975b-ceb540ed7d72",
        clubId: aboutBlank.id,
      },
      {
        id: "8592274d-45bf-4391-b329-a025dbe22b63",
        artistIds: [louisahhh.id],
        isLive: false,
        eventId: "814e0253-7533-4f38-975b-ceb540ed7d72",
        clubId: aboutBlank.id,
      },
      {
        id: "6e421fe3-13be-43c3-b25f-cac8b26ef966",
        artistIds: [sisselWincent.id],
        isLive: false,
        eventId: "814e0253-7533-4f38-975b-ceb540ed7d72",
        clubId: aboutBlank.id,
      },
      {
        id: "48d8af77-6a22-4419-b9f1-14360fa01487",
        artistIds: [snts.id],
        isLive: false,
        eventId: "814e0253-7533-4f38-975b-ceb540ed7d72",
        clubId: aboutBlank.id,
      },
      {
        id: "911e9d4b-a594-4de1-8802-5eb766ffb36e",
        artistIds: [tini.id],
        isLive: false,
        eventId: "814e0253-7533-4f38-975b-ceb540ed7d72",
        clubId: aboutBlank.id,
      },
      {
        id: "f2e7dd06-ed8a-47c9-aed6-995049d623b4",
        artistIds: [wallis.id],
        isLive: false,
        eventId: "814e0253-7533-4f38-975b-ceb540ed7d72",
        clubId: aboutBlank.id,
      },
    ],
  },
  {
    name: "Griessmuehle w./ Arts & Alkhemy",
    id: "71a522c9-bd06-4f9f-b2c1-b07d47b87622",
    clubId: griessmuehle.id,
    startDateTime: moment().add(1, "day").format(),
    lastTimeStamp: moment().add(1, "day").add(6, "hours").format(),
    image: {
      url:
        "https://www.residentadvisor.net/images/events/flyer/2019/9/de-0906-1294522-1464293-front.jpg",
      creditee: "Unknown",
    },
    description: "Past Event",
    highlightedSetIds: [],
    sets: [
      {
        id: "9baab7fd-c2e9-497b-aa8c-41cf5bd05da6",
        artistIds: [iHateModels.id],
        isLive: false,
        eventId: "71a522c9-bd06-4f9f-b2c1-b07d47b87622",
        clubId: griessmuehle.id,
      },
      {
        id: "cc6028dc-d1ff-4608-9eaa-c7551e8a5c33",
        artistIds: [emmanuel.id],
        isLive: false,
        eventId: "71a522c9-bd06-4f9f-b2c1-b07d47b87622",
        clubId: griessmuehle.id,
      },
      {
        id: "897ace63-abd2-4bf7-a145-1977448c11a2",
        artistIds: [krtm.id],
        isLive: false,
        eventId: "71a522c9-bd06-4f9f-b2c1-b07d47b87622",
        clubId: griessmuehle.id,
      },
      {
        id: "f033ea2f-b14f-4a3b-86d5-6322236db9e1",
        artistIds: [geinst.id],
        isLive: false,
        eventId: "71a522c9-bd06-4f9f-b2c1-b07d47b87622",
        clubId: griessmuehle.id,
      },
      {
        id: "e7093f02-48fa-4e3b-90dc-c9a0a0bd9259",
        artistIds: [linnElisabet.id],
        isLive: false,
        eventId: "71a522c9-bd06-4f9f-b2c1-b07d47b87622",
        clubId: griessmuehle.id,
      },
      {
        id: "bbb7fb5d-625d-43ef-9906-163fffb2e5c4",
        artistIds: [charlton.id],
        isLive: false,
        eventId: "71a522c9-bd06-4f9f-b2c1-b07d47b87622",
        clubId: griessmuehle.id,
      },
      {
        id: "aa9727c8-6a14-431c-975c-e44b2b59b273",
        artistIds: [xiorro.id],
        isLive: false,
        eventId: "71a522c9-bd06-4f9f-b2c1-b07d47b87622",
        clubId: griessmuehle.id,
      },
      {
        id: "04d3a6e6-3689-465e-851b-f65bdc479030",
        artistIds: [adrestia.id],
        isLive: false,
        eventId: "71a522c9-bd06-4f9f-b2c1-b07d47b87622",
        clubId: griessmuehle.id,
      },
      {
        id: "a44d3e55-f576-4c0d-998e-abf78a324bac",
        artistIds: [buzzi.id],
        isLive: false,
        eventId: "71a522c9-bd06-4f9f-b2c1-b07d47b87622",
        clubId: griessmuehle.id,
      },
      {
        id: "87fe418a-2eb3-4b3a-bc0f-5c948b589683",
        artistIds: [schwefelgelb.id],
        isLive: false,
        eventId: "71a522c9-bd06-4f9f-b2c1-b07d47b87622",
        clubId: griessmuehle.id,
      },
      {
        id: "f6f7a32e-be88-45e5-9d37-76b296a8a155",
        artistIds: [miranN.id],
        isLive: false,
        eventId: "71a522c9-bd06-4f9f-b2c1-b07d47b87622",
        clubId: griessmuehle.id,
      },
      {
        id: "ba27b9dd-a2ea-46c7-bf06-6465e9482620",
        artistIds: [chami.id],
        isLive: false,
        eventId: "71a522c9-bd06-4f9f-b2c1-b07d47b87622",
        clubId: griessmuehle.id,
      },
    ],
  },
  {
    name: "Renates Heimkinder w. Entropie Floor",
    id: "e256abe9-6242-4b37-b7a6-0960cb8a4532",
    clubId: wildeRenate.id,
    startDateTime: moment("2019-09-06 23:59").format(),
    lastTimeStamp: moment("2019-09-07 12:00").format(),
    image: {
      url:
        "https://www.residentadvisor.net/images/events/flyer/2019/9/de-0906-1305166-front.jpg",
      creditee: "Unknown",
    },
    description: "Past Event",
    highlightedSetIds: [],
    sets: [
      {
        id: "89771297-ed67-4d44-8e77-57003cee9c29",
        artistIds: [conradVanOrton.id],
        isLive: false,
        eventId: "e256abe9-6242-4b37-b7a6-0960cb8a4532",
        clubId: wildeRenate.id,
      },
      {
        id: "59ec8dd9-abff-4856-9a1c-a54ca217764f",
        artistIds: [ferdinandDreyssig.id],
        isLive: false,
        eventId: "e256abe9-6242-4b37-b7a6-0960cb8a4532",
        clubId: wildeRenate.id,
      },
      {
        id: "c4ae9540-f51c-4ab4-acc2-c1ab675419fc",
        artistIds: [analogue.id],
        isLive: false,
        eventId: "e256abe9-6242-4b37-b7a6-0960cb8a4532",
        clubId: wildeRenate.id,
      },
      {
        id: "592fe907-20bd-43c0-ab2d-6bd5036f8252",
        artistIds: [alberty.id],
        isLive: false,
        eventId: "e256abe9-6242-4b37-b7a6-0960cb8a4532",
        clubId: wildeRenate.id,
      },
      {
        id: "2e6a4d90-94cf-456f-86e0-90db63c7b487",
        artistIds: [juliusHenze.id],
        isLive: false,
        eventId: "e256abe9-6242-4b37-b7a6-0960cb8a4532",
        clubId: wildeRenate.id,
      },
      {
        id: "7964b4bf-132d-4412-af55-e007cb0e0dea",
        artistIds: [dannySalas.id],
        isLive: false,
        eventId: "e256abe9-6242-4b37-b7a6-0960cb8a4532",
        clubId: wildeRenate.id,
      },
      {
        id: "acffc1ae-311c-4ab6-b853-2e97a1b4c200",
        artistIds: [jackJenson.id],
        isLive: false,
        eventId: "e256abe9-6242-4b37-b7a6-0960cb8a4532",
        clubId: wildeRenate.id,
      },
      {
        id: "7578fa05-0577-4423-8f24-e5be800e5397",
        artistIds: [swamThing.id],
        isLive: false,
        eventId: "e256abe9-6242-4b37-b7a6-0960cb8a4532",
        clubId: wildeRenate.id,
      },
      {
        id: "ff923668-219b-4a58-80a7-1e77bac1d03d",
        artistIds: [mothersBlacksheep.id],
        isLive: false,
        eventId: "e256abe9-6242-4b37-b7a6-0960cb8a4532",
        clubId: wildeRenate.id,
      },
      {
        id: "ea1e513d-01bb-4379-9673-83fb7908f73b",
        artistIds: [bamela.id],
        isLive: false,
        eventId: "e256abe9-6242-4b37-b7a6-0960cb8a4532",
        clubId: wildeRenate.id,
      },
    ],
  },

  {
    name:
      "Laut & Luise - esteble / Johannes Ton / Midas 104 / Katka / Thor Rixon / Niju",
    id: "0116fa71-a243-4ce6-a575-3a2070a18bba",
    clubId: katerBlau.id,
    startDateTime: moment("2019-09-06 23:59").format(),
    lastTimeStamp: moment("2019-09-07 12:00").format(),
    image: {
      url:
        "https://www.residentadvisor.net/images/events/flyer/2019/9/de-0906-1301782-1448575-front.jpg",
      creditee: "Unknown",
    },
    description: "Past Event",
    highlightedSetIds: [],
    sets: [
      {
        id: "0e8adffd-3b30-4e64-947f-6decf4e216ae",
        artistIds: [johannesTon.id],
        isLive: false,
        eventId: "0116fa71-a243-4ce6-a575-3a2070a18bba",
        clubId: katerBlau.id,
      },
      {
        id: "24c34801-0963-4aec-8a78-cad8ecac2efe",
        artistIds: [esteble.id],
        isLive: false,
        eventId: "0116fa71-a243-4ce6-a575-3a2070a18bba",
        clubId: katerBlau.id,
      },
      {
        id: "e5d17e07-4c6a-469d-8ce4-11420c7797ee",
        artistIds: [laetizia.id],
        isLive: false,
        eventId: "0116fa71-a243-4ce6-a575-3a2070a18bba",
        clubId: katerBlau.id,
      },
      {
        id: "ee2be1b5-40ee-4f51-86a5-0e1ff87dc0a7",
        artistIds: [midas104.id],
        isLive: false,
        eventId: "0116fa71-a243-4ce6-a575-3a2070a18bba",
        clubId: katerBlau.id,
      },
      {
        id: "ff0515a1-8a99-4512-9711-42aa1954f327",
        artistIds: [maes.id],
        isLive: false,
        eventId: "0116fa71-a243-4ce6-a575-3a2070a18bba",
        clubId: katerBlau.id,
      },
      {
        id: "5c529b5d-5f24-479c-bd98-0910a2128cc9",
        artistIds: [thorRixon.id],
        isLive: false,
        eventId: "0116fa71-a243-4ce6-a575-3a2070a18bba",
        clubId: katerBlau.id,
      },
      {
        id: "30831593-3944-4adc-9c6a-fbf9bf2aed6d",
        artistIds: [katka.id],
        isLive: false,
        eventId: "0116fa71-a243-4ce6-a575-3a2070a18bba",
        clubId: katerBlau.id,
      },
      {
        id: "e678462c-c311-473d-a6ac-ac1541a8e603",
        artistIds: [niju.id],
        isLive: false,
        eventId: "0116fa71-a243-4ce6-a575-3a2070a18bba",
        clubId: katerBlau.id,
      },
    ],
  },
  {
    clubId: "5776e283-a68f-42c0-963b-880248263479",
    id: "73856ba8-3236-4551-8e5a-256ff8be101b",
    name: "Ritter Butzke Jubiläum",
    description:
      "Congratulations to Ritter Butzke! 10 years in and still blasting parties like this. Some big names will provide big music!",
    highlightedSetIds: [],
    image: {
      url:
        "https://www.residentadvisor.net/images/events/flyer/2019/10/de-1011-1286905-1472319-front.jpg",
      creditee: "Unknown",
    },
    lastTimeStamp: "2020-11-13T10:00:00+02:00",
    startDateTime: "2020-11-12T23:59:00+02:00",
    sets: [
      {
        id: "fd91a75a-8924-4352-9d6a-c8003f507161",
        artistIds: ["07ae362f-d457-4311-8449-f9debb775a42"],
        isLive: false,
        clubId: "5776e283-a68f-42c0-963b-880248263479",
        eventId: "73856ba8-3236-4551-8e5a-256ff8be101b",
      },
      {
        id: "df92cd54-78f9-447a-b8c8-5933598e082c",
        artistIds: ["60d136ed-53b6-42fe-ab36-ddbb8ea6a015"],
        isLive: false,
        clubId: "5776e283-a68f-42c0-963b-880248263479",
        eventId: "73856ba8-3236-4551-8e5a-256ff8be101b",
      },
      {
        id: "274586e5-78df-40d3-ba2f-7cf18dade803",
        artistIds: ["5b46f6d7-fb59-4a6d-995b-027fe9aedc8a"],
        isLive: false,
        clubId: "5776e283-a68f-42c0-963b-880248263479",
        eventId: "73856ba8-3236-4551-8e5a-256ff8be101b",
      },
      {
        id: "0278a617-3604-4115-975c-dcd886660330",
        artistIds: ["5ad2da0c-d78e-4463-93a2-c4adec363e77"],
        isLive: false,
        clubId: "5776e283-a68f-42c0-963b-880248263479",
        eventId: "73856ba8-3236-4551-8e5a-256ff8be101b",
      },
      {
        id: "49de9a70-7025-4c1a-841c-e0b7a1f0674a",
        artistIds: ["023c5720-55dd-41a8-aef2-34acee6aff34"],
        isLive: false,
        clubId: "5776e283-a68f-42c0-963b-880248263479",
        eventId: "73856ba8-3236-4551-8e5a-256ff8be101b",
      },
      {
        id: "64181da5-0691-4a7d-bb6c-b1dd5fd713a8",
        artistIds: ["f37e0f33-d6a3-4ddc-9ef5-592cb72f396e"],
        isLive: false,
        clubId: "5776e283-a68f-42c0-963b-880248263479",
        eventId: "73856ba8-3236-4551-8e5a-256ff8be101b",
      },
      {
        id: "97ceda46-2eac-411c-bb97-40ad8bc7181f",
        artistIds: ["2029d92c-4266-4950-b32d-faa2d2030735"],
        isLive: false,
        clubId: "5776e283-a68f-42c0-963b-880248263479",
        eventId: "73856ba8-3236-4551-8e5a-256ff8be101b",
      },
      {
        id: "1d4f3a53-83b5-4d21-8453-870f38642669",
        artistIds: ["9c4b0d18-0a7b-4435-b5d8-00c88872ed82"],
        isLive: false,
        clubId: "5776e283-a68f-42c0-963b-880248263479",
        eventId: "73856ba8-3236-4551-8e5a-256ff8be101b",
      },
      {
        id: "8bb6742b-487c-4501-9cc6-6c75432a36b1",
        artistIds: ["b9c6c3ee-a2f7-4afe-9cd6-212bf4ae28e7"],
        isLive: false,
        clubId: "5776e283-a68f-42c0-963b-880248263479",
        eventId: "73856ba8-3236-4551-8e5a-256ff8be101b",
      },
      {
        id: "fb951cc8-a343-43fe-874e-86669206d2f2",
        artistIds: ["df09ae1b-c0ba-422d-b141-7c675bca21b9"],
        isLive: false,
        clubId: "5776e283-a68f-42c0-963b-880248263479",
        eventId: "73856ba8-3236-4551-8e5a-256ff8be101b",
      },
      {
        id: "6b6f7aef-9cd7-4296-ae20-ad0152b31431",
        artistIds: ["9c7711a0-c30b-4e70-90c8-fc80a852051c"],
        isLive: false,
        clubId: "5776e283-a68f-42c0-963b-880248263479",
        eventId: "73856ba8-3236-4551-8e5a-256ff8be101b",
      },
      {
        id: "f5081ad6-2845-4644-a6dc-e8474c1b99aa",
        artistIds: ["ba778735-4cdc-4216-81f9-08566bc13090"],
        isLive: false,
        clubId: "5776e283-a68f-42c0-963b-880248263479",
        eventId: "73856ba8-3236-4551-8e5a-256ff8be101b",
      },
      {
        id: "f62797fe-cd8f-46f1-a1bd-85f2fc28d9bd",
        artistIds: ["ffba5f40-7fc5-47f2-82da-79548eb87fdd"],
        isLive: false,
        clubId: "5776e283-a68f-42c0-963b-880248263479",
        eventId: "73856ba8-3236-4551-8e5a-256ff8be101b",
      },
      {
        id: "bd2245b3-14d2-46da-a0b2-52500bb2cc90",
        artistIds: ["4fa1599f-b436-4015-93cb-c72d0d8b51cf"],
        isLive: false,
        clubId: "5776e283-a68f-42c0-963b-880248263479",
        eventId: "73856ba8-3236-4551-8e5a-256ff8be101b",
      },
      {
        id: "9605a81f-d95b-4f7a-ae8a-ea24e6af6e26",
        artistIds: ["39e34b23-ddf1-4c91-922c-a9c7674d5d71"],
        isLive: false,
        clubId: "5776e283-a68f-42c0-963b-880248263479",
        eventId: "73856ba8-3236-4551-8e5a-256ff8be101b",
      },
      {
        id: "5d02ef9e-7eca-4c75-b85a-1d8578996b41",
        artistIds: ["6cf8ee82-28c8-43cc-a4b9-721a5b423268"],
        isLive: false,
        clubId: "5776e283-a68f-42c0-963b-880248263479",
        eventId: "73856ba8-3236-4551-8e5a-256ff8be101b",
      },
      {
        id: "049de5c1-3956-4525-8ed9-9e3abc6d44e8",
        artistIds: ["b4f0b3f5-3efe-4ff9-9326-c582c7ad8a16"],
        isLive: false,
        clubId: "5776e283-a68f-42c0-963b-880248263479",
        eventId: "73856ba8-3236-4551-8e5a-256ff8be101b",
      },
      {
        id: "3cd88d2e-8dbd-4453-805a-53838762ddf0",
        artistIds: ["2166cfbe-a7e5-4af8-b1c7-a258da719220"],
        isLive: false,
        clubId: "5776e283-a68f-42c0-963b-880248263479",
        eventId: "73856ba8-3236-4551-8e5a-256ff8be101b",
      },
      {
        id: "fc25a02d-181e-45e5-bfd8-2aaed9534910",
        artistIds: ["46c14acf-c453-4129-9923-7d5be6508d35"],
        isLive: false,
        clubId: "5776e283-a68f-42c0-963b-880248263479",
        eventId: "73856ba8-3236-4551-8e5a-256ff8be101b",
      },
      {
        id: "58fb673a-8a78-42eb-b56c-e1a02d807d1f",
        artistIds: ["a0cc6183-5d1c-4f85-a86d-2b7146a91d9c"],
        isLive: false,
        clubId: "5776e283-a68f-42c0-963b-880248263479",
        eventId: "73856ba8-3236-4551-8e5a-256ff8be101b",
      },
      {
        id: "1ece99c8-74d1-44d0-834c-3665abf4f73b",
        artistIds: ["42bbc391-bdc1-4bcc-aed2-a93005d098b5"],
        isLive: false,
        clubId: "5776e283-a68f-42c0-963b-880248263479",
        eventId: "73856ba8-3236-4551-8e5a-256ff8be101b",
      },
      {
        id: "cae655e7-83bb-491b-b7c1-ed39864edf00",
        artistIds: ["5bec87f0-bcb9-490c-814f-95bc2a09f242"],
        isLive: false,
        clubId: "5776e283-a68f-42c0-963b-880248263479",
        eventId: "73856ba8-3236-4551-8e5a-256ff8be101b",
      },
      {
        id: "0bd56ab1-ddde-4d28-98c4-5b38725ae8d9",
        artistIds: ["b3d1c214-2f50-4d01-aafc-029252b29ae4"],
        isLive: false,
        clubId: "5776e283-a68f-42c0-963b-880248263479",
        eventId: "73856ba8-3236-4551-8e5a-256ff8be101b",
      },
      {
        id: "bbfe9dcc-37f9-42d6-b44c-2bdaf5bfb9e6",
        artistIds: ["af4e3798-6f59-4c7f-9d33-ad398f102462"],
        isLive: false,
        clubId: "5776e283-a68f-42c0-963b-880248263479",
        eventId: "73856ba8-3236-4551-8e5a-256ff8be101b",
      },
      {
        id: "3b94c369-3346-431c-b01f-d1bf8e74936c",
        artistIds: ["f2970155-d00a-4457-bb15-b9d940210cde"],
        isLive: false,
        clubId: "5776e283-a68f-42c0-963b-880248263479",
        eventId: "73856ba8-3236-4551-8e5a-256ff8be101b",
      },
      {
        id: "c4664595-41c1-4ee8-a646-af372a982857",
        artistIds: ["631c3de9-9f74-422d-bba7-5c100af3d547"],
        isLive: false,
        clubId: "5776e283-a68f-42c0-963b-880248263479",
        eventId: "73856ba8-3236-4551-8e5a-256ff8be101b",
      },
      {
        id: "28c256b2-fe52-4855-9a22-fbef72a05b44",
        artistIds: ["09b6bb4e-3e2c-4e97-9580-e9a1f8daccaa"],
        isLive: false,
        clubId: "5776e283-a68f-42c0-963b-880248263479",
        eventId: "73856ba8-3236-4551-8e5a-256ff8be101b",
      },
      {
        id: "18fba508-27aa-4e5c-98f6-38d5c8d36b21",
        artistIds: ["097b0b30-92db-4aa7-be4b-f3ffc82184e4"],
        isLive: false,
        clubId: "5776e283-a68f-42c0-963b-880248263479",
        eventId: "73856ba8-3236-4551-8e5a-256ff8be101b",
      },
    ],
  },
  {
    clubId: "b246a380-c3fb-4019-8536-14aabedf1696",
    id: "34d24b38-e31e-483a-a89d-3ba9547ec3fc",
    name: "Klubnacht",
    description:
      'Berghain describes some of the artists as "Hypnotic and Psychedelic". All the others are as always high-quality, so no dissapointments.',
    highlightedSetIds: [],
    image: {
      url:
        "https://www.residentadvisor.net/images/events/flyer/2019/10/de-1012-1317783-1497319-front.jpg",
      creditee: "Unknown",
    },
    lastTimeStamp: moment().add(6, "hours").format(),
    startDateTime: moment().format(),
    sets: [
      {
        id: "aef86dc5-b908-4c4d-921d-af8b691b329f",
        artistIds: ["2ae72442-c24e-40a9-961a-5ca7b3b7d357"],
        isLive: false,
        clubId: "b246a380-c3fb-4019-8536-14aabedf1696",
        eventId: "34d24b38-e31e-483a-a89d-3ba9547ec3fc",
        floorId: "c8d60116-2bb6-4726-b095-4b0fffeee6ea",
      },
      {
        id: "8389d863-c72e-44d7-aef9-21a6f697bc1b",
        artistIds: ["77ede2ee-69b4-4693-a74f-0efd393ffac8"],
        isLive: false,
        clubId: "b246a380-c3fb-4019-8536-14aabedf1696",
        eventId: "34d24b38-e31e-483a-a89d-3ba9547ec3fc",
        floorId: "c8d60116-2bb6-4726-b095-4b0fffeee6ea",
      },
      {
        id: "7e67503f-7709-425d-a3fc-f22ddb5dd80d",
        artistIds: ["81c0000b-8b43-4c8d-ac0a-abab9a234921"],
        isLive: false,
        clubId: "b246a380-c3fb-4019-8536-14aabedf1696",
        eventId: "34d24b38-e31e-483a-a89d-3ba9547ec3fc",
        floorId: "c8d60116-2bb6-4726-b095-4b0fffeee6ea",
      },
      {
        id: "fd824a37-2d76-4c9b-bb11-7e5c4c9b6999",
        artistIds: ["8bbe580a-5664-400e-8629-6e02dbcf3f2e"],
        isLive: false,
        clubId: "b246a380-c3fb-4019-8536-14aabedf1696",
        eventId: "34d24b38-e31e-483a-a89d-3ba9547ec3fc",
        floorId: "c8d60116-2bb6-4726-b095-4b0fffeee6ea",
      },
      {
        id: "63a8579e-4bf2-46c2-9b52-937ff0de8cf8",
        artistIds: ["369fb31a-a3e5-4ea0-a741-320d3f93fe15"],
        isLive: false,
        clubId: "b246a380-c3fb-4019-8536-14aabedf1696",
        eventId: "34d24b38-e31e-483a-a89d-3ba9547ec3fc",
        floorId: "c8d60116-2bb6-4726-b095-4b0fffeee6ea",
      },
      {
        id: "9d281721-f3a6-4b38-a7bf-0b96155e649a",
        artistIds: ["14f4981c-cbc9-4776-b557-d7588468268a"],
        isLive: false,
        clubId: "b246a380-c3fb-4019-8536-14aabedf1696",
        eventId: "34d24b38-e31e-483a-a89d-3ba9547ec3fc",
        floorId: "c8d60116-2bb6-4726-b095-4b0fffeee6ea",
      },
      {
        id: "ffd91e76-57cb-460c-8430-d325cd9494e7",
        artistIds: ["f286ef47-29d4-48bf-8b84-4a2a6a31d344"],
        isLive: false,
        clubId: "b246a380-c3fb-4019-8536-14aabedf1696",
        eventId: "34d24b38-e31e-483a-a89d-3ba9547ec3fc",
        floorId: "c8d60116-2bb6-4726-b095-4b0fffeee6ea",
      },
      {
        id: "bb269e7c-bdb8-4515-ae85-0be8be1c302e",
        artistIds: ["742d045e-b0e2-4d5c-a5ba-b2d551b1fcd9"],
        isLive: false,
        clubId: "b246a380-c3fb-4019-8536-14aabedf1696",
        eventId: "34d24b38-e31e-483a-a89d-3ba9547ec3fc",
        floorId: "c8d60116-2bb6-4726-b095-4b0fffeee6ea",
      },
      {
        id: "eedcebba-2278-4804-baae-90e9257b0d39",
        artistIds: ["e7561b15-bd10-4478-b3ee-712929df5eb5"],
        isLive: false,
        clubId: "b246a380-c3fb-4019-8536-14aabedf1696",
        eventId: "34d24b38-e31e-483a-a89d-3ba9547ec3fc",
        floorId: "c8d60116-2bb6-4726-b095-4b0fffeee6ea",
      },
      {
        id: "2871a538-7417-4356-b1ea-ee697fd17a5f",
        artistIds: ["acc49e74-eebb-4437-9ebe-359529de4e42"],
        isLive: false,
        clubId: "b246a380-c3fb-4019-8536-14aabedf1696",
        eventId: "34d24b38-e31e-483a-a89d-3ba9547ec3fc",
        floorId: "7dc1516d-b052-46d2-ac33-804b29b592f8",
      },
      {
        id: "e1dcaf6b-1e07-4776-876b-9c1116bea7d5",
        artistIds: ["3ea8b85e-5134-4dfc-963f-8c810e993201"],
        isLive: false,
        clubId: "b246a380-c3fb-4019-8536-14aabedf1696",
        eventId: "34d24b38-e31e-483a-a89d-3ba9547ec3fc",
        floorId: "7dc1516d-b052-46d2-ac33-804b29b592f8",
      },
      {
        id: "a5ac10a3-c41b-4ed9-acc7-6a5134b7788c",
        artistIds: ["0bc4c058-0385-4446-9e44-bfce643a978d"],
        isLive: false,
        clubId: "b246a380-c3fb-4019-8536-14aabedf1696",
        eventId: "34d24b38-e31e-483a-a89d-3ba9547ec3fc",
        floorId: "7dc1516d-b052-46d2-ac33-804b29b592f8",
      },
      {
        id: "d2877a11-afa8-4bab-be0c-420fd44dd212",
        artistIds: ["d3e4ee59-5abc-44b5-a01b-b7ac368f8258"],
        isLive: false,
        clubId: "b246a380-c3fb-4019-8536-14aabedf1696",
        eventId: "34d24b38-e31e-483a-a89d-3ba9547ec3fc",
        floorId: "7dc1516d-b052-46d2-ac33-804b29b592f8",
      },
      {
        id: "25d841ba-e1da-4d17-bdec-0ec29f2d5d17",
        artistIds: ["454760a5-9d96-4c7b-8660-dd2d26452f32"],
        isLive: false,
        clubId: "b246a380-c3fb-4019-8536-14aabedf1696",
        eventId: "34d24b38-e31e-483a-a89d-3ba9547ec3fc",
        floorId: "7dc1516d-b052-46d2-ac33-804b29b592f8",
      },
      {
        id: "0c58637b-b901-44a3-9f33-afc2c111fe3b",
        artistIds: ["678fce06-8e4d-46e4-a9b3-eb1ff04a3638"],
        isLive: false,
        clubId: "b246a380-c3fb-4019-8536-14aabedf1696",
        eventId: "34d24b38-e31e-483a-a89d-3ba9547ec3fc",
        floorId: "7dc1516d-b052-46d2-ac33-804b29b592f8",
      },
      {
        id: "cfaff09a-eba2-4fce-b02f-052ddabd6bbe",
        artistIds: ["2a0a72a4-956f-4d5b-a1a0-a45696adb40a"],
        isLive: false,
        clubId: "b246a380-c3fb-4019-8536-14aabedf1696",
        eventId: "34d24b38-e31e-483a-a89d-3ba9547ec3fc",
        floorId: "7dc1516d-b052-46d2-ac33-804b29b592f8",
      },
      {
        id: "fbc2063b-a3d5-4bac-98c7-e83f39682405",
        artistIds: ["3122d784-482e-461a-82c8-c5f9add66612"],
        isLive: false,
        clubId: "b246a380-c3fb-4019-8536-14aabedf1696",
        eventId: "34d24b38-e31e-483a-a89d-3ba9547ec3fc",
        floorId: "7dc1516d-b052-46d2-ac33-804b29b592f8",
      },
    ],
  },
  {
    clubId: "a4eab261-4477-4b9d-b068-8124d9678466",
    id: "d37d8fea-a534-493c-8a0d-6a5133346e81",
    name: "Savour The Moment",
    description:
      "Tonight is about making the planet greener. For every clubber €1 is donated to atmosfair, €0,50 by you and €0,50 by the club. Oh, and hand in your phone!",
    highlightedSetIds: [],
    image: {
      url:
        "https://www.residentadvisor.net/images/events/flyer/2019/10/de-1012-1305172-front.jpg",
      creditee: "Unknown",
    },
    lastTimeStamp: "2020-10-13T14:00:00+02:00",
    startDateTime: "2020-10-12T23:59:00+02:00",
    sets: [
      {
        id: "5d8d3724-a043-4e3c-a326-b52a526efd84",
        artistIds: ["d8edac37-8e4d-4f38-9121-882a42254b01"],
        isLive: false,
        clubId: "a4eab261-4477-4b9d-b068-8124d9678466",
        eventId: "d37d8fea-a534-493c-8a0d-6a5133346e81",
      },
      {
        id: "1b6419a6-0501-4c1e-9d65-a5bf3a5eaa62",
        artistIds: ["20be83b5-3da6-47e0-bff8-ca22ef4bec65"],
        isLive: false,
        clubId: "a4eab261-4477-4b9d-b068-8124d9678466",
        eventId: "d37d8fea-a534-493c-8a0d-6a5133346e81",
      },
      {
        id: "4764dd10-c1ce-4564-8f56-9f7a90aa7236",
        artistIds: ["8fe0bb6f-415e-4a3d-adee-214555183ff5"],
        isLive: false,
        clubId: "a4eab261-4477-4b9d-b068-8124d9678466",
        eventId: "d37d8fea-a534-493c-8a0d-6a5133346e81",
      },
      {
        id: "1f178e64-a776-431e-b24c-77e9b9332bb2",
        artistIds: ["c5d51f53-5684-4b21-9a8f-e00049c77296"],
        isLive: false,
        clubId: "a4eab261-4477-4b9d-b068-8124d9678466",
        eventId: "d37d8fea-a534-493c-8a0d-6a5133346e81",
      },
      {
        id: "24dc9293-375f-4ffb-893f-26669a83ff5a",
        artistIds: ["5b3bc6f7-6cda-4e87-9e2f-d753c8a41ad5"],
        isLive: false,
        clubId: "a4eab261-4477-4b9d-b068-8124d9678466",
        eventId: "d37d8fea-a534-493c-8a0d-6a5133346e81",
      },
      {
        id: "eee504b7-626d-480a-8a94-55a34d1752b9",
        artistIds: ["be4568f0-47cb-47e2-98d0-e68e92a7d117"],
        isLive: false,
        clubId: "a4eab261-4477-4b9d-b068-8124d9678466",
        eventId: "d37d8fea-a534-493c-8a0d-6a5133346e81",
      },
      {
        id: "9b374a53-6841-4242-be09-c7401ea45398",
        artistIds: ["0914d744-4d4a-4818-a2ad-084e455c6028"],
        isLive: false,
        clubId: "a4eab261-4477-4b9d-b068-8124d9678466",
        eventId: "d37d8fea-a534-493c-8a0d-6a5133346e81",
      },
      {
        id: "f4beaa7b-1b9e-4fa0-9d3c-71ec2f7b1296",
        artistIds: ["a2652757-772e-43c1-b8b5-4a042dfeac11"],
        isLive: false,
        clubId: "a4eab261-4477-4b9d-b068-8124d9678466",
        eventId: "d37d8fea-a534-493c-8a0d-6a5133346e81",
      },
      {
        id: "84953db9-fb01-4526-b1e6-f4c0a06c10cc",
        artistIds: ["40d3f76a-3041-4b5f-b1b3-91df3ed734c0"],
        isLive: false,
        clubId: "a4eab261-4477-4b9d-b068-8124d9678466",
        eventId: "d37d8fea-a534-493c-8a0d-6a5133346e81",
      },
    ],
  },
  {
    clubId: "e57e670b-9dab-4520-9034-c3ea24d7d478",
    id: "795c8778-aad3-4149-9d39-b96a2c6b9e8e",
    name: "4 Years of OECUS",
    description:
      "With a lineup of dark beats on the befbf81b-220e-419b-8741-ea3bb599cf8c floor and Italian Disco at the 86b40349-b5c8-44f4-9204-6c48eb84a2d1 one can expect to be raving all day.",
    highlightedSetIds: [],
    image: {
      url:
        "https://www.residentadvisor.net/images/events/flyer/2019/10/de-1012-1317049-front.jpg",
      creditee: "Unknown",
    },
    startDateTime: "2020-10-12T23:00:00+02:00",
    lastTimeStamp: "2020-10-13T10:00:00+02:00",
    sets: [
      {
        id: "a04960bd-a3f1-4c2f-a166-bf48953593e8",
        artistIds: ["d526a59f-0682-4c7e-89c9-fbf845fd808a"],
        isLive: false,
        clubId: "e57e670b-9dab-4520-9034-c3ea24d7d478",
        eventId: "795c8778-aad3-4149-9d39-b96a2c6b9e8e",
        floorId: "befbf81b-220e-419b-8741-ea3bb599cf8c",
      },
      {
        id: "36237f40-1ab3-45f7-84d3-be885b7daa17",
        artistIds: ["761c9b20-8cd5-4e61-83f0-d0044ced9365"],
        isLive: false,
        clubId: "e57e670b-9dab-4520-9034-c3ea24d7d478",
        eventId: "795c8778-aad3-4149-9d39-b96a2c6b9e8e",
        floorId: "befbf81b-220e-419b-8741-ea3bb599cf8c",
      },
      {
        id: "a6dff3cd-a8cc-451b-8374-7487509a390b",
        artistIds: ["111e260b-1e7c-484c-a4e1-06d06e2a0248"],
        isLive: false,
        clubId: "e57e670b-9dab-4520-9034-c3ea24d7d478",
        eventId: "795c8778-aad3-4149-9d39-b96a2c6b9e8e",
        floorId: "86b40349-b5c8-44f4-9204-6c48eb84a2d1",
      },
      {
        id: "5341ec7d-0047-43b6-a9fb-f40bf086ac24",
        artistIds: ["a5aec2d4-4542-49c3-aa7d-4dc31f635a68"],
        isLive: false,
        clubId: "e57e670b-9dab-4520-9034-c3ea24d7d478",
        eventId: "795c8778-aad3-4149-9d39-b96a2c6b9e8e",
        floorId: "befbf81b-220e-419b-8741-ea3bb599cf8c",
      },
      {
        id: "441eeac9-394e-407e-8b51-3bac0a05f8bb",
        artistIds: ["8a52429e-7442-4c16-83f1-9e5f6397326b"],
        isLive: false,
        clubId: "e57e670b-9dab-4520-9034-c3ea24d7d478",
        eventId: "795c8778-aad3-4149-9d39-b96a2c6b9e8e",
        floorId: "befbf81b-220e-419b-8741-ea3bb599cf8c",
      },
      {
        id: "d4333df5-f707-41d0-ab8b-6124808ca44f",
        artistIds: ["52ca5c9b-422c-4c37-8e61-9be0aba2f629"],
        isLive: false,
        clubId: "e57e670b-9dab-4520-9034-c3ea24d7d478",
        eventId: "795c8778-aad3-4149-9d39-b96a2c6b9e8e",
        floorId: "86b40349-b5c8-44f4-9204-6c48eb84a2d1",
      },
      {
        id: "98cd4cf4-5830-4528-af00-b2b8d2d7c82f",
        artistIds: ["0d83500e-14f8-4d77-93d7-19d9cd1b4bd5"],
        isLive: false,
        clubId: "e57e670b-9dab-4520-9034-c3ea24d7d478",
        eventId: "795c8778-aad3-4149-9d39-b96a2c6b9e8e",
        floorId: "86b40349-b5c8-44f4-9204-6c48eb84a2d1",
      },
      {
        id: "68279349-eb64-4887-9766-d7430e786ad0",
        artistIds: ["29bbe435-cf99-4af3-8fb7-d4c5ae2d71f8"],
        isLive: false,
        clubId: "e57e670b-9dab-4520-9034-c3ea24d7d478",
        eventId: "795c8778-aad3-4149-9d39-b96a2c6b9e8e",
        floorId: "befbf81b-220e-419b-8741-ea3bb599cf8c",
      },
    ],
  },
  {
    clubId: "97e6762e-f782-42c6-8ceb-ce57e776c0b6",
    id: "74d9146f-69ba-40e9-ba5a-63d3a0beb63f",
    name: "Griessmuehle x Khidi",
    residentAdvisorEventId: 1323566,
    facebookEventId: 2963525603662349,
    description:
      "Regal & Acient Methods will blast this party to bits, with a whole trail of epic artists.",
    highlightedSetIds: [],
    image: {
      url:
        "https://www.residentadvisor.net/images/events/flyer/2019/10/de-1018-1323566-1494464-front.jpg",
      creditee: "Unknown",
    },
    lastTimeStamp: "2020-10-11T22:00:00+02:00",
    startDateTime: "2020-10-12T22:00:00+02:00",
    sets: [
      {
        id: "30298e5a-2007-449e-b305-7914a883996d",
        artistIds: ["4ebfeaca-b23d-405f-9da8-dd699f4930b3"],
        isLive: false,
        clubId: "97e6762e-f782-42c6-8ceb-ce57e776c0b6",
        eventId: "74d9146f-69ba-40e9-ba5a-63d3a0beb63f",
        floorId: "8a318c2b-aac9-413c-9f33-95ca739ea978",
      },
      {
        id: "8aa499f8-1324-433d-90f2-ba43096fce8d",
        artistIds: ["0ed95dbc-b092-444b-937f-fc0e2f591094"],
        isLive: false,
        clubId: "97e6762e-f782-42c6-8ceb-ce57e776c0b6",
        eventId: "74d9146f-69ba-40e9-ba5a-63d3a0beb63f",
        floorId: "8a318c2b-aac9-413c-9f33-95ca739ea978",
      },
      {
        id: "c3116d69-834c-459e-a972-f00237656118",
        artistIds: ["3c57e9ba-7b9c-4fb0-90a4-beb81199b016"],
        isLive: false,
        clubId: "97e6762e-f782-42c6-8ceb-ce57e776c0b6",
        eventId: "74d9146f-69ba-40e9-ba5a-63d3a0beb63f",
        floorId: "8a318c2b-aac9-413c-9f33-95ca739ea978",
      },
      {
        id: "48070a8c-b14f-42e6-9485-fe527b1fc856",
        artistIds: ["ff9989a9-f634-4873-b5b9-a74283d716b9"],
        isLive: false,
        clubId: "97e6762e-f782-42c6-8ceb-ce57e776c0b6",
        eventId: "74d9146f-69ba-40e9-ba5a-63d3a0beb63f",
        floorId: "8a318c2b-aac9-413c-9f33-95ca739ea978",
      },
      {
        id: "2112c881-7aa0-470f-a9d1-41c285ece621",
        artistIds: ["36c00a7d-28b2-435b-8b31-c9bb4886479f"],
        isLive: false,
        clubId: "97e6762e-f782-42c6-8ceb-ce57e776c0b6",
        eventId: "74d9146f-69ba-40e9-ba5a-63d3a0beb63f",
        floorId: "8a318c2b-aac9-413c-9f33-95ca739ea978",
      },
      {
        id: "cd7738c1-880f-4766-a415-f04fdbab223a",
        artistIds: ["886d9ad0-3cf2-42e3-bd43-ffd878985fe9"],
        isLive: false,
        clubId: "97e6762e-f782-42c6-8ceb-ce57e776c0b6",
        eventId: "74d9146f-69ba-40e9-ba5a-63d3a0beb63f",
        floorId: "8a318c2b-aac9-413c-9f33-95ca739ea978",
      },
      {
        id: "565d58ff-024b-488a-b56d-eb2e22136ed6",
        artistIds: ["2aaf9261-eef5-45ce-97bf-74bc611cc77a"],
        isLive: false,
        clubId: "97e6762e-f782-42c6-8ceb-ce57e776c0b6",
        eventId: "74d9146f-69ba-40e9-ba5a-63d3a0beb63f",
        floorId: "8a318c2b-aac9-413c-9f33-95ca739ea978",
      },
      {
        id: "39a486ef-56b8-48cc-bb1d-86e2c8beab61",
        artistIds: ["d0546141-575c-4ad4-a714-53f177d2149b"],
        isLive: false,
        clubId: "97e6762e-f782-42c6-8ceb-ce57e776c0b6",
        eventId: "74d9146f-69ba-40e9-ba5a-63d3a0beb63f",
        floorId: "571bfc64-3f48-48a2-9c00-347bb34b807c",
      },
      {
        id: "3e05a065-d673-4c85-9597-40680168d5c4",
        artistIds: ["2d4e1838-8407-4b2f-b58f-da2798000f06"],
        isLive: false,
        clubId: "97e6762e-f782-42c6-8ceb-ce57e776c0b6",
        eventId: "74d9146f-69ba-40e9-ba5a-63d3a0beb63f",
        floorId: "571bfc64-3f48-48a2-9c00-347bb34b807c",
      },
      {
        id: "11002405-8350-4d0d-95de-c4ae34359706",
        artistIds: ["ed4a77be-e2dd-419a-9171-f76d31a7e1ad"],
        isLive: false,
        clubId: "97e6762e-f782-42c6-8ceb-ce57e776c0b6",
        eventId: "74d9146f-69ba-40e9-ba5a-63d3a0beb63f",
        floorId: "571bfc64-3f48-48a2-9c00-347bb34b807c",
      },
      {
        id: "6b874106-e584-400d-bd50-57a47464f5b5",
        artistIds: ["6f671fd2-649c-47e4-9430-7f7e5f67bfea"],
        isLive: false,
        clubId: "97e6762e-f782-42c6-8ceb-ce57e776c0b6",
        eventId: "74d9146f-69ba-40e9-ba5a-63d3a0beb63f",
        floorId: "571bfc64-3f48-48a2-9c00-347bb34b807c",
      },
      {
        id: "45054da5-38cf-41fd-b927-ef65d06c457e",
        artistIds: ["6184d14b-06bc-46d7-ad28-ca280022a206"],
        isLive: false,
        clubId: "97e6762e-f782-42c6-8ceb-ce57e776c0b6",
        eventId: "74d9146f-69ba-40e9-ba5a-63d3a0beb63f",
        floorId: "cc796fd5-c414-4e97-85e4-a247d279aff5",
      },
      {
        id: "d3fd28f8-b6ad-4a5c-a6d7-1de64adf8311",
        artistIds: ["7ae6e6c5-cec7-4e30-931c-1c3102df1f99"],
        isLive: false,
        clubId: "97e6762e-f782-42c6-8ceb-ce57e776c0b6",
        eventId: "74d9146f-69ba-40e9-ba5a-63d3a0beb63f",
        floorId: "cc796fd5-c414-4e97-85e4-a247d279aff5",
      },
      {
        id: "b93e01fa-447f-4318-964b-0c7e5aeb0180",
        artistIds: ["7a618213-65f5-46c0-a984-da1fd4222c40"],
        isLive: false,
        clubId: "97e6762e-f782-42c6-8ceb-ce57e776c0b6",
        eventId: "74d9146f-69ba-40e9-ba5a-63d3a0beb63f",
        floorId: "cc796fd5-c414-4e97-85e4-a247d279aff5",
      },
      {
        id: "4c90ae25-e3e7-4a7b-8a61-db2ec61a3e5d",
        artistIds: ["90a88293-29c7-4b9f-b3bc-58555abb3450"],
        isLive: false,
        clubId: "97e6762e-f782-42c6-8ceb-ce57e776c0b6",
        eventId: "74d9146f-69ba-40e9-ba5a-63d3a0beb63f",
        floorId: "cc796fd5-c414-4e97-85e4-a247d279aff5",
      },
    ],
  },
  {
    clubId: "b246a380-c3fb-4019-8536-14aabedf1696",
    id: "4e765f08-02cd-488d-a6ad-5bbaf580968a",
    name: "Pan x Mannequin",
    description:
      "Two labels are matching up to provide you a wide range of music.",
    highlightedSetIds: [],
    image: {
      url:
        "https://www.residentadvisor.net/images/events/flyer/2019/10/de-1018-1317788-1506251-back.jpg",
      creditee: "Resident Advisor",
    },
    lastTimeStamp: "2020-11-19T09:00:00+02:00",
    startDateTime: "2020-11-18T23:59:00+02:00",
    sets: [
      {
        id: "be13d7ab-c03d-46d0-9de7-119d2d4b2193",
        artistIds: ["fccd979b-f128-4664-8edc-6851eec4e9c6"],
        isLive: false,
        clubId: "b246a380-c3fb-4019-8536-14aabedf1696",
        eventId: "4e765f08-02cd-488d-a6ad-5bbaf580968a",
        floorId: "c8d60116-2bb6-4726-b095-4b0fffeee6ea",
      },
      {
        id: "e6ac7ee0-2884-496e-a823-8e0063b6d75c",
        artistIds: ["2cb8a6fd-e21c-4da7-815f-fe637caae938"],
        isLive: false,
        clubId: "b246a380-c3fb-4019-8536-14aabedf1696",
        eventId: "4e765f08-02cd-488d-a6ad-5bbaf580968a",
        floorId: "c8d60116-2bb6-4726-b095-4b0fffeee6ea",
      },
      {
        id: "8b391ea2-0988-4053-8831-38345b91e5e8",
        artistIds: ["a2665118-5129-45fc-af84-7ea35ef5beb1"],
        isLive: false,
        clubId: "b246a380-c3fb-4019-8536-14aabedf1696",
        eventId: "4e765f08-02cd-488d-a6ad-5bbaf580968a",
        floorId: "c8d60116-2bb6-4726-b095-4b0fffeee6ea",
      },
      {
        id: "95415281-6b47-40cf-bb45-6d12089cf0dd",
        artistIds: ["a54c62f9-dd95-4054-a00f-9cb9d243161d"],
        isLive: false,
        clubId: "b246a380-c3fb-4019-8536-14aabedf1696",
        eventId: "4e765f08-02cd-488d-a6ad-5bbaf580968a",
        floorId: "c8d60116-2bb6-4726-b095-4b0fffeee6ea",
      },
      {
        id: "d8fbc38b-ced8-45b1-8484-2eb415953719",
        artistIds: ["cbd1787b-eaac-4433-9b01-4ab686dbcb51"],
        isLive: false,
        clubId: "b246a380-c3fb-4019-8536-14aabedf1696",
        eventId: "4e765f08-02cd-488d-a6ad-5bbaf580968a",
        floorId: "c8d60116-2bb6-4726-b095-4b0fffeee6ea",
      },
      {
        id: "ba381c42-6d0c-43e8-bd20-22ea6f4a9df0",
        artistIds: ["7a6ea1a3-4ab6-488c-ab9a-ceecd2dceec9"],
        isLive: false,
        clubId: "b246a380-c3fb-4019-8536-14aabedf1696",
        eventId: "4e765f08-02cd-488d-a6ad-5bbaf580968a",
        floorId: "c8d60116-2bb6-4726-b095-4b0fffeee6ea",
      },
      {
        id: "ef910b56-8f5c-45cb-b26a-3cab473ae60e",
        artistIds: ["dbbce746-1652-4fd1-93ff-c10b45a03e07"],
        isLive: false,
        clubId: "b246a380-c3fb-4019-8536-14aabedf1696",
        eventId: "4e765f08-02cd-488d-a6ad-5bbaf580968a",
        floorId: "c8d60116-2bb6-4726-b095-4b0fffeee6ea",
      },
      {
        id: "5f630d5c-9aaa-45bf-9dee-345cf576fc56",
        artistIds: ["3f424711-1879-4a52-8536-6af343bbcd76"],
        isLive: false,
        clubId: "b246a380-c3fb-4019-8536-14aabedf1696",
        eventId: "4e765f08-02cd-488d-a6ad-5bbaf580968a",
        floorId: "c8d60116-2bb6-4726-b095-4b0fffeee6ea",
      },
      {
        id: "13ef1390-6b7a-42ae-9a8a-94757266b34d",
        artistIds: ["4d2f94c1-1504-46ec-9c85-59c9217d8997"],
        isLive: false,
        clubId: "b246a380-c3fb-4019-8536-14aabedf1696",
        eventId: "4e765f08-02cd-488d-a6ad-5bbaf580968a",
        floorId: "7dc1516d-b052-46d2-ac33-804b29b592f8",
      },
      {
        id: "8e435d57-7208-4f4b-96e9-d6dbb664830b",
        artistIds: ["905894e9-fbab-4d02-9cff-4fa242cf2372"],
        isLive: false,
        clubId: "b246a380-c3fb-4019-8536-14aabedf1696",
        eventId: "4e765f08-02cd-488d-a6ad-5bbaf580968a",
        floorId: "7dc1516d-b052-46d2-ac33-804b29b592f8",
      },
      {
        id: "5a4187bc-9c1d-4c81-a27c-a2155aa60c30",
        artistIds: ["90e23b70-32be-4927-a8ae-4a85cddec0d7"],
        isLive: false,
        clubId: "b246a380-c3fb-4019-8536-14aabedf1696",
        eventId: "4e765f08-02cd-488d-a6ad-5bbaf580968a",
        floorId: "7dc1516d-b052-46d2-ac33-804b29b592f8",
      },
      {
        id: "45f33a2a-c3f8-44c6-b923-20adee45fbd5",
        artistIds: ["01f0f6f9-9b09-42ee-8675-dda23f7a18d0"],
        isLive: false,
        clubId: "b246a380-c3fb-4019-8536-14aabedf1696",
        eventId: "4e765f08-02cd-488d-a6ad-5bbaf580968a",
        floorId: "7dc1516d-b052-46d2-ac33-804b29b592f8",
      },
      {
        id: "fde8e665-d053-4780-8423-2c89353bfc49",
        artistIds: ["d9b50b75-8046-404d-a1d5-4cbdaaad7a9c"],
        isLive: false,
        clubId: "b246a380-c3fb-4019-8536-14aabedf1696",
        eventId: "4e765f08-02cd-488d-a6ad-5bbaf580968a",
        floorId: "7dc1516d-b052-46d2-ac33-804b29b592f8",
      },
    ],
  },
  {
    clubId: "5776e283-a68f-42c0-963b-880248263479",
    id: "afb22592-dcb5-4247-9a80-6e65eaa4a159",
    name: "Syndikat",
    description:
      "Techno | Disco | House | Downtempo as they describe the music. For every visitor a tree will be planted!",
    highlightedSetIds: [],
    image: {
      url:
        "https://www.residentadvisor.net/images/events/flyer/2019/10/de-1018-1321451-front.jpg",
      creditee: "Resident Advisor",
    },
    lastTimeStamp: "2020-11-19T10:00:00+02:00",
    startDateTime: "2020-11-18T23:59:00+02:00",
    onlineTicketInformation: {
      price: 16.9,
      url: "https://www.residentadvisor.net/events/1321451",
    },
    sets: [
      {
        id: "4fc6b60c-bbf6-4d88-be99-170da4a1bf4b",
        artistIds: ["cb42cbd6-6924-453a-9eab-7dbb1f53d473"],
        isLive: false,
        clubId: "5776e283-a68f-42c0-963b-880248263479",
        eventId: "afb22592-dcb5-4247-9a80-6e65eaa4a159",
      },
      {
        id: "48abacba-50b2-4370-b100-7c3b31a5701d",
        artistIds: ["0db38648-8983-4428-86a6-bb25a2d71ac4"],
        isLive: false,
        clubId: "5776e283-a68f-42c0-963b-880248263479",
        eventId: "afb22592-dcb5-4247-9a80-6e65eaa4a159",
      },
      {
        id: "c3ce764e-e318-46bb-828f-3543203005dc",
        artistIds: ["f63aa644-e7b4-41e9-bb69-c8bf60165a0d"],
        isLive: false,
        clubId: "5776e283-a68f-42c0-963b-880248263479",
        eventId: "afb22592-dcb5-4247-9a80-6e65eaa4a159",
      },
      {
        id: "60b5d32b-70c9-4164-98e9-2397c316ab48",
        artistIds: ["376f3f91-fda1-43de-a7b1-8f936d25a344"],
        isLive: false,
        clubId: "5776e283-a68f-42c0-963b-880248263479",
        eventId: "afb22592-dcb5-4247-9a80-6e65eaa4a159",
      },
      {
        id: "604a11af-eb72-423a-891c-53c5c0dc16ab",
        artistIds: ["af7e43e9-1197-4029-9a69-3020517fca4f"],
        isLive: false,
        clubId: "5776e283-a68f-42c0-963b-880248263479",
        eventId: "afb22592-dcb5-4247-9a80-6e65eaa4a159",
      },
      {
        id: "ca1d19d6-e4d2-4894-b322-3d2a4a3c307c",
        artistIds: ["25153d74-c047-4c5e-b593-da7fb485963e"],
        isLive: false,
        clubId: "5776e283-a68f-42c0-963b-880248263479",
        eventId: "afb22592-dcb5-4247-9a80-6e65eaa4a159",
      },
      {
        id: "9ef4bdf1-9e32-4820-84b7-eadf7eb8d498",
        artistIds: ["2c68bef6-1f77-453f-84c9-b9824b7c076e"],
        isLive: false,
        clubId: "5776e283-a68f-42c0-963b-880248263479",
        eventId: "afb22592-dcb5-4247-9a80-6e65eaa4a159",
      },
      {
        id: "62484da9-89d7-4e54-a295-0c91c17ada50",
        artistIds: ["64f63e56-1e15-4bb8-8398-757caac6b79b"],
        isLive: false,
        clubId: "5776e283-a68f-42c0-963b-880248263479",
        eventId: "afb22592-dcb5-4247-9a80-6e65eaa4a159",
      },
      {
        id: "437a103f-2951-48a3-95e6-9ef4055f3120",
        artistIds: ["097b0b30-92db-4aa7-be4b-f3ffc82184e4"],
        isLive: false,
        clubId: "5776e283-a68f-42c0-963b-880248263479",
        eventId: "afb22592-dcb5-4247-9a80-6e65eaa4a159",
      },
      {
        id: "5f3a43f7-7b3a-4410-a9dc-12f9a70ef986",
        artistIds: ["da5512e4-a1c9-43d5-8e09-faf82e7e4286"],
        isLive: false,
        clubId: "5776e283-a68f-42c0-963b-880248263479",
        eventId: "afb22592-dcb5-4247-9a80-6e65eaa4a159",
      },
      {
        id: "27fa6cb9-f675-4d05-99ad-762e4177586d",
        artistIds: ["2b3b10b0-afd2-4a8f-89bf-8e40d52539d6"],
        isLive: false,
        clubId: "5776e283-a68f-42c0-963b-880248263479",
        eventId: "afb22592-dcb5-4247-9a80-6e65eaa4a159",
      },
      {
        id: "f58b47da-03ad-4750-9374-07242a5911d6",
        artistIds: ["84263300-6024-437a-b7ae-8d938da02f02"],
        isLive: false,
        clubId: "5776e283-a68f-42c0-963b-880248263479",
        eventId: "afb22592-dcb5-4247-9a80-6e65eaa4a159",
      },
      {
        id: "a3b1c324-3dd9-4f8b-92ee-5f5bbb64dd67",
        artistIds: ["e1110d4d-c502-4039-a522-eea07a74f695"],
        isLive: false,
        clubId: "5776e283-a68f-42c0-963b-880248263479",
        eventId: "afb22592-dcb5-4247-9a80-6e65eaa4a159",
      },
      {
        id: "fd4f5fbf-bcea-4db8-9737-34e476e38e8d",
        artistIds: ["d27f617a-49b0-40f8-8dd3-6841262d501b"],
        isLive: false,
        clubId: "5776e283-a68f-42c0-963b-880248263479",
        eventId: "afb22592-dcb5-4247-9a80-6e65eaa4a159",
      },
    ],
  },
  {
    clubId: "e57e670b-9dab-4520-9034-c3ea24d7d478",
    id: "c4dfe476-5b02-489e-95db-a526f6b97044",
    name: "Disobedient Circumstance #9",
    description: "Groovy beats till agressive music! Dance away!",
    highlightedSetIds: [],
    image: {
      url:
        "https://www.residentadvisor.net/images/events/flyer/2019/10/de-1019-1309453-1483357-front.jpg",
      creditee: "Resident Advisor",
    },
    lastTimeStamp: "2020-11-20T10:00:00+02:00",
    startDateTime: "2020-11-19T23:59:00+02:00",
    sets: [
      {
        id: "f0ddb15f-57f1-4566-acb1-48b6138ef7e8",
        artistIds: ["5fa74ff9-3306-40d5-a630-2fe2225ce1eb"],
        isLive: false,
        clubId: "e57e670b-9dab-4520-9034-c3ea24d7d478",
        eventId: "c4dfe476-5b02-489e-95db-a526f6b97044",
      },
      {
        id: "7dfffc31-93db-41dc-abad-5d53451bd6c0",
        artistIds: ["10c7f6c4-fbd5-48a5-aaba-cf9a67752e96"],
        isLive: false,
        clubId: "e57e670b-9dab-4520-9034-c3ea24d7d478",
        eventId: "c4dfe476-5b02-489e-95db-a526f6b97044",
      },
      {
        id: "264db6c1-00b1-4854-aa7e-6ac48f847ecd",
        artistIds: ["fb802988-adf3-4899-b118-aa1196a59890"],
        isLive: false,
        clubId: "e57e670b-9dab-4520-9034-c3ea24d7d478",
        eventId: "c4dfe476-5b02-489e-95db-a526f6b97044",
      },
      {
        id: "c60251ea-acce-4fe3-9993-95b20c3a89b9",
        artistIds: ["761c9b20-8cd5-4e61-83f0-d0044ced9365"],
        isLive: false,
        clubId: "e57e670b-9dab-4520-9034-c3ea24d7d478",
        eventId: "c4dfe476-5b02-489e-95db-a526f6b97044",
      },
      {
        id: "e17c5023-4daa-479b-b19b-93c6279523c4",
        artistIds: ["c1832f1c-6633-44da-963d-2d84d02c9a85"],
        isLive: false,
        clubId: "e57e670b-9dab-4520-9034-c3ea24d7d478",
        eventId: "c4dfe476-5b02-489e-95db-a526f6b97044",
      },
      {
        id: "90efa2b1-abb0-4cf6-89db-703bbbf9dd51",
        artistIds: ["54dcbb36-3092-48be-9297-5b9576831bb2"],
        isLive: false,
        clubId: "e57e670b-9dab-4520-9034-c3ea24d7d478",
        eventId: "c4dfe476-5b02-489e-95db-a526f6b97044",
      },
      {
        id: "4a474563-3473-4a3a-94b8-f0e6c0574747",
        artistIds: ["58bddd67-6e0e-4947-84ec-2ad728cc312a"],
        isLive: false,
        clubId: "e57e670b-9dab-4520-9034-c3ea24d7d478",
        eventId: "c4dfe476-5b02-489e-95db-a526f6b97044",
      },
      {
        id: "ce57e6dd-3a34-4e65-b4d8-7f84a54573f4",
        artistIds: ["95fbcbf5-4acc-4533-aee2-832440066371"],
        isLive: false,
        clubId: "e57e670b-9dab-4520-9034-c3ea24d7d478",
        eventId: "c4dfe476-5b02-489e-95db-a526f6b97044",
      },
      {
        id: "eca0db2d-7477-4277-a6fb-4f39ea8b736f",
        artistIds: ["18027e6b-33b0-4a51-8f66-b3409977e82a"],
        isLive: false,
        clubId: "e57e670b-9dab-4520-9034-c3ea24d7d478",
        eventId: "c4dfe476-5b02-489e-95db-a526f6b97044",
      },
    ],
  },
  {
    clubId: "b246a380-c3fb-4019-8536-14aabedf1696",
    id: "a3ce9d66-53d2-4fdf-909e-3a1e7b842f91",
    name: "Klubnacht",
    description:
      "Tonight has some strong influences of Hip-hop and Techno. Colin Benders leading the lineup is going to make this crazy.",
    highlightedSetIds: [],
    image: {
      url:
        "https://www.residentadvisor.net/images/events/flyer/2019/10/de-1019-1317794-1497322-front.jpg",
      creditee: "Resident Advisor",
    },
    lastTimeStamp: "2020-11-21T06:00:00+02:00",
    startDateTime: "2020-11-19T23:59:00+02:00",
    sets: [
      {
        id: "cd924722-c265-4056-9f66-a114020976cc",
        artistIds: ["94494b02-d09e-485f-904b-dcfd503f845b"],
        isLive: false,
        clubId: "b246a380-c3fb-4019-8536-14aabedf1696",
        eventId: "a3ce9d66-53d2-4fdf-909e-3a1e7b842f91",
        floorId: "c8d60116-2bb6-4726-b095-4b0fffeee6ea",
      },
      {
        id: "078a1fde-560d-408f-9696-9afd597066ae",
        artistIds: ["00bb13a9-bad8-49ac-9942-a632c3d25a60"],
        isLive: false,
        clubId: "b246a380-c3fb-4019-8536-14aabedf1696",
        eventId: "a3ce9d66-53d2-4fdf-909e-3a1e7b842f91",
        floorId: "c8d60116-2bb6-4726-b095-4b0fffeee6ea",
      },
      {
        id: "e96d4df3-f817-4d83-845e-b337644ddd00",
        artistIds: ["0739af34-871a-4f47-ab59-420439389e34"],
        isLive: false,
        clubId: "b246a380-c3fb-4019-8536-14aabedf1696",
        eventId: "a3ce9d66-53d2-4fdf-909e-3a1e7b842f91",
        floorId: "c8d60116-2bb6-4726-b095-4b0fffeee6ea",
      },
      {
        id: "f6688a70-eb33-4623-8846-62a61753b90a",
        artistIds: ["143097e5-56ad-461b-8f54-a0af2a3be15c"],
        isLive: false,
        clubId: "b246a380-c3fb-4019-8536-14aabedf1696",
        eventId: "a3ce9d66-53d2-4fdf-909e-3a1e7b842f91",
        floorId: "c8d60116-2bb6-4726-b095-4b0fffeee6ea",
      },
      {
        id: "9e35dd2d-58a0-4bd3-9bf5-f3e1c27dd08d",
        artistIds: ["b9ad3eb3-59e7-45c8-ab67-bd57ec1b405e"],
        isLive: false,
        clubId: "b246a380-c3fb-4019-8536-14aabedf1696",
        eventId: "a3ce9d66-53d2-4fdf-909e-3a1e7b842f91",
        floorId: "c8d60116-2bb6-4726-b095-4b0fffeee6ea",
      },
      {
        id: "22f60354-a420-43fc-a8ed-dbff8917da47",
        artistIds: ["4d4099cc-28dd-4744-9bf3-68bfe823a52b"],
        isLive: false,
        clubId: "b246a380-c3fb-4019-8536-14aabedf1696",
        eventId: "a3ce9d66-53d2-4fdf-909e-3a1e7b842f91",
        floorId: "c8d60116-2bb6-4726-b095-4b0fffeee6ea",
      },
      {
        id: "d4792d9b-3a7f-4133-b24c-6ec6b3dfd699",
        artistIds: ["3a353322-3987-4cd5-9664-b9b2ab11930c"],
        isLive: false,
        clubId: "b246a380-c3fb-4019-8536-14aabedf1696",
        eventId: "a3ce9d66-53d2-4fdf-909e-3a1e7b842f91",
        floorId: "c8d60116-2bb6-4726-b095-4b0fffeee6ea",
      },
      {
        id: "026d0708-9756-456e-a427-c97bfeec9e47",
        artistIds: ["81786516-4b7d-44ad-b6f8-e8fae61d8b02"],
        isLive: false,
        clubId: "b246a380-c3fb-4019-8536-14aabedf1696",
        eventId: "a3ce9d66-53d2-4fdf-909e-3a1e7b842f91",
        floorId: "c8d60116-2bb6-4726-b095-4b0fffeee6ea",
      },
      {
        id: "cfc3df1e-d50b-4996-ad06-2bf13204d1c0",
        artistIds: ["1e4be740-6fee-4f49-999c-8e5546b79d2c"],
        isLive: false,
        clubId: "b246a380-c3fb-4019-8536-14aabedf1696",
        eventId: "a3ce9d66-53d2-4fdf-909e-3a1e7b842f91",
        floorId: "c8d60116-2bb6-4726-b095-4b0fffeee6ea",
      },
      {
        id: "333a9651-2891-4358-a3fb-75d655760604",
        artistIds: ["cf638f6d-c922-404a-b803-4b4da67c3dab"],
        isLive: false,
        clubId: "b246a380-c3fb-4019-8536-14aabedf1696",
        eventId: "a3ce9d66-53d2-4fdf-909e-3a1e7b842f91",
        floorId: "7dc1516d-b052-46d2-ac33-804b29b592f8",
      },
      {
        id: "66a3ddab-bf72-48f2-98d8-a86eb60f1a4a",
        artistIds: ["a03ec402-373a-4816-ac0e-3a39668ef185"],
        isLive: false,
        clubId: "b246a380-c3fb-4019-8536-14aabedf1696",
        eventId: "a3ce9d66-53d2-4fdf-909e-3a1e7b842f91",
        floorId: "7dc1516d-b052-46d2-ac33-804b29b592f8",
      },
      {
        id: "0931afc5-59c1-48ab-8472-2cab029442c1",
        artistIds: ["8180a848-0c06-457d-b448-225afe241b7c"],
        isLive: false,
        clubId: "b246a380-c3fb-4019-8536-14aabedf1696",
        eventId: "a3ce9d66-53d2-4fdf-909e-3a1e7b842f91",
        floorId: "7dc1516d-b052-46d2-ac33-804b29b592f8",
      },
      {
        id: "4fed499e-2e1d-40f2-99bf-3a0afccafc67",
        artistIds: ["c0ec0795-1ffd-4da5-acbd-8caa24fac0a7"],
        isLive: false,
        clubId: "b246a380-c3fb-4019-8536-14aabedf1696",
        eventId: "a3ce9d66-53d2-4fdf-909e-3a1e7b842f91",
        floorId: "7dc1516d-b052-46d2-ac33-804b29b592f8",
      },
      {
        id: "5a705ea7-2e4a-4ceb-87f7-e1e06c8bc877",
        artistIds: ["0202e968-e722-46f2-b834-a8d3ce6de010"],
        isLive: false,
        clubId: "b246a380-c3fb-4019-8536-14aabedf1696",
        eventId: "a3ce9d66-53d2-4fdf-909e-3a1e7b842f91",
        floorId: "7dc1516d-b052-46d2-ac33-804b29b592f8",
      },
      {
        id: "910ebeaf-31ea-46a7-ac09-0110efbfa9c9",
        artistIds: ["b2efbd46-0354-4987-848b-b565dbc37e3a"],
        isLive: false,
        clubId: "b246a380-c3fb-4019-8536-14aabedf1696",
        eventId: "a3ce9d66-53d2-4fdf-909e-3a1e7b842f91",
        floorId: "7dc1516d-b052-46d2-ac33-804b29b592f8",
      },
      {
        id: "b97e5007-ccae-47ab-a235-e9869dc72322",
        artistIds: ["a76ad61d-1c6b-412d-b0e7-a189908fd436"],
        isLive: false,
        clubId: "b246a380-c3fb-4019-8536-14aabedf1696",
        eventId: "a3ce9d66-53d2-4fdf-909e-3a1e7b842f91",
        floorId: "7dc1516d-b052-46d2-ac33-804b29b592f8",
      },
      {
        id: "6fb7ac26-a7cc-4662-bc38-66c94def9724",
        artistIds: ["5f9ca509-3329-428a-bbe3-e9583fb1c086"],
        isLive: false,
        clubId: "b246a380-c3fb-4019-8536-14aabedf1696",
        eventId: "a3ce9d66-53d2-4fdf-909e-3a1e7b842f91",
        floorId: "7dc1516d-b052-46d2-ac33-804b29b592f8",
      },
      {
        id: "1a12dd08-cc32-4d08-bb37-eb0945752f98",
        artistIds: ["add62ea8-4b9b-4854-a7ed-1f65928aa365"],
        isLive: false,
        clubId: "b246a380-c3fb-4019-8536-14aabedf1696",
        eventId: "a3ce9d66-53d2-4fdf-909e-3a1e7b842f91",
        floorId: "7dc1516d-b052-46d2-ac33-804b29b592f8",
      },
    ],
  },

  {
    clubId: "273c0042-b0f2-4bf0-ad84-5e06f173704b",
    description: "",
    highlightedSetIds: [],
    id: "2dc7bc82-0f60-4e70-a71f-c1263b27061a",
    image: {
      url:
        "https://www.residentadvisor.net/images/events/flyer/2019/12/nl-1231-1313104-1504506-front.jpg",
      creditee: "Resident Advisor",
    },
    name: "VERKNIPT New Years Eve",
    startDateTime: "2019-12-31T22:00:00+01:00",
    lastTimeStamp: "2020-01-01T07:00:00+01:00",
    sets: [
      {
        artistIds: ["30b3faac-5e47-4df0-9bd5-e25e4ed8c139"],
        clubId: "273c0042-b0f2-4bf0-ad84-5e06f173704b",
        eventId: "2dc7bc82-0f60-4e70-a71f-c1263b27061a",
        id: "a79b4ae4-d9fd-4d23-9210-86b9155e3359",
        isLive: false,
      },
      {
        artistIds: ["5fdc071e-ea3c-4e60-8cf7-15c0c07da722"],
        clubId: "273c0042-b0f2-4bf0-ad84-5e06f173704b",
        eventId: "2dc7bc82-0f60-4e70-a71f-c1263b27061a",
        id: "33f97b71-8444-4eec-93a7-ce09d82d566e",
        isLive: false,
      },
      {
        artistIds: ["ebcc62d5-92b6-4891-92bf-b933d0084d21"],
        clubId: "273c0042-b0f2-4bf0-ad84-5e06f173704b",
        eventId: "2dc7bc82-0f60-4e70-a71f-c1263b27061a",
        id: "8e1430ae-931e-4dac-8041-d4435e9451b6",
        isLive: false,
      },
      {
        artistIds: ["45cd2230-db15-4ede-81cc-71d04acc9529"],
        clubId: "273c0042-b0f2-4bf0-ad84-5e06f173704b",
        eventId: "2dc7bc82-0f60-4e70-a71f-c1263b27061a",
        id: "e937e010-eb23-4add-ab45-29d68759de91",
        isLive: false,
      },
      {
        artistIds: ["cf14e5a6-dcf3-4399-b60d-6c3403209caa"],
        clubId: "273c0042-b0f2-4bf0-ad84-5e06f173704b",
        eventId: "2dc7bc82-0f60-4e70-a71f-c1263b27061a",
        id: "78095c53-4e75-4cc0-b386-8fcd4009cc73",
        isLive: false,
      },
      {
        artistIds: [
          "ee6df5d9-7fed-4f5d-9df7-3c9bcf637528",
          "2c1402e3-897c-4120-9bbd-67e56b7a25a7",
        ],
        clubId: "273c0042-b0f2-4bf0-ad84-5e06f173704b",
        eventId: "2dc7bc82-0f60-4e70-a71f-c1263b27061a",
        id: "f1f0b303-361a-491b-be37-9815395275ac",
        isLive: false,
      },
      {
        artistIds: ["fed6cc61-581d-4e8b-89e2-0147526e2794"],
        clubId: "273c0042-b0f2-4bf0-ad84-5e06f173704b",
        eventId: "2dc7bc82-0f60-4e70-a71f-c1263b27061a",
        id: "c52a5d0c-e43d-4b57-8587-b5647e35d57e",
        isLive: false,
      },
    ],
  },
  {
    clubId: "6a659b8c-a950-4d0a-b46a-fd1484c4654f",
    description: "",
    highlightedSetIds: [],
    id: "72c55d98-b804-4e26-95ae-3851838af8e7",
    image: {
      url:
        "https://www.residentadvisor.net/images/events/flyer/2019/12/nl-1231-1343064-1532611-front.jpg",
      creditee: "Resident Advisor",
    },
    name: "NYE Around The World 2020",
    startDateTime: "2019-12-31T22:00:00+01:00",
    lastTimeStamp: "2020-01-01T07:00:00+01:00",
    sets: [
      {
        artistIds: ["04f0b5db-55c0-405b-9b89-3ad7721edae1"],
        clubId: "6a659b8c-a950-4d0a-b46a-fd1484c4654f",
        eventId: "72c55d98-b804-4e26-95ae-3851838af8e7",
        id: "7817ebd0-622b-4abb-92f1-053af5089c2b",
        isLive: false,
      },
      {
        artistIds: ["089ca7eb-2728-496d-9e61-543bc86ceb57"],
        clubId: "6a659b8c-a950-4d0a-b46a-fd1484c4654f",
        eventId: "72c55d98-b804-4e26-95ae-3851838af8e7",
        id: "2d0c546d-3edb-4181-b8cc-37bcc35c9e24",
        isLive: false,
      },
      {
        artistIds: ["96a2632c-7ab4-4c35-aa65-5addcc436c90"],
        clubId: "6a659b8c-a950-4d0a-b46a-fd1484c4654f",
        eventId: "72c55d98-b804-4e26-95ae-3851838af8e7",
        id: "1b1ea292-8a36-406d-94ab-b1ae5096e85b",
        isLive: false,
      },
      {
        artistIds: ["049a3cce-c7c3-4c98-b1b3-be148f540766"],
        clubId: "6a659b8c-a950-4d0a-b46a-fd1484c4654f",
        eventId: "72c55d98-b804-4e26-95ae-3851838af8e7",
        id: "b6b1c0ba-1105-417f-99fe-c49af28107b2",
        isLive: false,
      },
      {
        artistIds: ["045dfc9c-02b7-41b9-91d8-b93c7bc73064"],
        clubId: "6a659b8c-a950-4d0a-b46a-fd1484c4654f",
        eventId: "72c55d98-b804-4e26-95ae-3851838af8e7",
        id: "d8b5bc36-dd5d-42f9-88c8-f78891578c15",
        isLive: false,
      },
      {
        artistIds: ["f0d4aeea-7db6-4e88-9477-ade9f365d937"],
        clubId: "6a659b8c-a950-4d0a-b46a-fd1484c4654f",
        eventId: "72c55d98-b804-4e26-95ae-3851838af8e7",
        id: "13efc24c-c665-47bb-a780-cd9572fe9fb4",
        isLive: false,
      },
    ],
  },
  {
    clubId: "98751239-bb46-4fcc-9c3f-79375909efa9",
    description: "",
    highlightedSetIds: [],
    id: "571e6789-2433-4f12-bf3d-d7dc1e489bbc",
    image: {
      url:
        "https://www.residentadvisor.net/images/events/flyer/2019/12/nl-1231-1333260-1542510-front.jpg",
      creditee: "Resident Advisor",
    },
    name: "Het Grote Nieuwsjaarsfeest with Gerd Janson, Carista and More",
    startDateTime: "2019-12-31T21:00:00+01:00",
    lastTimeStamp: "2020-01-01T06:00:00+01:00",
    sets: [
      {
        artistIds: ["c0ec0795-1ffd-4da5-acbd-8caa24fac0a7"],
        clubId: "98751239-bb46-4fcc-9c3f-79375909efa9",
        eventId: "571e6789-2433-4f12-bf3d-d7dc1e489bbc",
        id: "582e80be-c7a9-46b3-bde4-2fad90376c74",
        isLive: false,
      },
      {
        artistIds: ["a462cf3f-f5ea-4c34-9021-3657261c3ecd"],
        clubId: "98751239-bb46-4fcc-9c3f-79375909efa9",
        eventId: "571e6789-2433-4f12-bf3d-d7dc1e489bbc",
        id: "53e308fa-15e4-43ce-8867-c647e08c81ce",
        isLive: false,
      },
      {
        artistIds: ["847bc832-fbcc-4d06-8fb5-8cb5a00190f1"],
        clubId: "98751239-bb46-4fcc-9c3f-79375909efa9",
        eventId: "571e6789-2433-4f12-bf3d-d7dc1e489bbc",
        id: "48aa78e6-d088-4c68-bab2-6d88e06a3f25",
        isLive: false,
      },
      {
        artistIds: ["cb1b516b-848e-4ae7-8b49-25cec8be99e6"],
        clubId: "98751239-bb46-4fcc-9c3f-79375909efa9",
        eventId: "571e6789-2433-4f12-bf3d-d7dc1e489bbc",
        id: "20faae61-605a-470f-b6b9-ccb9681e5c16",
        isLive: false,
      },
      {
        artistIds: ["91892426-dfd4-46eb-8a11-24835ee58e15"],
        clubId: "98751239-bb46-4fcc-9c3f-79375909efa9",
        eventId: "571e6789-2433-4f12-bf3d-d7dc1e489bbc",
        id: "c59cf41d-ec10-4d3c-a32c-1f10d8961eaf",
        isLive: false,
      },
      {
        artistIds: ["87149f14-636a-40c9-83d9-43503e0959e0"],
        clubId: "98751239-bb46-4fcc-9c3f-79375909efa9",
        eventId: "571e6789-2433-4f12-bf3d-d7dc1e489bbc",
        id: "4058b97d-26e6-46a4-8b6f-600f418f235c",
        isLive: false,
      },
      {
        artistIds: ["6ad1fe84-2fec-4d83-a5fa-30672e5cb606"],
        clubId: "98751239-bb46-4fcc-9c3f-79375909efa9",
        eventId: "571e6789-2433-4f12-bf3d-d7dc1e489bbc",
        id: "8d17fccc-6d5c-48aa-ba6f-085b13130c4b",
        isLive: false,
      },
    ],
  },
  {
    clubId: "f4c0bd28-64a9-40cf-b65e-bd79e6b98f5a",
    description: "",
    highlightedSetIds: [],
    id: "fc912e3a-95b8-4a66-9bc8-94cf41c5d2de",
    image: {
      url:
        "https://www.residentadvisor.net/images/events/flyer/2019/12/nl-1231-1327301-front.jpg",
      creditee: "Resident Advisor",
    },
    name: "RADION=ON – an extraordinary new years eve",
    startDateTime: "2019-12-31T22:00:00+01:00",
    lastTimeStamp: "2020-01-01T12:00:00+01:00",
    sets: [
      {
        artistIds: ["426e97b9-aed1-4983-8f54-0a794a29e2c6"],
        clubId: "f4c0bd28-64a9-40cf-b65e-bd79e6b98f5a",
        eventId: "fc912e3a-95b8-4a66-9bc8-94cf41c5d2de",
        id: "432f75c5-3a45-4fb4-a8a7-a6583bb20f30",
        isLive: false,
      },
      {
        artistIds: ["14a0e6c3-1b99-4546-8f19-30b750202f61"],
        clubId: "f4c0bd28-64a9-40cf-b65e-bd79e6b98f5a",
        eventId: "fc912e3a-95b8-4a66-9bc8-94cf41c5d2de",
        id: "66e0d193-20ed-41a1-b12f-d794f337cf3c",
        isLive: false,
      },
      {
        artistIds: ["4ea6afaa-9d94-4324-afe8-644276a920d9"],
        clubId: "f4c0bd28-64a9-40cf-b65e-bd79e6b98f5a",
        eventId: "fc912e3a-95b8-4a66-9bc8-94cf41c5d2de",
        id: "ca798049-eeb4-483a-964b-ce2706505f62",
        isLive: false,
      },
      {
        artistIds: ["3cf4c6ad-a492-45ef-9590-1b3dcdd55dad"],
        clubId: "f4c0bd28-64a9-40cf-b65e-bd79e6b98f5a",
        eventId: "fc912e3a-95b8-4a66-9bc8-94cf41c5d2de",
        id: "34f9d2a1-4cb9-46ff-adf1-18aae44bb128",
        isLive: false,
      },
      {
        artistIds: ["132ef321-909e-4c78-af58-41536d6e69ab"],
        clubId: "f4c0bd28-64a9-40cf-b65e-bd79e6b98f5a",
        eventId: "fc912e3a-95b8-4a66-9bc8-94cf41c5d2de",
        id: "169fdaa1-22fa-4e3b-af8a-8e23944b315f",
        isLive: false,
      },
      {
        artistIds: ["e9e5e4e8-fc40-4376-8dbe-b4dd7922b2db"],
        clubId: "f4c0bd28-64a9-40cf-b65e-bd79e6b98f5a",
        eventId: "fc912e3a-95b8-4a66-9bc8-94cf41c5d2de",
        id: "7118b92b-d7ec-44d2-9a5e-6f07f3e291bb",
        isLive: false,
      },
      {
        artistIds: ["bbbfc91d-9368-4de9-8f7d-4630601be55b"],
        clubId: "f4c0bd28-64a9-40cf-b65e-bd79e6b98f5a",
        eventId: "fc912e3a-95b8-4a66-9bc8-94cf41c5d2de",
        id: "778a6446-a916-4891-8af7-adb00445a0b8",
        isLive: false,
      },
      {
        artistIds: ["074c14f8-6605-4567-942d-85da54141429"],
        clubId: "f4c0bd28-64a9-40cf-b65e-bd79e6b98f5a",
        eventId: "fc912e3a-95b8-4a66-9bc8-94cf41c5d2de",
        id: "ffec0e97-ec16-4aba-b7bc-60c6e983e366",
        isLive: false,
      },
      {
        artistIds: ["56487d96-124d-47e3-97bd-892e43dc1ea8"],
        clubId: "f4c0bd28-64a9-40cf-b65e-bd79e6b98f5a",
        eventId: "fc912e3a-95b8-4a66-9bc8-94cf41c5d2de",
        id: "bee4633f-2f81-44fd-8483-d7e6e8cbab58",
        isLive: false,
      },
      {
        artistIds: ["70f8b655-a73f-431b-9403-caf883388ead"],
        clubId: "f4c0bd28-64a9-40cf-b65e-bd79e6b98f5a",
        eventId: "fc912e3a-95b8-4a66-9bc8-94cf41c5d2de",
        id: "f61fd295-25d1-4c53-b3c0-9174ce5798d8",
        isLive: false,
      },
      {
        artistIds: ["5be3db2a-a626-415a-a4fa-5298fa4912de"],
        clubId: "f4c0bd28-64a9-40cf-b65e-bd79e6b98f5a",
        eventId: "fc912e3a-95b8-4a66-9bc8-94cf41c5d2de",
        id: "5dcc1dee-61c1-4bb8-8ccd-b05f2b30c578",
        isLive: false,
      },
      {
        artistIds: [
          "1e2e1c32-95b9-46ef-a23c-bd9d3cc0bd28",
          "439a6037-9229-42a4-84b8-698cef8f1e5e",
        ],
        clubId: "f4c0bd28-64a9-40cf-b65e-bd79e6b98f5a",
        eventId: "fc912e3a-95b8-4a66-9bc8-94cf41c5d2de",
        id: "a881e111-5a49-42a7-a418-791d11089554",
        isLive: false,
      },
      {
        artistIds: ["1bf9fa0e-6800-46fb-ab28-6bae60da19b3"],
        clubId: "f4c0bd28-64a9-40cf-b65e-bd79e6b98f5a",
        eventId: "fc912e3a-95b8-4a66-9bc8-94cf41c5d2de",
        id: "dc752541-4531-4ade-9d42-b2d84bfa684b",
        isLive: false,
      },
      {
        artistIds: ["6f9617d5-2e40-4b20-8630-41c0aa0e9041"],
        clubId: "f4c0bd28-64a9-40cf-b65e-bd79e6b98f5a",
        eventId: "fc912e3a-95b8-4a66-9bc8-94cf41c5d2de",
        id: "c29bda92-62a9-41fa-bbd8-614ee7ab3017",
        isLive: false,
      },
    ],
  },
  {
    clubId: "dc555dab-8f88-494a-b225-5189e5a3b96c",
    description: "",
    highlightedSetIds: [],
    id: "9f1f0bd8-4d6f-4a63-950e-3322516592ef",
    image: {
      url:
        "https://www.residentadvisor.net/images/events/flyer/2019/12/nl-1231-1350297-front.jpg",
      creditee: "Resident Advisor",
    },
    name:
      "Shelter NYE Special; Jackmaster, William Djoko, Carlos Valdes, Alinka",
    startDateTime: "2019-12-31T23:59:00+01:00",
    lastTimeStamp: "2020-01-01T08:00:00+01:00",
    sets: [
      {
        artistIds: ["d1eb283b-6cd5-448d-8434-dacc80ec9698"],
        clubId: "dc555dab-8f88-494a-b225-5189e5a3b96c",
        eventId: "9f1f0bd8-4d6f-4a63-950e-3322516592ef",
        id: "090cd224-89bc-4817-b49d-35885b70270e",
        isLive: false,
      },
      {
        artistIds: ["da63330a-13c8-4543-93fb-784d70183581"],
        clubId: "dc555dab-8f88-494a-b225-5189e5a3b96c",
        eventId: "9f1f0bd8-4d6f-4a63-950e-3322516592ef",
        id: "a1fbf9df-737b-4f0b-9c5d-74f0c41872b9",
        isLive: false,
      },
      {
        artistIds: ["0ad54702-d349-432e-8900-287cd3820e87"],
        clubId: "dc555dab-8f88-494a-b225-5189e5a3b96c",
        eventId: "9f1f0bd8-4d6f-4a63-950e-3322516592ef",
        id: "414c33b8-552c-4993-aa84-696b54688736",
        isLive: false,
      },
      {
        artistIds: ["68ae9918-0477-47c3-a7d2-248aba30181e"],
        clubId: "dc555dab-8f88-494a-b225-5189e5a3b96c",
        eventId: "9f1f0bd8-4d6f-4a63-950e-3322516592ef",
        id: "68340d53-465a-4cd2-86bc-b88090a334c0",
        isLive: false,
      },
    ],
  },
  {
    clubId: "a40092f5-4204-4691-8c6a-1ed28f2a9b75",
    description: "",
    highlightedSetIds: [],
    id: "8fa829bf-4588-44db-8762-4e322d4b239f",
    image: {
      url:
        "https://www.residentadvisor.net/images/events/flyer/2019/12/nl-1231-1330427-1507659-front.jpg",
      creditee: "Resident Advisor",
    },
    name: "Awakenings 31.12.19",
    startDateTime: "2019-12-31T21:00:00+01:00",
    lastTimeStamp: "2020-01-01T08:00:00+01:00",
    sets: [
      {
        artistIds: ["7102643a-3999-455a-93ac-ea66a44ea771"],
        clubId: "a40092f5-4204-4691-8c6a-1ed28f2a9b75",
        eventId: "8fa829bf-4588-44db-8762-4e322d4b239f",
        id: "5567af29-f45e-4036-8ccf-fc5c83c18d74",
        startTime: "2019-12-31T21:00:00+01:00",
        floorId: "01e9b833-f915-4098-ae02-b22f764a4932",
        isLive: false,
      },
      {
        artistIds: ["056f4f14-211f-40a8-a4ed-fefa0675ff25"],
        clubId: "a40092f5-4204-4691-8c6a-1ed28f2a9b75",
        eventId: "8fa829bf-4588-44db-8762-4e322d4b239f",
        id: "8016a42c-1798-466c-a58b-9f666ce47220",
        startTime: "2019-12-31T22:30:00+01:00",
        floorId: "01e9b833-f915-4098-ae02-b22f764a4932",
        isLive: false,
      },
      {
        artistIds: ["df224902-f774-4e0f-9009-53b7c75ec496"],
        clubId: "a40092f5-4204-4691-8c6a-1ed28f2a9b75",
        eventId: "8fa829bf-4588-44db-8762-4e322d4b239f",
        id: "33a6bf0f-2561-4ead-ad3d-5c3d93559049",
        startTime: "2020-01-01T00:00:00+01:00",
        floorId: "01e9b833-f915-4098-ae02-b22f764a4932",
        isLive: false,
      },
      {
        artistIds: ["c701effe-d024-4f1a-98b0-b5b39aa5cbca"],
        clubId: "a40092f5-4204-4691-8c6a-1ed28f2a9b75",
        eventId: "8fa829bf-4588-44db-8762-4e322d4b239f",
        id: "2b012928-eb42-4fec-aee8-d4416babfad0",
        startTime: "2020-01-01T02:00:00+01:00",
        floorId: "01e9b833-f915-4098-ae02-b22f764a4932",
        isLive: false,
      },
      {
        artistIds: ["f25f88d1-2068-40d2-9953-bc962b4280e9"],
        clubId: "a40092f5-4204-4691-8c6a-1ed28f2a9b75",
        eventId: "8fa829bf-4588-44db-8762-4e322d4b239f",
        id: "4f827217-dbcc-472c-898b-b0cedd6031aa",
        startTime: "2020-01-01T04:00:00+01:00",
        floorId: "01e9b833-f915-4098-ae02-b22f764a4932",
        isLive: false,
      },
      {
        artistIds: ["16d1779b-598a-4c05-ac83-869778228140"],
        clubId: "a40092f5-4204-4691-8c6a-1ed28f2a9b75",
        eventId: "8fa829bf-4588-44db-8762-4e322d4b239f",
        id: "011e9d5d-0fa0-4e7c-97e1-0a7eb7a6056b",
        startTime: "2020-01-01T06:00:00+01:00",
        floorId: "01e9b833-f915-4098-ae02-b22f764a4932",
        isLive: false,
      },
    ],
  },
];

export default events;
