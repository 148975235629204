import { useDispatch } from "react-redux";
import { Dispatch } from "react";
import shuffle from "lodash/shuffle";
import SerializedEvent, {
  isSerializedEvent
} from "../../data_models/Event/SerializedEvent";
import PlayableItem from "../../data_models/PlayableItem";
import { itemNotPlayable } from "../../utils/error_builders/share_error_builders";
import getSetPreviewInformation from "../../audio_helpers/getSetPreviewInformation";
import { PlayableSetInformation } from "./playerReducer";
import SerializedSetInformation from "../../data_models/SetInformation/SerializedSetInformation";
import fetchSet from "../../api/fetchSet";
import { useReduxSelector } from "../redux_hooks";

export const QUEUE_PREVIEWS = "QUEUE_PREVIEWS";
export const TOGGLE_PAUSED_STATE = "TOGGLE_PAUSED_STATE";
export const RESUME_PREVIEW = "NEXT_PREVIEW";
export const NEXT_PREVIEW = "NEXT_SET_PREVIEW";
export const UPDATE_PLAYER_TIME = "UPDATE_PLAYER_TIME";
export const PLAY_EVENT_AUDIO = "PLAY_EVENT_AUDIO";
export const EMPTY_QUEUE_AND_CURRENT_PREVIEW =
  "EMPTY_QUEUE_AND_CURRENT_PREVIEW";
export const ENABLE_AUTO_PLAY = "ENABLE_AUTO_PLAY";
export const DISABLE_AUTO_PLAY = "DISABLE_AUTO_PLAY";
export const SET_BUFFERING = "SET_BUFFERING";
export const SET_QUEUE_LOADING = "SET_QUEUE_LOADING";
export const SET_DID_PLAYER_ERROR_OCCUR = "SET_DID_PLAYER_ERROR_OCCUR";

interface QueueTracksAction {
  type: typeof QUEUE_PREVIEWS;
  previews: PlayableSetInformation[];
}

interface TogglePausedStateAction {
  type: typeof TOGGLE_PAUSED_STATE;
}

interface UpdateTimeAction {
  type: typeof UPDATE_PLAYER_TIME;
  time: number;
}

interface PlayNextPreviewAction {
  type: typeof NEXT_PREVIEW;
}

interface EmptyQueueAndCurrentTrackAction {
  type: typeof EMPTY_QUEUE_AND_CURRENT_PREVIEW;
}

interface EnableAutoPlayAction {
  type: typeof ENABLE_AUTO_PLAY;
}

interface DisableAutoPlayAction {
  type: typeof DISABLE_AUTO_PLAY;
}

interface SetBufferingAction {
  type: typeof SET_BUFFERING;
  isPlayerPausedBecauseBuffering: boolean;
}

interface SetQueueLoadingAction {
  type: typeof SET_QUEUE_LOADING;
  isQueueLoading: boolean;
}

interface SetDidPlayerErrorOccurAction {
  type: typeof SET_DID_PLAYER_ERROR_OCCUR;
  didPlayerErrorOccur: boolean;
}

export type ReduxPlayerAction =
  | QueueTracksAction
  | UpdateTimeAction
  | PlayNextPreviewAction
  | EmptyQueueAndCurrentTrackAction
  | EnableAutoPlayAction
  | DisableAutoPlayAction
  | SetBufferingAction
  | TogglePausedStateAction
  | SetQueueLoadingAction
  | SetDidPlayerErrorOccurAction;

export const useQueuePreviews = () => {
  const dispatch = useDispatch<Dispatch<QueueTracksAction>>();

  return async (previews: PlayableSetInformation[]) => {
    dispatch({ type: QUEUE_PREVIEWS, previews });
  };
};

export const useEmptyQueueAndCurrentPreview = () => {
  const dispatch = useDispatch<Dispatch<EmptyQueueAndCurrentTrackAction>>();

  return () => {
    dispatch({ type: EMPTY_QUEUE_AND_CURRENT_PREVIEW });
  };
};

export const useTogglePausedState = () => {
  const dispatch = useDispatch<Dispatch<TogglePausedStateAction>>();

  return () => {
    dispatch({ type: TOGGLE_PAUSED_STATE });
  };
};

export const useUpdatePlayerTime = () => {
  const dispatch = useDispatch<Dispatch<UpdateTimeAction>>();

  return (time: number) => {
    dispatch({ type: UPDATE_PLAYER_TIME, time });
  };
};

export const usePlayNextPreview = () => {
  const dispatch = useDispatch<Dispatch<PlayNextPreviewAction>>();

  return () => {
    dispatch({ type: NEXT_PREVIEW });
  };
};

const useSetQueueLoading = () => {
  const dispatch = useDispatch<Dispatch<SetQueueLoadingAction>>();

  return (isQueueLoading: boolean) => {
    dispatch({ type: SET_QUEUE_LOADING, isQueueLoading });
  };
};

export const usePlayEventSetPreviews = () => {
  const activeTrackPlayerState = useReduxSelector(
    state => state.player.activeTrackPlayerState
  );
  const playSetPreviews = useInternalPlaySetPreviews();
  const setAutoPlay = useSetAutoPlay();
  const togglePausedState = useTogglePausedState();

  /**
   * Starts previewing of a new event or if the event is already in player pauses/unpauses it
   * @param event The event that is the target for the preview
   * @param explicitRestartPreview Optional flag that when set to true will restart event preview even if that event is currently already in player
   */
  const actionFunction = async (
    event: SerializedEvent,
    explicitRestartPreview?: boolean
  ) => {
    if (
      activeTrackPlayerState &&
      activeTrackPlayerState.currentSetPreviewInformation.eventId ===
        event.id &&
      !explicitRestartPreview
    ) {
      togglePausedState();
    } else {
      setAutoPlay(true);
      playSetPreviews(event.sets);
    }
  };

  return actionFunction;
};

export const usePlaySetPreview = () => {
  const activeTrackPlayerState = useReduxSelector(
    store => store.player.activeTrackPlayerState
  );
  const setIdFocusedInPlayer =
    activeTrackPlayerState &&
    activeTrackPlayerState.currentSetPreviewInformation.setId;
  const togglePausedState = useTogglePausedState();
  const playSetPreviews = useInternalPlaySetPreviews();
  const setAutoPlay = useSetAutoPlay();

  /**
   * Starts previewing of a new set or if the set is already in player pauses/unpauses it
   * @param setOrSetId The set that is the target for the preview
   * @param explicitRestartPreview Optional flag that when set to true will restart set preview even if that set is currently already in player
   */
  const actionFunction = async (
    setOrSetId: SerializedSetInformation | string,
    explicitRestartPreview?: boolean
  ) => {
    const getSetId = () => {
      if (typeof setOrSetId === "string") {
        return setOrSetId;
      } else {
        return setOrSetId.id;
      }
    };
    const getSet = async () => {
      if (typeof setOrSetId === "string") {
        return fetchSet(setOrSetId);
      } else {
        return setOrSetId;
      }
    };

    if (getSetId() === setIdFocusedInPlayer && !explicitRestartPreview) {
      togglePausedState();
    } else {
      const set = await getSet();

      setAutoPlay(false);
      playSetPreviews([set]);
    }
  };

  return actionFunction;
};

const useInternalPlaySetPreviews = () => {
  const emptyQueueAndCurrentPreview = useEmptyQueueAndCurrentPreview();
  const queueTracks = useQueuePreviews();
  const setIsQueueLoading = useSetQueueLoading();
  const playNextTrack = usePlayNextPreview();

  return async (sets: SerializedSetInformation[]) => {
    setIsQueueLoading(true);
    emptyQueueAndCurrentPreview();
    const [firstSet, ...remainderSets] = shuffle(sets);

    const firstPreviewableSetInArray = await getSetPreviewInformation([
      firstSet
    ]);

    // We don't want to wait for all track data to be loaded, so we first only queue first track
    await queueTracks(firstPreviewableSetInArray);
    setIsQueueLoading(false);
    await playNextTrack();

    const remainderPreviews = await getSetPreviewInformation(remainderSets);
    await queueTracks(remainderPreviews);
  };
};

export const usePlayPlayableItem = () => {
  const playEventAudio = usePlayEventSetPreviews();

  return (playableItem: PlayableItem) => {
    if (isSerializedEvent(playableItem)) {
      playEventAudio(playableItem);
    } else {
      throw itemNotPlayable();
    }
  };
};

export const useSetAutoPlay = () => {
  const dispatch = useDispatch<
    Dispatch<EnableAutoPlayAction | DisableAutoPlayAction>
  >();

  return (value: boolean) => {
    dispatch({ type: value ? ENABLE_AUTO_PLAY : DISABLE_AUTO_PLAY });
  };
};

export const useSetIsPlayerBuffering = () => {
  const dispatch = useDispatch<Dispatch<SetBufferingAction>>();

  return (isPlayerPausedBecauseBuffering: boolean) => {
    dispatch({ type: SET_BUFFERING, isPlayerPausedBecauseBuffering });
  };
};

export const useSetDidPlayerErrorOccur = () => {
  const dispatch = useDispatch<Dispatch<SetDidPlayerErrorOccurAction>>();

  return (didPlayerErrorOccur: boolean) => {
    dispatch({ type: SET_DID_PLAYER_ERROR_OCCUR, didPlayerErrorOccur });
  };
}
