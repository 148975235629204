import ServerErrorResponse from "../data_models/ServerErrorResponse";

/** Hooks will return either the requested value of Type T, and error or null meaning the hook is still running to get a result */
export type HookReturnValue<T> = T | ServerErrorResponse | null;

export const isHookErrorResponse = <T>(
  returnValue: HookReturnValue<T>
): returnValue is ServerErrorResponse =>
  returnValue !== null &&
  (returnValue as ServerErrorResponse).message !== undefined &&
  (returnValue as ServerErrorResponse).httpCode !== undefined;

export const isHookLoading = <T>(
  returnValue: HookReturnValue<T>
): returnValue is null => returnValue === null;

export const isHookRequestedResult = <T>(
  returnValue: HookReturnValue<T>
): returnValue is T =>
  !isHookErrorResponse(returnValue) && !isHookLoading(returnValue);
