import React from "react";
import { ErrorModalState } from "../../../redux/modal_state/modalReducer";
import { useCloseModal } from "../../../redux/modal_state/modal_actions";
import ErrorMessageModal from "./ErrorMessageModal";

export default function ErrorMessageModalContainer({ error }: ErrorModalState) {
  const closeModal = useCloseModal();

  return <ErrorMessageModal error={error} onRequestClose={closeModal} />;
}
