import React from "react";
import styled from "styled-components";
import LinkButton from "../buttons/LinkButton";
import { Moment } from "moment";
import { defaultCopyFontFamily } from "../../styles/font_styles";

const TextStyler = styled.div`
  font-size: 16px;
  font-family: ${defaultCopyFontFamily};
`;

interface IProps {
  queueLength: number;
  artistNames: string[] | null;
  setFloor?: string;
  setStartTimestamp?: Moment;

  currentTimeStamp: number;
  currentTrackNextTrackTimestamp: number;
  isAutoPlaying: boolean;
  setAutoPlay: (value: boolean) => void;
}

export default function PlayerSubText({
  queueLength,
  artistNames,
  setFloor,
  setStartTimestamp,

  currentTimeStamp,
  currentTrackNextTrackTimestamp,
  isAutoPlaying,
  setAutoPlay
}: IProps) {
  // Transforming data
  const hasNextTrackToPlay = queueLength !== 0;
  const secondsTillNextPreview = getSecondsTillNextTrack(
    currentTimeStamp,
    currentTrackNextTrackTimestamp
  );

  // Creating Copy
  const currentlyPlayingCopy = "Currently playing: ";
  const setFloorFormatted = setFloor || "TBA";
  const setTimeFormatted = setStartTimestamp
    ? setStartTimestamp.format("HH:mm dddd")
    : "TBA";
  const artistsNamesOrLoading = artistNames
    ? artistNames.join(" & ")
    : "Loading...";
  const setArtistCopy = "🎧: " + artistsNamesOrLoading + " | ";
  const setFloorCopy = "⛺: " + setFloorFormatted + " | ";
  const setTimeCopy = "⏲: " + setTimeFormatted + " | ";
  const countdownPrefixCopy = hasNextTrackToPlay
    ? `${queueLength} artists left to preview. Next one in `
    : "This is the last preview. Ending in ";
  const toggleAutoPlayCopy = isAutoPlaying
    ? "Keep listening"
    : "Toggle Auto Playing back on";

  return (
    <TextStyler>
      {currentlyPlayingCopy} {setArtistCopy} {setFloorCopy} {setTimeCopy}{" "}
      {isAutoPlaying &&
        `${countdownPrefixCopy} ${secondsTillNextPreview} seconds | `}
      <LinkButton onClick={() => setAutoPlay(!isAutoPlaying)}>
        {toggleAutoPlayCopy}
      </LinkButton>
    </TextStyler>
  );
}

const getSecondsTillNextTrack = (
  currentTimeStamp: number,
  currentTrackNextTrackTimestamp: number
) => {
  const millisecondsDifference =
    currentTrackNextTrackTimestamp - currentTimeStamp;
  const secondsDifference = Math.round(millisecondsDifference / 1000);

  return secondsDifference;
};
