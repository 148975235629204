import ReactGA from "react-ga";
import React, { useState, useEffect } from "react";
import P from "../Copy/P";
import { Redirect } from "react-router";
import { fallbackRoute } from "../../routes";

interface IProps {
  nonExistentPermalink: string;
}

export default function NonExistentPermalinkView({
  nonExistentPermalink
}: IProps) {
  const [timeOver, setTimeOver] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => setTimeOver(true), 5000);

    ReactGA.event({
      category: "Navigation",
      action: "Navigated to Unknwon Path",
      label: nonExistentPermalink
    });

    return () => {
      clearTimeout(timeoutId);
    };
  }, [nonExistentPermalink]);

  return timeOver ? (
    <Redirect to={fallbackRoute.pathname} />
  ) : (
    <>
      <P>
        Oh no, seems there is no page for {nonExistentPermalink} yet. You
        visiting this page did show us someone is looking for it! Maybe in the
        future it will appear!
      </P>
      <P>You will be redirected in five seconds!</P>
    </>
  );
}
