import styled from "styled-components";
import { defaultCopyFontFamily } from "../../../styles/font_styles";
import { darkGrey } from "../../../styles/colors";
import InspectableImage from "../../InspectableImage/InspectableImage";
import ellipsisTextCss from "../../style_mixins/ellipsisTextCss";

export const EventInformationModalEventImage = styled(InspectableImage)`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

export const EventInformationModalHeaderStyler = styled.div`
  padding: 8px;
`;

export const EventInformationModalEventNameStyler = styled.div`
  font-family: ${defaultCopyFontFamily};
  font-size: 36px;
  font-weight: bold;

  ${ellipsisTextCss}
`;

export const EventInformationModalAtVenueCopyStyler = styled.div`
  font-family: ${defaultCopyFontFamily};
  font-size: 18px;
  color: ${darkGrey};
`;

export const EventInformationModalVenueImageStyler = styled(InspectableImage)`
  width: 200px;
  height: 128px;
`;
