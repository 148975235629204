import cheerio from "cheerio";
import { v4 as uuid } from "uuid";
import moment, { Moment } from "moment";
import SerializedEvent from "../../data_models/Event/SerializedEvent";
import corsResistantFetch from "../corsResistantFetch";
import SerializedArtist from "../../data_models/Artist/SerializedArtist";
import SerializedClub from "../../data_models/Club/SerializedClub";
import SerializedSetInformation from "../../data_models/SetInformation/SerializedSetInformation";

const createEventUrlReferenceErrorMessage = (eventUrl: string) =>
  `Happened during attempted parsing of page at "${eventUrl}"`;

const createEventUrl = (eventId: number) =>
  `https://www.residentadvisor.net/events/${eventId}`;

const scrapeResidentAdvisorEvent = async (
  eventId: number,
  allArtists: SerializedArtist[],
  allClubs: SerializedClub[]
): Promise<{
  event: SerializedEvent;
  newArtists: SerializedArtist[];
} | null> => {
  const eventUrl = createEventUrl(eventId);

  const pageResponse = await corsResistantFetch(eventUrl);
  const pageBody = await pageResponse.text();

  const pageDomNavigator = cheerio.load(pageBody);

  const newEventId = uuid();
  const clubId = getNativeClubIdFromResidentAdvisorPage(
    eventUrl,
    pageDomNavigator,
    allClubs
  );
  const eventImageUrl = getFlyerImageUrlFromResidentAdvisorPage(
    pageDomNavigator
  );

  // TODO: Support non-existing clubs to be made as well
  if (clubId === null) {
    return null;
  }
  // TODO: Support events without images
  if (eventImageUrl === undefined) {
    return null;
  }

  const {
    startDateTime,
    lastTimeStamp
  } = getStartAndEndDateTimeFromResidentAdvisorPage(eventUrl, pageDomNavigator);
  const { sets, newArtists } = getSetsFromResidentAdvisorPage(
    eventUrl,
    pageDomNavigator,
    allArtists,
    newEventId,
    clubId
  );

  return {
    event: {
      clubId,
      description: "",
      highlightedSetIds: [],
      id: newEventId,
      image: {
        url: eventImageUrl,
        creditee: "Resident Advisor"
      },
      name: getEventNameFromResidentAdvisorPage(pageDomNavigator),
      startDateTime,
      lastTimeStamp,
      sets
    },
    newArtists
  };
};

const clubPathRegex = /\/club.aspx\?id=([0-9]+)/;
const getNativeClubIdFromResidentAdvisorPage = (
  pageFullUrl: string,
  pageDomNavigator: CheerioStatic,
  allClubs: SerializedClub[]
): string | null => {
  const clubAnchor = pageDomNavigator("a[title*='Club profile of']");
  const clubPath = clubAnchor.attr("href");
  const clubPathClubIdMatch = clubPathRegex.exec(clubPath);

  if (!clubPathClubIdMatch) {
    // Some events don't have an actual venue linked to them
    // Example: https://www.residentadvisor.net/events/1330122
    return null;
  }

  const residentAdvisorClubId = parseInt(clubPathClubIdMatch[1]);

  const relatedClub = allClubs.find(
    club => club.residentAdvisorId === residentAdvisorClubId
  );

  if (!relatedClub) {
    // TODO: Parse clubs that we don't have in our DB yet instead of ignoring them
    return null;
  }

  return relatedClub.id;
};

const getFlyerImageUrlFromResidentAdvisorPage = (
  pageDomNavigator: CheerioStatic
) => {
  const flyerDiv = pageDomNavigator("div.flyer");
  const flyerImageElement = flyerDiv.find("img").first();
  const flyerImageSrc = flyerImageElement.attr("src");

  if (flyerImageElement === undefined) {
    return undefined;
  }

  const imageUrl = `https://www.residentadvisor.net${flyerImageSrc}`;
  return imageUrl;
};

const getEventNameFromResidentAdvisorPage = (
  pageDomNavigator: CheerioStatic
): string => {
  const eventHeader = pageDomNavigator("div#sectionHead");
  const eventNameElement = eventHeader.find("h1");
  const eventName = eventNameElement.text();

  return eventName.trim();
};

// Inspired by https://www.regextester.com/94091
const dayOfWeekRegex = /((Mon|Tues|Wednes|Thurs|Fri|Satur|Sun)(day))/;

interface StartEndDateTimeReturnValue {
  startDateTime: string;
  lastTimeStamp: string;
}

const getStartAndEndDateTimeFromResidentAdvisorPage = (
  pageFullUrl: string,
  pageDomNavigator: CheerioStatic
): StartEndDateTimeReturnValue => {
  const dateBlock = pageDomNavigator("aside#detail > ul > li").first();

  // This dateblock will output the start/end timestamp in either of the following two formats:
  // "Date /Friday15 Nov 201923:59 - 10:00"
  // "Date /Fri, 15 Nov 2019  - Sat, 16 Nov 201922:00 - 22:00"
  const dateBlockTextContent = dateBlock.text();

  // Remove prefix of "Date /"
  const pureDateText = dateBlockTextContent.replace("Date /", "");

  // In single day notation the day of week is spelled out in full (Friday), in two day it's spelled out shortened (Fri)
  const isSingleDayNotation = dayOfWeekRegex.test(pureDateText);

  if (isSingleDayNotation) {
    return getStartEndDateFromSingleDayNotation(pageFullUrl, pureDateText);
  } else {
    return getStartEndDateFromTwoDayNotation(pageFullUrl, pureDateText);
  }
};

const singleDayNotationRegex = /(?<dayOfMonth>[0-9][0-9]?) (?<month>...) (?<year>[0-9]{4})(?<startHour>..):(?<startMinute>..) - (?<lastHour>..):(?<lastMinute>..)/;

/**
 * Parses date text in shape "Friday15 Nov 201923:59 - 10:00" to two datestamp strings
 * @param dateText The date text to parse
 */
const getStartEndDateFromSingleDayNotation = (
  pageFullUrl: string,
  dateText: string
): StartEndDateTimeReturnValue => {
  const match = singleDayNotationRegex.exec(dateText);

  if (!match) {
    throw new Error(
      `Failed to retrieve start and end date from single day notation of "${dateText}". ${createEventUrlReferenceErrorMessage(
        pageFullUrl
      )}`
    );
  }

  const { groups: matchGroups } = match;

  if (!matchGroups) {
    throw new Error(
      `Match occured but no groups have been found in the single day notation regex. ${createEventUrlReferenceErrorMessage(
        pageFullUrl
      )}`
    );
  }

  const startMoment = getMomentFromParsedData({
    dayOfMonth: matchGroups["dayOfMonth"],
    month: matchGroups["month"],
    year: matchGroups["year"],
    hours: matchGroups["startHour"],
    minutes: matchGroups["startMinute"],
    pageFullUrl
  });
  const lastMomentDayBefore = getMomentFromParsedData({
    dayOfMonth: matchGroups["dayOfMonth"],
    month: matchGroups["month"],
    year: matchGroups["year"],
    hours: matchGroups["lastHour"],
    minutes: matchGroups["lastMinute"],
    pageFullUrl
  });
  const lastMoment = lastMomentDayBefore.add(1, "days");

  return {
    startDateTime: startMoment.format(),
    lastTimeStamp: lastMoment.format()
  };
};

const twoDayNotationRegex = /(?<startDayOfMonth>[0-9][0-9]?) (?<startMonth>...) (?<startYear>[0-9]{4}) *- ..., (?<endDayOfMonth>[0-9][0-9]?) (?<endMonth>...) (?<endYear>[0-9]{4})(?<startHour>..):(?<startMinute>..) - (?<lastHour>..):(?<lastMinute>..)/;

/**
 * Parses date text in shape "Fri, 15 Nov 2019  - Sat, 16 Nov 201922:00 - 22:00" to two datestamp strings
 * @param dateText The date text to parse
 */
const getStartEndDateFromTwoDayNotation = (
  pageFullUrl: string,
  dateText: string
): StartEndDateTimeReturnValue => {
  const match = twoDayNotationRegex.exec(dateText);

  if (!match) {
    throw new Error(
      `Failed to retrieve start and end date from double day notation of "${dateText}".  ${createEventUrlReferenceErrorMessage(
        pageFullUrl
      )}`
    );
  }

  const { groups: matchGroups } = match;

  if (!matchGroups) {
    throw new Error(
      `Match occured but no groups have been found in the double day notation regex.  ${createEventUrlReferenceErrorMessage(
        pageFullUrl
      )}`
    );
  }

  const startMoment = getMomentFromParsedData({
    dayOfMonth: matchGroups["startDayOfMonth"],
    month: matchGroups["startMonth"],
    year: matchGroups["startYear"],
    hours: matchGroups["startHour"],
    minutes: matchGroups["startMinute"],
    pageFullUrl
  });
  const lastMoment = getMomentFromParsedData({
    dayOfMonth: matchGroups["endDayOfMonth"],
    month: matchGroups["endMonth"],
    year: matchGroups["endYear"],
    hours: matchGroups["lastHour"],
    minutes: matchGroups["lastMinute"],
    pageFullUrl
  });

  return {
    startDateTime: startMoment.format(),
    lastTimeStamp: lastMoment.format()
  };
};

const getMomentFromParsedData = ({
  dayOfMonth,
  month,
  year,
  hours,
  minutes,
  pageFullUrl
}: {
  dayOfMonth: string;
  month: string;
  year: string;
  hours: string;
  minutes: string;
  pageFullUrl: string;
}): Moment => {
  const momentString = `${month}-${dayOfMonth}-${year} ${hours}:${minutes}`;
  const theMoment = moment(
    `${dayOfMonth}-${month}-${year} ${hours}:${minutes}`,
    "DD-MMMM-YYYY HH:mm"
  );

  if (!theMoment.isValid()) {
    throw new Error(
      `The string moment "${momentString}" hasn't been parsed to a valid moment. ${createEventUrlReferenceErrorMessage(
        pageFullUrl
      )}`
    );
  }

  return theMoment;
};

const getSetsFromResidentAdvisorPage = (
  pageFullUrl: string,
  pageDomNavigator: CheerioStatic,
  allArtists: SerializedArtist[],
  eventId: string,
  clubId: string
): {
  sets: SerializedSetInformation[];
  newArtists: SerializedArtist[];
} => {
  const lineupElement = pageDomNavigator("p.lineup");
  const linkableArtists = lineupElement.find("a");

  const newArtists: SerializedArtist[] = [];
  const eventSets: SerializedSetInformation[] = [];
  const makeNewSetForArtistId = (artistId: string) =>
    eventSets.push({
      artistIds: [artistId],
      clubId,
      eventId,
      id: uuid(),
      isLive: false
    });

  linkableArtists.each((index, element) => {
    // a -> textnode -> data (the text itself)
    const artistName = element.firstChild.data;

    if (!artistName) {
      console.dir(element);
      throw new Error(
        `Failed to get artist name from element. ${createEventUrlReferenceErrorMessage(
          pageFullUrl
        )}`
      );
    }

    const trimmedArtistName = artistName.trim();

    const existingArtist = allArtists.find(
      artist => artist.name === trimmedArtistName
    );

    if (!existingArtist) {
      // TODO: Scrape artist as well, to get for example the image
      const newArtist: SerializedArtist = {
        id: uuid(),
        name: trimmedArtistName,
        representableMixSoundcloudTrackId: null
      };
      makeNewSetForArtistId(newArtist.id);
      newArtists.push(newArtist);
    } else {
      makeNewSetForArtistId(existingArtist.id);
    }
  });

  return {
    newArtists,
    sets: eventSets
  };
};

export default scrapeResidentAdvisorEvent;
