import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import App from "./components/App/App";
import initializeApp from "./initializeApp";
import getElementByIdOrThrow from "./utils/getElementByIdOrThrow";

initializeApp();

const appRootElementId = "root";
const appRootElement = getElementByIdOrThrow(appRootElementId);

ReactDOM.render(<App />, appRootElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
