import soundcloud from "soundcloud";
import { SoundcloudPlayer, SoundcloudPlayerState } from "../data_models/SoundcloudPlayer";

const playSoundcloudTrack = async (
  soundcloudTrackId: number,
  startTimestamp: number,
  onTimeUpdate: (some: any) => void = () => {},
  onError: () => void,
  setIsPlayerPausedBecauseBuffering: (
    isPlayerPausedBecauseBuffering: boolean
  ) => void
): Promise<SoundcloudPlayer> => {
  const player: SoundcloudPlayer = await soundcloud.stream(
    "/tracks/" + soundcloudTrackId
  );

  player.seek(startTimestamp);
  player.on("time", onTimeUpdate);
  player.on("play-start", () => setIsPlayerPausedBecauseBuffering(false));
  player.on("state-change", (newState) => {
    if(newState === SoundcloudPlayerState.ERROR){
      onError();
    }
  })
  player.play();
  return player;
};

export default playSoundcloudTrack;
