import {
  TOGGLE_PAUSED_STATE,
  QUEUE_PREVIEWS,
  NEXT_PREVIEW,
  EMPTY_QUEUE_AND_CURRENT_PREVIEW,
  UPDATE_PLAYER_TIME,
  ENABLE_AUTO_PLAY,
  DISABLE_AUTO_PLAY,
  SET_BUFFERING,
  SET_QUEUE_LOADING,
  SET_DID_PLAYER_ERROR_OCCUR
} from "./player_actions";
import { ReduxAction } from "../reducers";
import getRandomWithinRange from "../../utils/getRandomWithinRange";
import { cantPerformActionNoActivePlayerState } from "../../utils/error_builders/share_error_builders";

const TRACK_PLAY_TIME_BEFORE_CONTINUE_IN_SECONDS = 10;

export interface PlayableSetInformation {
  artistIds: string[];
  setFloorId?: string;
  setStartTime?: string;
  eventId: string;
  clubId: string;
  setId: string;

  soundcloudTrackId: number;
  soundcloudTrackDuration: number;
  soundcloudPermalinkUrl: string;
  soundcloudUploader: string;
}

export type ActiveTrackPlayerState = {
  currentSetPreviewInformation: PlayableSetInformation;

  /** At what timestamp did we start playing? */
  currentTrackStartedTimestamp: number;

  /** At what timestamp should the player go on to the next song if autoplaying? */
  currentTrackContinueTimestamp: number;

  currentTimeStamp: number;

  isPlayerPausedBecauseBuffering: boolean;

  isPaused: boolean;
} | null;

export interface ReduxPlayerState {
  activeTrackPlayerState: ActiveTrackPlayerState;

  didPlayerErrorOccur: boolean;
  trackQueue: PlayableSetInformation[];
  isQueueLoading: boolean;
  isAutoPlaying: boolean;
}

const initialState: ReduxPlayerState = {
  activeTrackPlayerState: null,
  trackQueue: [],
  isQueueLoading: false,
  isAutoPlaying: true,
  didPlayerErrorOccur: false
};

const playerReducer = (
  state: ReduxPlayerState = initialState,
  action: ReduxAction
): ReduxPlayerState => {
  switch (action.type) {
    case TOGGLE_PAUSED_STATE:
      if (!state.activeTrackPlayerState) {
        throw cantPerformActionNoActivePlayerState();
      }

      return {
        ...state,
        activeTrackPlayerState: {
          ...state.activeTrackPlayerState,
          isPaused: !state.activeTrackPlayerState.isPaused
        }
      };
    case QUEUE_PREVIEWS:
      return {
        ...state,
        trackQueue: [...state.trackQueue, ...action.previews]
      };
    case NEXT_PREVIEW: {
      const [nextSetPreviewInformation, ...queueRemainder] = state.trackQueue;

      if (nextSetPreviewInformation === undefined) {
        // There is no next track, so just empty active player state
        return {
          ...state,
          activeTrackPlayerState: null
        };
      }

      const startTimeStamp =
        getRandomWithinRange(0.1, 0.9) *
        nextSetPreviewInformation.soundcloudTrackDuration;

      return {
        ...state,
        activeTrackPlayerState: {
          currentSetPreviewInformation: nextSetPreviewInformation,
          currentTrackStartedTimestamp: startTimeStamp,
          currentTrackContinueTimestamp:
            startTimeStamp + TRACK_PLAY_TIME_BEFORE_CONTINUE_IN_SECONDS * 1000,
          currentTimeStamp: startTimeStamp,
          isPlayerPausedBecauseBuffering: true,
          isPaused: false
        },
        // We reset the error state when trying to play new song
        didPlayerErrorOccur: false,
        trackQueue: queueRemainder
      };
    }
    case EMPTY_QUEUE_AND_CURRENT_PREVIEW:
      return {
        ...state,
        activeTrackPlayerState: null,
        // When emptying queue and preview we also empty any reported errors
        didPlayerErrorOccur: false,
        trackQueue: []
      };
    case UPDATE_PLAYER_TIME:
      if (state.activeTrackPlayerState === null) {
        return state;
      }

      return {
        ...state,
        activeTrackPlayerState: {
          ...state.activeTrackPlayerState,
          currentTimeStamp: action.time
        }
      };
    case ENABLE_AUTO_PLAY:
      return {
        ...state,
        // When enabling auto play we from then on want to set the 10 seconds countdown
        activeTrackPlayerState: state.activeTrackPlayerState
          ? {
              ...state.activeTrackPlayerState,
              currentTrackContinueTimestamp:
                state.activeTrackPlayerState.currentTimeStamp +
                TRACK_PLAY_TIME_BEFORE_CONTINUE_IN_SECONDS * 1000
            }
          : null,
        isAutoPlaying: true
      };
    case DISABLE_AUTO_PLAY:
      return {
        ...state,
        isAutoPlaying: false
      };
    case SET_BUFFERING:
      if (!state.activeTrackPlayerState) {
        throw cantPerformActionNoActivePlayerState();
      }

      return {
        ...state,
        activeTrackPlayerState: {
          ...state.activeTrackPlayerState,
          isPlayerPausedBecauseBuffering: action.isPlayerPausedBecauseBuffering
        }
      };
    case SET_QUEUE_LOADING:
      return {
        ...state,
        isQueueLoading: action.isQueueLoading
      };
    case SET_DID_PLAYER_ERROR_OCCUR:
      return {
        ...state,
        didPlayerErrorOccur: action.didPlayerErrorOccur,
        isQueueLoading: false,
        activeTrackPlayerState: state.activeTrackPlayerState ? {
          ...state.activeTrackPlayerState,
          isPaused: true,
          isPlayerPausedBecauseBuffering: false
        }: null
      }
  }

  return state;
};

export default playerReducer;
