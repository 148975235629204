import React, { ReactNode } from "react";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { IconKey } from "../icons/Icon";
import {
  PlayerStyler,
  PlayerControlsStyler,
  PlayerTextStyler,
  PlayerTopRowTextStyler,
  PlayerSubTextStyler,
  PlayerControlsButtonIcon,
  PlayerPlayPauseButton,
  PlayerMoreInformationStyler,
  PlayerFocusedEventImage,
  PlayerMoreInformationButtonCopy,
  PlayerMoreInformationButtonExpandIcon,
  PlayerMoreInformationButton
} from "./Player.styles";

interface IProps {
  isPlayerPausedBecauseBuffering: boolean;
  isQueueLoading: boolean;
  isPaused: boolean;
  isDisabled: boolean;
  hasNextTrackToPlay: boolean;
  hasLoadedTrack: boolean;

  onPlayPauseClick: () => void;
  onNextClick: () => void;
  onStopClick: () => void;

  playerMainTextComponent: ReactNode;
  playerAttributionComponent: ReactNode;
  playerSubTextComponent: ReactNode;

  moreInformationButtonProps?: {
    /** When this flag is set to true the expand icon will be replaced by collapse icon */
    isMoreInformationShowing: boolean;
    focusedEventImageUrl: string;
    onMoreInformationClick: () => void;
  };
}

export default function Player({
  isPlayerPausedBecauseBuffering,
  isQueueLoading,
  isPaused,
  isDisabled,
  hasNextTrackToPlay,
  hasLoadedTrack,

  onPlayPauseClick,
  onNextClick,
  onStopClick,

  playerMainTextComponent,
  playerAttributionComponent,
  playerSubTextComponent,

  moreInformationButtonProps
}: IProps) {
  const isVisuallyDisabled = isDisabled || !hasLoadedTrack;

  const playPauseOrLoadingComponent =
    (isPlayerPausedBecauseBuffering || isQueueLoading) && !isPaused ? (
      <LoadingSpinner size={60} onClick={onPlayPauseClick} />
    ) : (
      <PlayerPlayPauseButton
        isPaused={isPaused}
        isDisabled={isVisuallyDisabled}
        onClick={onPlayPauseClick}
        iconTheme="plain"
        tooltip={isVisuallyDisabled ? isPaused ? "Resume preview" : "Pause preview" : undefined}
      />
    );

  return (
    <PlayerStyler hasLoadedTrack={hasLoadedTrack}>
      <PlayerControlsStyler>
        {playPauseOrLoadingComponent}
        <PlayerControlsButtonIcon
          iconKey={IconKey.SkipNext}
          isDisabled={isVisuallyDisabled || !hasNextTrackToPlay}
          onClick={onNextClick}
          tooltip={
            isVisuallyDisabled
              ? undefined
              : !hasNextTrackToPlay
              ? "No next preview available"
              : "Next preview"
          }
        />
        <PlayerControlsButtonIcon
          iconKey={IconKey.Stop}
          isDisabled={isVisuallyDisabled}
          onClick={onStopClick}
          tooltip={!isVisuallyDisabled ? "Stop playing previews" : undefined}
        />
      </PlayerControlsStyler>

      <PlayerTextStyler>
        <PlayerTopRowTextStyler>
          {playerMainTextComponent}
        </PlayerTopRowTextStyler>

        <PlayerSubTextStyler>{playerSubTextComponent}</PlayerSubTextStyler>
      </PlayerTextStyler>

      {playerAttributionComponent}

      {moreInformationButtonProps && (
        <PlayerMoreInformationStyler
          onClick={moreInformationButtonProps.onMoreInformationClick}
        >
          <PlayerFocusedEventImage
            src={moreInformationButtonProps.focusedEventImageUrl}
            alt="Event Flyer"
          />
          <PlayerMoreInformationButton>
            <PlayerMoreInformationButtonCopy>
              Event & Venue Info
            </PlayerMoreInformationButtonCopy>
            <PlayerMoreInformationButtonExpandIcon
              iconKey={
                moreInformationButtonProps.isMoreInformationShowing
                  ? IconKey.CollapseIcon
                  : IconKey.ExpandIcon
              }
            />
          </PlayerMoreInformationButton>
        </PlayerMoreInformationStyler>
      )}
    </PlayerStyler>
  );
}
