import Imagable, { isImagable } from "../Imagable";
import IDable, { isIDable } from "../IDable";
import Permalinkable, { isPermalinkable } from "../Permalinkable";

export default interface SerializedCity
  extends Imagable,
    IDable,
    Permalinkable {
  nameKey: string;
  countryKey: string;
  clubIds: string[];
}

export const isSerializedCity = (target: unknown): target is SerializedCity => {
  const { nameKey, countryKey, clubIds } = target as SerializedCity;

  return (
    isImagable(target) &&
    isIDable(target) &&
    isPermalinkable(target) &&
    nameKey !== undefined &&
    countryKey !== undefined &&
    clubIds !== undefined
  );
};
