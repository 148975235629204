import styled, { css } from "styled-components";
import generateBootstrap4MediaQueries from "../style_mixins/bootstrap4MediaQueries";

export const AppContentStyler = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: grid;
  grid-template-rows: [pre-header] auto [post-header] 1fr [pre-footer] auto [post-footer];
  grid-template-columns: [pre-single-column] 1fr [post-single-column];
`;

export const AppHeaderStyler = styled.div`
  grid-column: pre-single-column / post-single-column;
  grid-row: pre-header / post-header;
`;

const baseAppPageStylerCss = css`
  grid-column: pre-single-column / post-single-column;
  grid-row: post-header / pre-footer;
  overflow-y: scroll;
  position: relative;
`;

// Mobile Portrait no padding on the sides
const mobilePortraitAppPageStylerCss = css`
  ${baseAppPageStylerCss}
  padding: 24px 0;
`;

const defaultPortraitAppPageStylerCss = css`
  ${baseAppPageStylerCss}
  padding: 40px;
`;

export const AppPageStyler = styled.div`
  ${generateBootstrap4MediaQueries({
    extraSmallCss: mobilePortraitAppPageStylerCss,
    mediumCss: defaultPortraitAppPageStylerCss
  })}
`;

export const AppFooterStyler = styled.div`
  grid-column: pre-single-column / post-single-column;
  grid-row: pre-footer / post-footer;
`;
