import React from "react";
import EventInformationModal from "./EventInformationModal";
import useEventInformationModalProps from "./useEventInformationModalProps";
import {
  isHookErrorResponse,
  isHookLoading
} from "../../../api/HookReturnValue";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import { EventInformationModalState } from "../../../redux/modal_state/modalReducer";
import { useOpenErrorModal } from "../../../redux/modal_state/modal_actions";

export default function EventInformationModalContainer({
  eventId
}: EventInformationModalState) {
  const useEventInformationModalPropsResult = useEventInformationModalProps(
    eventId
  );
  const openErrorModal = useOpenErrorModal();

  if (isHookErrorResponse(useEventInformationModalPropsResult)) {
    openErrorModal(useEventInformationModalPropsResult);
    return null;
  }

  if (isHookLoading(useEventInformationModalPropsResult)) {
    return <LoadingSpinner />;
  }

  return <EventInformationModal {...useEventInformationModalPropsResult} />;
}
