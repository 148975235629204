import React from "react";
import ClassNamePropType from "../../data_models/react_prop_types/ClassNamePropType";
import SvgWithDefaultStyler from "./SvgWithDefaultStyle";

export default function PlayCircle({ className }: ClassNamePropType) {
  return (
    <SvgWithDefaultStyler
      className={className}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.199951 12.2C0.199951 5.57601 5.57595 0.200012 12.2 0.200012C18.824 0.200012 24.2 5.57601 24.2 12.2C24.2 18.824 18.824 24.2 12.2 24.2C5.57595 24.2 0.199951 18.824 0.199951 12.2ZM16.9999 12.2L9.79986 6.80001V17.6L16.9999 12.2Z"
      />
    </SvgWithDefaultStyler>
  );
}
