import soundcloud from "soundcloud";
import artists from "../static_db/artists";
import findByIdOrThrow from "../utils/findByIdOrThrow";
import { PlayableSetInformation } from "../redux/player_state/playerReducer";
import SerializedSetInformation from "../data_models/SetInformation/SerializedSetInformation";
import SerializedArtist from "../data_models/Artist/SerializedArtist";

const getSetPreviewInformation = async (
  sets: SerializedSetInformation[]
): Promise<PlayableSetInformation[]> => {
  const setsWithArtists = sets.map(serializedSet => ({
    serializedSet,
    artists: serializedSet.artistIds.map(artistId =>
      findByIdOrThrow(artists, artistId)
    )
  }));

  const playableSetsWithArtistAndSoundcloudTrackId = setsWithArtists
    .map(({ serializedSet, artists }) => ({
      serializedSet,
      artists,
      // We currently dumbly just get the soundcloud track id of the first artist with a track id set
      soundcloudTrackId: artists
        .map(
          ({ representableMixSoundcloudTrackId }) =>
            representableMixSoundcloudTrackId
        )
        .find(trackId => trackId !== null)
    }))
    .filter(
      (
        serializedSetWithSoundcloudTrackId
      ): serializedSetWithSoundcloudTrackId is {
        serializedSet: SerializedSetInformation;
        artists: SerializedArtist[];
        soundcloudTrackId: number;
      } =>
        serializedSetWithSoundcloudTrackId.soundcloudTrackId !== undefined ||
        serializedSetWithSoundcloudTrackId.soundcloudTrackId !== null
    );

  return Promise.all(
    playableSetsWithArtistAndSoundcloudTrackId.map(
      async ({ artists, serializedSet, soundcloudTrackId }) => {
        const trackInformation = await soundcloud.get(
          "/tracks/" + soundcloudTrackId
        );

        return {
          artistIds: artists.map(({ id }) => id),
          setFloorId: serializedSet.floorId,
          setStartTime: serializedSet.startTime,
          eventId: serializedSet.eventId,
          clubId: serializedSet.clubId,
          setId: serializedSet.id,

          soundcloudTrackId: soundcloudTrackId,
          soundcloudTrackDuration: trackInformation.duration,
          soundcloudPermalinkUrl: trackInformation.permalink_url,
          soundcloudUploader: trackInformation.user.username
        };
      }
    )
  );
};

export default getSetPreviewInformation;
