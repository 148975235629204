import styled, { css } from "styled-components";
import { defaultCopyFontFamily } from "../../styles/font_styles";
import IconButton from "../buttons/IconButton";
import PlayPauseButton from "../buttons/PlayPauseButton";
import UnstyledButton from "../buttons/UnstyledButton";
import { lightGrey } from "../../styles/colors";
import Icon from "../icons/Icon";
import generateBootstrap4MediaQueries from "../style_mixins/bootstrap4MediaQueries";
import ellipsisTextCss from "../style_mixins/ellipsisTextCss";

export const PlayerTopRowTextStyler = styled.div`
  display: flex;
  justify-content: space-between;
`;

const baseMainTextStylerCss = css`
  font-family: ${defaultCopyFontFamily};
  ${ellipsisTextCss};
`;

const defaultMainTextStylerCss = css`
  ${baseMainTextStylerCss}
  font-size: 28px;
`;

const mobileMainTextStylerCss = css`
  ${baseMainTextStylerCss}
  font-size: 24px;
`;

export const PlayerMainTextStyler = styled.div`
  ${generateBootstrap4MediaQueries({
    extraSmallCss: mobileMainTextStylerCss,
    mediumCss: defaultMainTextStylerCss
  })}
`;

const basePlayerAttributionTextStylerCss = css`
grid-area: credits;
  font-family: ${defaultCopyFontFamily};
`;

const defaultPlayerAttributionTextStylerCss = css`
${basePlayerAttributionTextStylerCss}
  margin: 20px 16px 20px 0;
  font-size: 12px;
  text-align: right;
`;

const mobilePlayerAttributionTextStylerCss = css`
${basePlayerAttributionTextStylerCss}
  margin: 8px;
  font-size: 9px;
  text-align: initial;
`;

export const PlayerAttributionTextStyler = styled.div`
  ${generateBootstrap4MediaQueries({
    extraSmallCss: mobilePlayerAttributionTextStylerCss,
    mediumCss: defaultPlayerAttributionTextStylerCss
  })}
`;

export const PlayerSubTextStyler = styled.div`
  font-size: 16px;
  font-family: ${defaultCopyFontFamily};
`;

const basePlayerStylerCss = css`
  border-top: 1px black solid;
  background-color: white;
  display: grid;
  grid-template-columns: 0fr 1fr 1fr 0fr;
`;

const defaultPlayerStylerCss = css`
  ${basePlayerStylerCss}
  grid-template-areas: "controls set-info credits more-info-cta";
  grid-template-rows: 104px;
`;

interface PlayerStylerProps {
  hasLoadedTrack: boolean;
}

const mobilePortraitPlayerStylerCss = css<PlayerStylerProps>`
  ${basePlayerStylerCss}
  grid-template-areas:
    "controls set-info set-info more-info-cta"
    "credits credits credits credits";
  grid-template-rows: 40px ${({ hasLoadedTrack }) =>
      hasLoadedTrack ? 28 : 0}px;

  transition: grid-template-rows 1s ease-in-out;
`;

export const PlayerStyler = styled.div<PlayerStylerProps>`
  ${generateBootstrap4MediaQueries<PlayerStylerProps>({
    extraSmallCss: mobilePortraitPlayerStylerCss,
    mediumCss: defaultPlayerStylerCss
  })}
`;

const basePlayerConstrolsStyleCss = css`
  grid-area: controls;
  display: grid;
  grid-template-columns: repeat(3, auto);
`;

const mobilePortraitControlsStylerCss = css`
  ${basePlayerConstrolsStyleCss};
  grid-gap: 8px;
  margin: 8px;
`;

const defaultControlsStylerCss = css`
  ${basePlayerConstrolsStyleCss};
  grid-gap: 0px;
  margin: 20px 20px 20px 40px;
`;

export const PlayerControlsStyler = styled.div`
  ${generateBootstrap4MediaQueries({
    extraSmallCss: mobilePortraitControlsStylerCss,
    mediumCss: defaultControlsStylerCss
  })}
`;

const basePlayerTextStylerCss = css`
  grid-area: set-info;
`;

const defaultPlayerTextStylerCss = css`
  ${basePlayerTextStylerCss}

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  margin: 20px 16px 20px 0px;
`;

const mobilePlayerTextStylerCss = css`
  ${basePlayerTextStylerCss}

  margin: 8px 0px;
`;

export const PlayerTextStyler = styled.div`
  ${generateBootstrap4MediaQueries({
    extraSmallCss: mobilePlayerTextStylerCss,
    mediumCss: defaultPlayerTextStylerCss
  })}
`;

export const PlayerMoreInformationStyler = styled.div`
  grid-area: more-info-cta;
  display: flex;
  flex-direction: row;

  cursor: pointer;

  :hover {
    background-color: ${lightGrey};
  }
`;

const basePlayerFocusedEventImageCss = css`
  object-fit: cover;
  height: 100%;
`;

const mobilePortraitPlayerFocusedEventImageCss = css`
  ${basePlayerFocusedEventImageCss}
  width: 48px;
`;

const defaultPlayerFocusedEventImageCss = css`
  ${basePlayerFocusedEventImageCss}
  width: 208px;
`;

export const PlayerFocusedEventImage = styled.img`
  ${generateBootstrap4MediaQueries({
    extraSmallCss: mobilePortraitPlayerFocusedEventImageCss,
    mediumCss: defaultPlayerFocusedEventImageCss
  })}
`;

const basePlayerMoreInformationButtonCss = css`
  /** Prefer to use grid here but can't have grid in button: https://github.com/rachelandrew/gridbugs#10-some-html-elements-cant-be-grid-containers */
  height: 100%;
  display: flex;
  flex-direction: row;
`;

const mobilePortraitPlayerMoreInformationButtonCss = css`
  ${basePlayerMoreInformationButtonCss}
  padding: 8px;
`;

const defaultPlayerMoreInformationButtonCss = css`
  ${basePlayerMoreInformationButtonCss}
  width: 208px;
  padding: 32px 32px 32px 16px;
`;

export const PlayerMoreInformationButton = styled(UnstyledButton)`
  ${generateBootstrap4MediaQueries({
    extraSmallCss: mobilePortraitPlayerMoreInformationButtonCss,
    mediumCss: defaultPlayerMoreInformationButtonCss
  })}
`;

const basePlayerMoreInformationButtonCopyCss = css`
  font-family: ${defaultCopyFontFamily};
  font-size: 18px;
  text-transform: uppercase;
  text-align: left;
`;

const mobilePortraitPlayerMoreInformationButtonCopyCss = css`
  ${basePlayerMoreInformationButtonCopyCss}
  width: 0px;
  height: 0px;
  margin-right: 0px;
  overflow: hidden;
`;

const defaultPlayerMoreInformationButtonCopyCss = css`
  ${basePlayerMoreInformationButtonCopyCss}
  height: 40px;
  width: 104px;
  margin-right: 16px;
  overflow: auto;
`;

export const PlayerMoreInformationButtonCopy = styled.div`
  ${generateBootstrap4MediaQueries({
    extraSmallCss: mobilePortraitPlayerMoreInformationButtonCopyCss,
    mediumCss: defaultPlayerMoreInformationButtonCopyCss
  })}
`;

const twentyFourPxDimensionsCss = css`
  && {
    width: 24px;
    height: 24px;
  }
`;

const fourtyPxDimensionsCss = css`
  && {
    width: 40px;
    height: 40px;
  }
`;

const sixtyPxDimensionsCss = css`
  && {
    width: 60px;
    height: 60px;
  }
`;

const controlButtonsCss = css`
  ${generateBootstrap4MediaQueries({
    extraSmallCss: twentyFourPxDimensionsCss,
    mediumCss: sixtyPxDimensionsCss
  })}
`;

export const PlayerMoreInformationButtonExpandIcon = styled(Icon)`
  ${generateBootstrap4MediaQueries({
    extraSmallCss: twentyFourPxDimensionsCss,
    mediumCss: fourtyPxDimensionsCss
  })}
`;

export const PlayerControlsButtonIcon = styled(IconButton)`
  ${controlButtonsCss}
`;

export const PlayerPlayPauseButton = styled(PlayPauseButton)`
  ${controlButtonsCss}
`;
