import SerializedSetInformation from "./SerializedSetInformation";
import SerializedArtist from "../Artist/SerializedArtist";
import findByIdOrThrow from "../../utils/findByIdOrThrow";

export const isSetPlayable = (
  set: SerializedSetInformation,
  artists: SerializedArtist[]
): boolean => {
  const setArtists = set.artistIds.map(artistId =>
    findByIdOrThrow(artists, artistId)
  );

  const isThereAtLeastOneArtistPreviewable = setArtists.some(
    artist => artist.representableMixSoundcloudTrackId !== null
  );

  return isThereAtLeastOneArtistPreviewable;
};
