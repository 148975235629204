import React from "react";
import ClassNamePropType from "../../data_models/react_prop_types/ClassNamePropType";
import SvgWithDefaultStyler from "./SvgWithDefaultStyle";

export default function DanceFloorIcon({ className }: ClassNamePropType) {
  return (
    <SvgWithDefaultStyler
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      x="0px"
      y="0px"
      className={className}
    >
      <g data-name="Layer 3">
        <path d="M49.91,33.59A1,1,0,0,0,49,33H15a1,1,0,0,0-.91.59l-12,27A1,1,0,0,0,3,62H61a1,1,0,0,0,.91-1.41ZM24.24,49l1.48-8H38l1.19,8Zm15.27,2,1.33,9H22.2l1.67-9ZM13,41h10.7l-1.48,8H9.43Zm13.11-2,.74-4H37.14l.59,4Zm14,2H51l3.56,8H41.23Zm10.08-2H39.75l-.59-4h9.19ZM15.65,35H24.8l-.74,4H13.87ZM8.54,51h13.3l-1.67,9H4.54Zm34.32,9-1.33-9H55.46l4,9Z" />
        <path d="M32,2a1,1,0,0,0-1,1V6.06a9,9,0,1,0,2,0V3A1,1,0,0,0,32,2Zm-1,8H29V9a1,1,0,0,0-.06-.29A6.93,6.93,0,0,1,31,8.08Zm7,2a1,1,0,0,0,.29-.06A6.93,6.93,0,0,1,38.92,14H37V12Zm.29,6.06A1,1,0,0,0,38,18H37V16h1.92A6.93,6.93,0,0,1,38.29,18.06ZM25.08,16H27v2H26a1,1,0,0,0-.29.06A6.93,6.93,0,0,1,25.08,16Zm.63-4.06A1,1,0,0,0,26,12h1v2H25.08A6.93,6.93,0,0,1,25.71,11.94ZM31,18H29V16h2Zm2-2h2v2H33Zm-4,5V20h2v1.92a6.93,6.93,0,0,1-2.06-.63A1,1,0,0,0,29,21Zm4-1h2v1a1,1,0,0,0,.06.29,6.93,6.93,0,0,1-2.06.63Zm2-6H33V12h2Zm-4,0H29V12h2Zm4.06-5.29A1,1,0,0,0,35,9v1H33V8.08A6.93,6.93,0,0,1,35.06,8.71Z" />
        <path d="M11,10a1,1,0,0,0-1,1v1H9a1,1,0,0,0,0,2h1v1a1,1,0,0,0,2,0V14h1a1,1,0,0,0,0-2H12V11A1,1,0,0,0,11,10Z" />
        <path d="M53,19a1,1,0,0,0-1,1v1H51a1,1,0,0,0,0,2h1v1a1,1,0,0,0,2,0V23h1a1,1,0,0,0,0-2H54V20A1,1,0,0,0,53,19Z" />
        <path d="M45,2a1,1,0,0,0-1,1V4H43a1,1,0,0,0,0,2h1V7a1,1,0,0,0,2,0V6h1a1,1,0,0,0,0-2H46V3A1,1,0,0,0,45,2Z" />
        <path d="M19,25a1,1,0,0,0-1,1v1H17a1,1,0,0,0,0,2h1v1a1,1,0,0,0,2,0V29h1a1,1,0,0,0,0-2H20V26A1,1,0,0,0,19,25Z" />
      </g>
    </SvgWithDefaultStyler>
  );
}
