import {
  berghain,
  griessmuehle,
  kitkat,
  katerBlau,
  sisyphos,
  aboutBlank,
  wildeRenate
} from "./clubs";
import SerializedCity from "../data_models/City/SerializedCity";

export const berlin: SerializedCity = {
  permalinks: ["berlin"],
  activePermalink: "berlin",
  nameKey: "berlin",
  countryKey: "de",
  image: {
    url:
      "https://wallpaperaccess.com/full/775607.jpg",
    creditee: "Wallpaper Access"
  },
  clubIds: [
    berghain.id,
    griessmuehle.id,
    kitkat.id,
    katerBlau.id,
    sisyphos.id,
    aboutBlank.id,
    wildeRenate.id,
    "5776e283-a68f-42c0-963b-880248263479"
  ],
  id: "f3e9c15f-8121-4c64-9c0e-9b52ffaf2d21"
};

const cities: SerializedCity[] = [
  berlin,
  {
    permalinks: ["amsterdam"],
    activePermalink: "amsterdam",
    nameKey: "amsterdam",
    countryKey: "nl",
    image: {
      url: "https://wallpapercave.com/wp/wp1835444.jpg",
      creditee: "Wallpaper Cave"
    },
    clubIds: [
      "273c0042-b0f2-4bf0-ad84-5e06f173704b",
      "6a659b8c-a950-4d0a-b46a-fd1484c4654f",
      "98751239-bb46-4fcc-9c3f-79375909efa9",
      "f4c0bd28-64a9-40cf-b65e-bd79e6b98f5a",
      "dc555dab-8f88-494a-b225-5189e5a3b96c",
      "a40092f5-4204-4691-8c6a-1ed28f2a9b75"
    ],
    id: "60875322-13ba-40d6-9bfa-62e2a9217e31"
  }
];

export default cities;
