import { useState, useEffect } from "react";
import { ReduxState } from "../../redux/reducers";
import { SoundcloudPlayer } from "../../data_models/SoundcloudPlayer";
import playSoundcloudTrack from "../../audio_helpers/playSoundcloudTrack";
import { argumentOfTypeUndefined } from "../../utils/error_builders/share_error_builders";
import { useSelector } from "react-redux";
import {
  useUpdatePlayerTime,
  usePlayNextPreview,
  useSetIsPlayerBuffering,
  useTogglePausedState,
  useSetDidPlayerErrorOccur
} from "../../redux/player_state/player_actions";
import { ActiveTrackPlayerState } from "../../redux/player_state/playerReducer";

type SoundcloudPlayerOrNull = SoundcloudPlayer | null;

interface IState {
  currentSoundcloudTrackId: number | null;
  currentSoundcloudPlayer: SoundcloudPlayerOrNull;
}

/**
 * This component doesn't render anything actually, but manages the player's state. It
 * * Syncs up the soundcloud player (what actually streams audio and makes the user hear something) with the redux state (what for us is easy to control through the application)
 * * Plays next tracks when the preview time is up
 * * Listens for an app-wide space press to toggle play/pause state
 * ! WARNING: Never instantiate more than one of this component, the system will freak out
 */
export default function PlayerStateManager() {
  const { activeTrackPlayerState, isAutoPlaying } = useSelector(
    ({ player }: ReduxState) => player
  );

  const updateReduxPlayerTime = useUpdatePlayerTime();
  const playNextTrack = usePlayNextPreview();
  const togglePlayPause = useTogglePausedState();
  const setIsPlayerBuffering = useSetIsPlayerBuffering();
  const setDidPlayerErrorOccur = useSetDidPlayerErrorOccur();

  useEffect(() => {
    // Don't listen to space press when buffering or no active player
    if (
      activeTrackPlayerState === null ||
      activeTrackPlayerState.isPlayerPausedBecauseBuffering
    ) {
      return;
    }

    document.body.onkeyup = function(e) {
      if (e.keyCode === 32) {
        togglePlayPause();
      }
    };
    return () => {
      document.body.onkeyup = null;
    };
  }, [activeTrackPlayerState, togglePlayPause]);

  // We need to store soundcloud player locally to manipulate when the redux state has changed
  const [
    { currentSoundcloudPlayer, currentSoundcloudTrackId },
    setCurrentSoundcloudState
  ] = useState<IState>({
    currentSoundcloudTrackId: null,
    currentSoundcloudPlayer: null
  });

  matchPlayerStateToRedux(
    currentSoundcloudPlayer,
    currentSoundcloudTrackId,
    setCurrentSoundcloudState,
    activeTrackPlayerState,
    updateReduxPlayerTime,
    setDidPlayerErrorOccur,
    setIsPlayerBuffering
  );

  if (isAutoPlaying) {
    if (activeTrackPlayerState !== null) {
      const {
        currentTimeStamp,
        currentTrackContinueTimestamp: currentTrackNextTrackTimestamp
      } = activeTrackPlayerState;

      if (currentTimeStamp > currentTrackNextTrackTimestamp) {
        playNextTrack();
      }
    }
  }

  return null;
}

/**
 * This function makes sure that the state of the soundcloud player (what you are actually hearing) is matching the Redux player state (what we are controlling).
 */
const matchPlayerStateToRedux = async (
  // Handlers for internal component state
  currentSouncloudPlayer: SoundcloudPlayerOrNull,
  currentSoundcloudTrackId: number | null,
  setCurrentSoundcloudState: (newState: IState) => void,

  // Redux Play State
  activeTrackPlayerState: ActiveTrackPlayerState,
  updateReduxPlayerTime: (time: number) => void,
  updateDidPlayerErrorOccured: (newDidPlayerErrorOccur: boolean) => void,
  setIsPlayerBuffering: (isPlayerPausedBecauseBuffering: boolean) => void
) => {
  const markPlayerErrorOccured = () => updateDidPlayerErrorOccured(true);

  if (currentSouncloudPlayer === null) {
    if (activeTrackPlayerState !== null) {
      const {
        currentTimeStamp,
        currentSetPreviewInformation
      } = activeTrackPlayerState;

      const soundcloudPlayer = await playSoundcloudTrack(
        currentSetPreviewInformation.soundcloudTrackId,
        currentTimeStamp,
        updateReduxPlayerTime,
        markPlayerErrorOccured,
        setIsPlayerBuffering
      );
      setCurrentSoundcloudState({
        currentSoundcloudPlayer: soundcloudPlayer,
        currentSoundcloudTrackId: currentSetPreviewInformation.soundcloudTrackId
      });
    }
  } else {
    if (activeTrackPlayerState === null) {
      // The redux player has been emptied out so let's kill the current player
      currentSouncloudPlayer.kill();

      setCurrentSoundcloudState({
        currentSoundcloudPlayer: null,
        currentSoundcloudTrackId: null
      });
      return;
    }

    const {
      currentTimeStamp,
      currentSetPreviewInformation,
      isPaused
    } = activeTrackPlayerState;

    // Pause/Play state matching
    if (isPaused && currentSouncloudPlayer.isPlaying()) {
      // Pause when redux says pause but player still playing
      currentSouncloudPlayer.pause();
    } else if (!isPaused && !currentSouncloudPlayer.isPlaying()) {
      // Play when redux says playing but player is paused
      currentSouncloudPlayer.play();
    }

    // Song matching
    if (currentTimeStamp === null || currentSoundcloudTrackId === null) {
      // Handles cases one of the current track information is null
      const argNameOfNullArg =
        currentSoundcloudTrackId === null
          ? "currentSoundcloudTrackId"
          : "currentTimeStamp";

      throw argumentOfTypeUndefined(argNameOfNullArg);
    }

    if (
      currentSoundcloudTrackId !==
      currentSetPreviewInformation.soundcloudTrackId
    ) {
      // Uh ooh, Redux state has moved onto a new track. Let's switch to it!
      currentSouncloudPlayer.kill();

      setCurrentSoundcloudState({
        currentSoundcloudPlayer: null,
        currentSoundcloudTrackId: null
      });

      const newSoundcloudPlayer = await playSoundcloudTrack(
        currentSetPreviewInformation.soundcloudTrackId,
        currentTimeStamp,
        updateReduxPlayerTime,
        markPlayerErrorOccured,
        setIsPlayerBuffering
      );
      setCurrentSoundcloudState({
        currentSoundcloudPlayer: newSoundcloudPlayer,
        currentSoundcloudTrackId: currentSetPreviewInformation.soundcloudTrackId
      });
    }
  }
};
