import ReactGA from "react-ga";
import React from "react";
import Player from "./Player";
import PlayerAttributionText from "./PlayerAttributionText";
import { PlayerMainTextStyler } from "./Player.styles";
import { useReduxSelector } from "../../redux/redux_hooks";
import {
  useTogglePausedState,
  useSetAutoPlay,
  usePlayNextPreview,
  useEmptyQueueAndCurrentPreview
} from "../../redux/player_state/player_actions";
import useEvent from "../../api/useEvent";
import useClub from "../../api/useClub";
import {
  isHookErrorResponse,
  isHookRequestedResult,
  isHookLoading
} from "../../api/HookReturnValue";
import ErrorComponent from "../ErrorComponent/ErrorComponent";
import { neverTypescriptCase } from "../../utils/error_builders/share_error_builders";
import SerializedEvent from "../../data_models/Event/SerializedEvent";
import SerializedClub from "../../data_models/Club/SerializedClub";
import PlayerSubText from "./PlayerSubText";
import moment from "moment";
import GoogleAnalyticsCategory from "../../data_models/enums/GoogleAnalyticsCategory";
import findByIdOrThrow from "../../utils/findByIdOrThrow";
import useArtists from "../../api/useArtists";
import {
  useOpenEventInformationModal,
  useCloseModal
} from "../../redux/modal_state/modal_actions";
import { ModalStateType } from "../../redux/modal_state/modalReducer";
import { getImageWithCrediteeToShowForEvent } from "../../data_models/Event/event_functions";

export default function PlayerContainer() {
  // Hooks
  //    Extract from Redux
  const {
    activeTrackPlayerState,
    trackQueue,
    isQueueLoading,
    isAutoPlaying,
    didPlayerErrorOccur
  } = useReduxSelector(({ player }) => player);
  const focusedEventId = useReduxSelector(
    ({ focusedEventId }) => focusedEventId
  );
  const reduxModalState = useReduxSelector(({ modal }) => modal);
  const reduxToggleTrackPausedState = useTogglePausedState();
  const reduxSetAutoPlay = useSetAutoPlay();
  const reduxPlayNextTrack = usePlayNextPreview();
  const reduxEmptyQueueAndCurrentTrack = useEmptyQueueAndCurrentPreview();
  const openEventInformationModal = useOpenEventInformationModal();
  const closeModal = useCloseModal();

  const eventIdOrNull = activeTrackPlayerState
    ? activeTrackPlayerState.currentSetPreviewInformation.eventId
    : focusedEventId || null;

  //    Fetch from Server
  const useEventResult = useEvent(eventIdOrNull);
  const useClubResult = useClub(
    isHookRequestedResult(useEventResult) ? useEventResult.clubId : null
  );
  const useArtistsResult = useArtists(
    activeTrackPlayerState &&
      activeTrackPlayerState.currentSetPreviewInformation.artistIds
  );

  // Handling errors
  if (
    isHookErrorResponse(useEventResult) ||
    isHookErrorResponse(useClubResult) ||
    isHookErrorResponse(useArtistsResult)
  ) {
    const error = isHookErrorResponse(useEventResult)
      ? useEventResult
      : isHookErrorResponse(useClubResult)
      ? useClubResult
      : isHookErrorResponse(useArtistsResult)
      ? useArtistsResult
      : null;

    if (error === null) {
      throw neverTypescriptCase();
    }

    return <ErrorComponent error={error} />;
  }

  // Transform data
  const isThisEventInformationModalShowing =
    reduxModalState.type === ModalStateType.EVENT_INFORMATION &&
    reduxModalState.eventId === eventIdOrNull;
  const isPlayerPausedBecauseBuffering = activeTrackPlayerState
    ? activeTrackPlayerState.isPlayerPausedBecauseBuffering
    : false;
  const mainText = getMainText(useEventResult, useClubResult, isQueueLoading, didPlayerErrorOccur);
  // All redux hooks are prefixed with "redux" because we need to distinguish between redux-only handler and handlers that also track Google Analytics events
  const isPaused = !activeTrackPlayerState || activeTrackPlayerState.isPaused;

  // Create handlers
  const toggleTrackPlausedState = () => {
    ReactGA.event({
      category: GoogleAnalyticsCategory.PLAYER_GOOGLE_ANALYTICS_CATEGORY,
      action: isPaused ? "Resume" : "Pause"
    });
    reduxToggleTrackPausedState();
  };
  const setAutoPlay = (value: boolean) => {
    const googleAnalyticsActionName =
      (isAutoPlaying ? "Disabled" : "Enabled") + " Auto Play";
    ReactGA.event({
      category: GoogleAnalyticsCategory.PLAYER_GOOGLE_ANALYTICS_CATEGORY,
      action: googleAnalyticsActionName
    });
    reduxSetAutoPlay(value);
  };
  const playNextTrack = () => {
    ReactGA.event({
      category: GoogleAnalyticsCategory.PLAYER_GOOGLE_ANALYTICS_CATEGORY,
      action: "Play Next Track"
    });
    reduxPlayNextTrack();
  };
  const onStopClickHandler = () => {
    ReactGA.event({
      category: GoogleAnalyticsCategory.PLAYER_GOOGLE_ANALYTICS_CATEGORY,
      action: "Stop Clicked"
    });
    reduxEmptyQueueAndCurrentTrack();
  };

  // Parse the setStartTime from string to Moment
  const setStartTimestamp =
    activeTrackPlayerState &&
    activeTrackPlayerState.currentSetPreviewInformation.setStartTime
      ? moment(activeTrackPlayerState.currentSetPreviewInformation.setStartTime)
      : undefined;

  const setFloorName =
    isHookLoading(useClubResult) || activeTrackPlayerState === null
      ? "Loading..."
      : activeTrackPlayerState.currentSetPreviewInformation.setFloorId &&
        findByIdOrThrow(
          useClubResult.floors,
          activeTrackPlayerState.currentSetPreviewInformation.setFloorId
        ).name;

  const artistNames = isHookLoading(useArtistsResult)
    ? null
    : useArtistsResult.map(artist => artist.name);

  const moreInformationButtonProps =
    isHookLoading(useEventResult) || isHookLoading(useClubResult)
      ? undefined
      : {
          focusedEventImageUrl: getImageWithCrediteeToShowForEvent(
            useEventResult,
            useClubResult
          ).url,
          onMoreInformationClick: () => {
            if (isThisEventInformationModalShowing) {
              closeModal();
            } else {
              openEventInformationModal(useEventResult.id);
            }
          },
          isMoreInformationShowing: isThisEventInformationModalShowing
        };

  return (
    <Player
      moreInformationButtonProps={moreInformationButtonProps}
      isDisabled={!activeTrackPlayerState || didPlayerErrorOccur}
      hasLoadedTrack={activeTrackPlayerState !== null}
      hasNextTrackToPlay={trackQueue.length !== 0}
      isPaused={isPaused}
      isQueueLoading={isQueueLoading}
      isPlayerPausedBecauseBuffering={isPlayerPausedBecauseBuffering}
      onNextClick={playNextTrack}
      onStopClick={onStopClickHandler}
      onPlayPauseClick={toggleTrackPlausedState}
      playerAttributionComponent={
        !isQueueLoading && !didPlayerErrorOccur && (
          <PlayerAttributionText
            activeTrackPlayerState={activeTrackPlayerState}
          />
        )
      }
      playerMainTextComponent={
        <PlayerMainTextStyler>{mainText}</PlayerMainTextStyler>
      }
      playerSubTextComponent={
        activeTrackPlayerState &&
        !didPlayerErrorOccur &&
        !isQueueLoading && (
          <PlayerSubText
            artistNames={artistNames}
            setFloor={setFloorName}
            setStartTimestamp={setStartTimestamp}
            currentTimeStamp={activeTrackPlayerState.currentTimeStamp}
            currentTrackNextTrackTimestamp={
              activeTrackPlayerState.currentTrackContinueTimestamp
            }
            isAutoPlaying={isAutoPlaying}
            queueLength={trackQueue.length}
            setAutoPlay={setAutoPlay}
          />
        )
      }
    />
  );
}

const getMainText = (
  useEventResult: SerializedEvent | null,
  useClubResult: SerializedClub | null,
  isQueueLoading: boolean,
  didPlayerErrorOccur: boolean
) => {
  if(didPlayerErrorOccur){
    return "Oops! An error with the Soundcloud player occured."
  }
  if (isQueueLoading) {
    return "Loading...";
  }

  if (
    !isHookRequestedResult(useEventResult) ||
    !isHookRequestedResult(useClubResult)
  ) {
    return "Click an event to listen to who is playing!";
  }

  return useEventResult.name + " at " + useClubResult.name;
};
