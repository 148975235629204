import React from "react";
import { useOpenImageModal } from "../../redux/modal_state/modal_actions";
import styled from "styled-components";
import ClassNamePropType from "../../data_models/react_prop_types/ClassNamePropType";

type IProps = {
  imageUrl: string;
  creditee: string;
} & ClassNamePropType;

const ClickableImage = styled.img`
  cursor: pointer;
`;

export default function InspectableImage({
  imageUrl,
  creditee,
  className
}: IProps) {
  const openImageModal = useOpenImageModal();

  return (
    <ClickableImage
      className={className}
      src={imageUrl}
      onClick={() => openImageModal(imageUrl, creditee)}
    />
  );
}
