import React from "react";
import SvgWithDefaultStyler from "./SvgWithDefaultStyle";
import ClassNamePropType from "../../data_models/react_prop_types/ClassNamePropType";

export default function Stop({ className }: ClassNamePropType) {
  return (
    <SvgWithDefaultStyler
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g id="icon/av/stop_24px">
        <path
          id="icon/av/stop_24px_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 6H18V18H6V6ZM16 16V8H8V16H16Z"
        />
      </g>
    </SvgWithDefaultStyler>
  );
}
