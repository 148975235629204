import React from "react";
import SvgWithDefaultStyler from "./SvgWithDefaultStyle";
import ClassNamePropType from "../../data_models/react_prop_types/ClassNamePropType";

export default function Next({ className }: ClassNamePropType) {
  return (
    <SvgWithDefaultStyler
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g id="icon/av/skip_next_24px">
        <path
          id="icon/av/skip_next_24px_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 18L14.5 12L6 6V18ZM8 9.86L11.03 12L8 14.14V9.86ZM18 6H16V18H18V6Z"
        />
      </g>
    </SvgWithDefaultStyler>
  );
}
