import React, { useRef } from "react";
import ApolloClient from "apollo-boost";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import reduxStore from "../../redux/reduxStore";
import GlobalStyle from "../../GlobalStyle";
import PageRoutes from "../PageRoutes";
import { ApolloProvider } from "@apollo/react-hooks";
import Header from "../Header/Header";
import PlayerStateManager from "../PlayerStateManager/PlayerStateManager";
import ModalContainer from "../modals/ModalContainer";
import PlayerContainer from "../Player/PlayerContainer";
import {
  AppContentStyler,
  AppHeaderStyler,
  AppPageStyler,
  AppFooterStyler
} from "./App.styles";
import getElementByIdOrThrow from "../../utils/getElementByIdOrThrow";

const apolloClient = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT
});

// We need to be able to refer to the app content styler by ID to have the Modal set the content styler as it's parent
// See http://reactcommunity.org/react-modal/#custom-parent
const appContentStylerId = "app-content-styler";
const appContentStylerSelector = () =>
  getElementByIdOrThrow(appContentStylerId);

const App = () => {
  // We need to be able to refer to the app page styler by ID to have that element ignored by aria when the Modal is active
  // See http://reactcommunity.org/react-modal/#custom-parent
  const appPageStylerRef = useRef<HTMLDivElement>(null);

  return (
    <Provider store={reduxStore}>
      <ApolloProvider client={apolloClient}>
        <BrowserRouter>
          <GlobalStyle />
          <PlayerStateManager />
          <ModalContainer
            overlayedElement={appPageStylerRef.current || undefined}
            parentSelector={appContentStylerSelector}
          />
          <AppContentStyler id={appContentStylerId}>
            <AppHeaderStyler>
              <Header />
            </AppHeaderStyler>
            <AppPageStyler ref={appPageStylerRef}>
              <PageRoutes />
            </AppPageStyler>
            <AppFooterStyler>
              <PlayerContainer />
            </AppFooterStyler>
          </AppContentStyler>
        </BrowserRouter>
      </ApolloProvider>
    </Provider>
  );
};

export default App;
