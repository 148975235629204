import SerializedSetInformation from "../data_models/SetInformation/SerializedSetInformation";
import events from "../static_db/events";
import findByIdOrThrow from "../utils/findByIdOrThrow";

const fetchSet = async (setId: string): Promise<SerializedSetInformation> => {
  const allSets = events.flatMap(event => event.sets);

  const set = findByIdOrThrow(allSets, setId);

  return set;
};

export default fetchSet;
