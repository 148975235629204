import styled, { css } from "styled-components";
import { defaultCopyFontFamily } from "../../styles/font_styles";
import generateBootstrap4MediaQueries from "../style_mixins/bootstrap4MediaQueries";

const baseHeaderStylerCss = css`
  display: flex;
  flex-direction: row;
  border-bottom: 1px black solid;
  padding: 20px 40px;
`;

const defaultHeaderStyleCss = css`
  ${baseHeaderStylerCss}
  justify-content: space-between;
  align-items: flex-end;
  height: 100px;
`;

const mobilePortraitHeaderStylerCss = css`
  ${baseHeaderStylerCss}
  justify-content: center;
  align-items: center;
  height: 64px;
`;

export const HeaderStyler = styled.div`
  ${generateBootstrap4MediaQueries({
    extraSmallCss: mobilePortraitHeaderStylerCss,
    mediumCss: defaultHeaderStyleCss
  })}
`;

const baseHeaderTitleStylerCss = css`
  font-family: ${defaultCopyFontFamily};
`;

const mobilePortraitHeaderTitleStylerCss = css`
  ${baseHeaderTitleStylerCss}
  font-size: 24px;
`;

const defaultHeaderTitleStylerCss = css`
  ${baseHeaderTitleStylerCss}
  font-size: 32px;
`;

export const HeaderTitle = styled.span`
  ${generateBootstrap4MediaQueries({
    extraSmallCss: mobilePortraitHeaderTitleStylerCss,
    mediumCss: defaultHeaderTitleStylerCss
  })}
`;
