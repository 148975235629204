import React from "react";
import SvgWithDefaultStyler from "./SvgWithDefaultStyle";
import ClassNamePropType from "../../data_models/react_prop_types/ClassNamePropType";

export default function Play({ className }: ClassNamePropType) {
  return (
    <SvgWithDefaultStyler
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g id="icon/av/play_arrow_24px">
        <path
          id="icon/av/play_arrow_24px_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.5 12L6.5 19V5L17.5 12ZM13.77 12L8.5 8.64V15.36L13.77 12Z"
        />
      </g>
    </SvgWithDefaultStyler>
  );
}
