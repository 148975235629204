import React from "react";
import CityView from "./CityView";
import useCityViewData from "../../../api/useCityViewData";
import ServerError from "../../ServerError/ServerError";
import {
  isHookErrorResponse,
  isHookLoading
} from "../../../api/HookReturnValue";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";

type IProps = {
  cityPermalink: string;
};

export default function CityViewContainer({ cityPermalink }: IProps) {
  const useCityViewDataResponse = useCityViewData({ cityPermalink });

  if (isHookErrorResponse(useCityViewDataResponse)) {
    return <ServerError error={useCityViewDataResponse} />;
  } else if (isHookLoading(useCityViewDataResponse)) {
    return <LoadingSpinner />;
  }

  const { city, events } = useCityViewDataResponse;

  return <CityView city={city} events={events} />;
}
