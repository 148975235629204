import React from "react";
import { useCloseModal } from "../../redux/modal_state/modal_actions";
import { useReduxSelector } from "../../redux/redux_hooks";
import { ModalStateType } from "../../redux/modal_state/modalReducer";
import StyledModal from "./StyledModal";
import ModalContent from "./ModalContent";

interface IProps {
  overlayedElement: HTMLElement | undefined;
  parentSelector: () => HTMLElement;
}

export default function ModalContainer({
  overlayedElement,
  parentSelector
}: IProps) {
  const modalState = useReduxSelector(({ modal }) => modal);
  const isModalOpen = modalState.type !== ModalStateType.CLOSED;
  const closeModal = useCloseModal();

  return (
    <StyledModal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      appElement={overlayedElement}
      parentSelector={parentSelector}
    >
      <ModalContent {...modalState} />
    </StyledModal>
  );
}
