import ReactGA from "react-ga";
import React from "react";
import { ActiveTrackPlayerState } from "../../redux/player_state/playerReducer";
import LinkButton from "../buttons/LinkButton";
import GoogleAnalyticsCategory from "../../data_models/enums/GoogleAnalyticsCategory";
import { PlayerAttributionTextStyler } from "./Player.styles";

interface IProps {
  activeTrackPlayerState: ActiveTrackPlayerState;
}

export default function PlayerAttributionText({
  activeTrackPlayerState
}: IProps) {
  if (activeTrackPlayerState === null) {
    return null;
  }

  const {
    currentSetPreviewInformation: { soundcloudPermalinkUrl, soundcloudUploader }
  } = activeTrackPlayerState;

  const onOpenSourceClickHandler = () => {
    ReactGA.event({
      category: GoogleAnalyticsCategory.PLAYER_GOOGLE_ANALYTICS_CATEGORY,
      action: "Open Track Source",
      label: soundcloudPermalinkUrl
    });
    window.open(soundcloudPermalinkUrl, "_blank");
  };

  return (
    <PlayerAttributionTextStyler>
      Audio through Soundcloud uploaded by "{soundcloudUploader}".{" "}
      <LinkButton onClick={onOpenSourceClickHandler}>Click here</LinkButton> for
      source.
    </PlayerAttributionTextStyler>
  );
}
