import React from "react";
import SvgWithDefaultStyler from "./SvgWithDefaultStyle";
import ClassNamePropType from "../../data_models/react_prop_types/ClassNamePropType";

export default function CollapseIcon({ className }: ClassNamePropType) {
  return (
    <SvgWithDefaultStyler
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
    >
      <switch>
        <foreignObject
          requiredExtensions="http://ns.adobe.com/AdobeIllustrator/10.0/"
          x="0"
          y="0"
          width="1"
          height="1"
        />
        <g>
          <g>
            <path d="M9.9,96.2l18.9-18.9l0,8.9c0,2.4,1.9,4.3,4.3,4.3c2.4,0,4.3-1.9,4.3-4.3V66.9c0-1.1-0.5-2.2-1.3-3.1     c-0.8-0.8-1.9-1.3-3.1-1.3l-19.4,0c-1.2,0-2.3,0.5-3.1,1.3c-0.8,0.8-1.3,1.9-1.3,3.1c0,2.4,1.9,4.3,4.3,4.3l8.9,0L3.8,90.1     c-1.7,1.7-1.7,4.4,0,6.1C5.5,97.9,8.2,97.9,9.9,96.2z" />
            <path d="M86.2,37.4c2.4,0,4.3-1.9,4.3-4.3c0-2.4-1.9-4.3-4.3-4.3l-8.9,0L96.2,9.9c1.7-1.7,1.7-4.4,0-6.1c-1.7-1.7-4.4-1.7-6.1,0     L71.2,22.7l0-8.9c0-2.4-1.9-4.3-4.3-4.3c-1.2,0-2.3,0.5-3.1,1.3c-0.8,0.8-1.3,1.9-1.3,3.1l0,19.4c0,1.1,0.5,2.2,1.3,3.1     c0.8,0.8,1.9,1.3,3.1,1.3H86.2z" />
            <circle cx="50" cy="50" r="12.4" />
          </g>
        </g>
      </switch>
    </SvgWithDefaultStyler>
  );
}
