import styled from "styled-components";

interface IProps {
  imageUrl: string;
  objectFit?: "cover" | "contain";
}

const ClickableImage = styled.div<IProps>`
  background-image: url(${({ imageUrl }) => imageUrl});
  background-size: ${({ objectFit }) => objectFit || "cover"};
  background-position-y: center;
  background-position-x: center;
  background-repeat: no-repeat;

  cursor: ${({ onClick }) => (onClick ? "pointer" : "default")};
`;

export default ClickableImage;
