import styled from "styled-components";
import { defaultCopyFontFamily } from "../../../styles/font_styles";
import PlayPauseButton from "../../buttons/PlayPauseButton";

export const LineupDisplayerIconCopyRow = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 16px;
`;

export const LineupDisplayerStyler = styled.div`
  padding: 8px;
  display: grid;
  grid-row-gap: 8px;
`;

export const LineupDisplayerHeaderCopyStyler = styled.div`
  font-family: ${defaultCopyFontFamily};
  font-size: 24px;
  font-weight: bold;
`;

export const LineupDisplayerHeaderPlayButton = styled(PlayPauseButton)`
  && {
    height: 32px;
    width: 32px;
  }
`;

export const LineupDisplayerSetItemPlayButton = styled(PlayPauseButton)`
  && {
    width: 24px;
    height: 24px;
  }
`;

export const LineupDisplayerSetItemCopyStyler = styled.div`
  font-family: ${defaultCopyFontFamily};
  font-size: 18px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
