import React from "react";
import styled from "styled-components";
import SvgWithDefaultStyler from "./SvgWithDefaultStyle";
import ClassNamePropType from "../../data_models/react_prop_types/ClassNamePropType";

const OverridenDefaultSVGStyle = styled(SvgWithDefaultStyler)`
  width: 100px;
  height: 87.54px;
`;

export default function QueueIcon({ className }: ClassNamePropType) {
  return (
    <OverridenDefaultSVGStyle
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 338.695 296.48"
      xmlSpace="preserve"
      className={className}
    >
      <g>
        <path d="M36.918,134.517c-1.297,1.311-3.066,2.167-5.059,2.284c-0.143,0.006-0.285,0.014-0.428,0.014   c-1.025,0-2.006-0.202-2.904-0.568v39.349c0,3.724,3.017,6.739,6.74,6.739c0.643,0,1.264-0.096,1.853-0.264   c-0.131-0.656-0.203-1.333-0.203-2.027V134.517z" />
        <path d="M23.377,118.066c0.021-16.291,2.389-27.812,7.24-35.222c0.508-0.772,1.051-1.5,1.621-2.18H16.73   c-0.082,0-0.166,0.009-0.248,0.014c-0.99,0.019-2.6,0.239-4.428,1.161c-1.936,0.966-4.027,2.71-5.818,5.428   C2.648,92.744,0.023,101.851,0,118.306c0,3.193,0.103,6.663,0.324,10.443c0.133,2.319,2.059,4.108,4.348,4.108   c0.086,0,0.174-0.004,0.26-0.008c2.406-0.141,4.24-2.204,4.1-4.609c-0.213-3.624-0.309-6.928-0.309-9.935   c-0.006-10.907,1.287-17.896,2.822-22.154c0.322-0.89,0.65-1.649,0.975-2.313v81.757c0,3.724,3.016,6.739,6.74,6.739   c3.721,0,6.738-3.016,6.738-6.739v-41.033c-1.303-1.297-2.152-3.054-2.266-5.021C23.498,125.535,23.377,121.676,23.377,118.066z" />
        <circle cx="27.264" cy="65.292" r="12.266" />
        <path d="M67.131,135.34c-0.039,0.046-0.078,0.092-0.117,0.137c-1.439,1.617-3.42,2.578-5.619,2.705   c-0.146,0.008-0.295,0.014-0.439,0.014c-1.338,0-2.603-0.327-3.721-0.907v42.754c0,4.028,3.266,7.291,7.293,7.291   c0.969,0,1.891-0.193,2.736-0.536c-0.078-0.526-0.133-1.06-0.133-1.607V135.34z" />
        <path d="M52.486,118.166c0.024-17.57,2.568-29.982,7.791-37.957c0.715-1.086,1.453-2.029,2.189-2.855H44.475   c-0.09,0-0.18,0.01-0.27,0.015c-1.07,0.021-2.811,0.259-4.789,1.257c-2.094,1.045-4.357,2.931-6.295,5.871   c-3.879,5.924-6.721,15.775-6.744,33.575c0,3.454,0.111,7.208,0.35,11.297c0.144,2.509,2.226,4.444,4.703,4.444   c0.094,0,0.189-0.004,0.281-0.009c2.604-0.152,4.588-2.384,4.434-4.985c-0.229-3.921-0.332-7.495-0.332-10.747   c-0.008-11.799,1.393-19.359,3.053-23.966c0.348-0.961,0.703-1.784,1.053-2.502v88.439c0,4.028,3.264,7.291,7.291,7.291   c4.025,0,7.291-3.263,7.291-7.291v-45.069c-0.938-1.239-1.534-2.754-1.631-4.411C52.615,126.195,52.486,122.025,52.486,118.166z" />
        <circle cx="55.868" cy="60.724" r="13.269" />
        <path d="M99.586,135.844c-0.199,0.287-0.416,0.565-0.652,0.828c-1.529,1.721-3.637,2.741-5.916,2.873   c-0.174,0.01-0.348,0.016-0.522,0.016c-1.298,0-2.529-0.298-3.637-0.818v46.449c0,4.356,3.531,7.886,7.887,7.886   c1.022,0,1.994-0.201,2.891-0.554c-0.029-0.33-0.051-0.663-0.051-1V135.844z" />
        <path d="M83.488,117.844C83.514,98.591,86.295,84.996,92,76.278c0.518-0.786,1.047-1.483,1.58-2.142H75.061   c-0.098,0-0.195,0.01-0.293,0.015c-1.156,0.022-3.039,0.28-5.178,1.36c-2.264,1.128-4.715,3.168-6.809,6.348   c-4.193,6.408-7.27,17.061-7.295,36.312c0,3.735,0.121,7.795,0.379,12.218c0.156,2.713,2.408,4.806,5.088,4.806   c0.102,0,0.203-0.004,0.303-0.009c2.814-0.165,4.961-2.578,4.795-5.392c-0.246-4.241-0.359-8.106-0.359-11.623   c-0.006-12.76,1.506-20.937,3.303-25.919c0.375-1.039,0.76-1.929,1.137-2.705v95.645c0,4.356,3.531,7.886,7.887,7.886   c4.351,0,7.885-3.529,7.885-7.886v-48.725c-1.148-1.372-1.885-3.104-1.996-5.014C83.629,126.662,83.488,122.085,83.488,117.844z" />
        <circle cx="87.381" cy="56.151" r="14.351" />
        <path d="M118.918,117.441c0.029-20.78,3.025-35.441,9.168-44.824c0.79-1.199,1.604-2.25,2.417-3.177h-22.499   c-0.107,0-0.215,0.011-0.322,0.017c-1.271,0.025-3.342,0.309-5.691,1.495c-2.488,1.241-5.184,3.483-7.486,6.979   c-4.609,7.044-7.99,18.755-8.018,39.917c0,4.105,0.133,8.568,0.416,13.43c0.174,2.982,2.646,5.283,5.594,5.283   c0.111,0,0.223-0.004,0.332-0.01c3.094-0.18,5.453-2.834,5.272-5.926c-0.272-4.663-0.395-8.911-0.395-12.777   c-0.008-14.027,1.654-23.016,3.631-28.492c0.412-1.143,0.834-2.12,1.25-2.974v105.142c0,4.788,3.881,8.668,8.668,8.668   c4.785,0,8.668-3.88,8.668-8.668v-56.756c-0.305-0.822-0.496-1.7-0.549-2.617C119.07,126.976,118.918,122.028,118.918,117.441z" />
        <path d="M136.334,136.064c-0.314,0.56-0.695,1.092-1.133,1.585c-1.611,1.811-3.83,2.884-6.248,3.024   c-0.178,0.01-0.357,0.017-0.533,0.017c-1.951,0-3.762-0.63-5.248-1.691v52.524c0,4.788,3.883,8.668,8.67,8.668   c1.702,0,3.285-0.498,4.623-1.344c-0.076-0.557-0.131-1.121-0.131-1.7V136.064z" />
        <circle cx="121.548" cy="49.669" r="15.775" />
        <path d="M173.289,136.494c-0.406,0.971-0.973,1.877-1.693,2.688c-1.705,1.916-4.053,3.053-6.629,3.203   c-0.182,0.01-0.369,0.014-0.551,0.014c-0.977,0-1.92-0.15-2.811-0.424v55.173c0,5.181,4.199,9.378,9.379,9.378   c0.863,0,1.695-0.127,2.489-0.345c-0.11-0.688-0.185-1.387-0.185-2.105V136.494z" />
        <path d="M154.838,133.358c-0.328-5.593-0.492-10.97-0.492-15.983c0.029-22.551,3.275-38.449,9.928-48.614   c0.926-1.405,1.881-2.621,2.832-3.68h-21.91c-0.117,0-0.232,0.011-0.348,0.018c-1.375,0.027-3.615,0.335-6.156,1.618   c-2.693,1.342-5.607,3.768-8.1,7.549c-4.986,7.619-8.643,20.288-8.674,43.181c0,4.442,0.143,9.271,0.451,14.529   c0.188,3.227,2.863,5.716,6.051,5.716c0.119,0,0.24-0.005,0.359-0.012c3.346-0.195,5.9-3.064,5.703-6.411   c-0.295-5.044-0.428-9.64-0.428-13.822c-0.008-15.175,1.791-24.897,3.928-30.822c0.447-1.235,0.902-2.293,1.352-3.216v113.739   c0,5.181,4.199,9.378,9.379,9.378c5.176,0,9.377-4.197,9.377-9.378v-57.15C156.227,138.358,154.994,136.012,154.838,133.358z" />
        <circle cx="159.848" cy="43.693" r="17.065" />
        <path d="M214.844,137.088c-0.377,1.654-1.166,3.194-2.324,4.496c-1.813,2.037-4.307,3.243-7.008,3.4   c-0.209,0.014-0.414,0.021-0.619,0.021c-1.565,0-3.048-0.365-4.379-1.001v60.07c0,5.635,4.568,10.201,10.201,10.201   c1.487,0,2.896-0.326,4.17-0.898c-0.016-0.274-0.041-0.545-0.041-0.822V137.088z" />
        <path d="M194.178,117.976c0.033-24.541,3.561-41.838,10.787-52.884c1.209-1.836,2.463-3.376,3.703-4.673h-26.004   c-0.127,0-0.252,0.012-0.377,0.019c-1.498,0.03-3.934,0.364-6.697,1.76c-2.932,1.46-6.102,4.099-8.811,8.212   c-5.424,8.288-9.402,22.068-9.434,46.97c0,4.832,0.154,10.084,0.488,15.805c0.205,3.509,3.115,6.216,6.582,6.216   c0.129,0,0.262-0.004,0.391-0.012c3.639-0.212,6.418-3.334,6.205-6.975c-0.32-5.485-0.467-10.484-0.467-15.034   c-0.008-16.506,1.949-27.081,4.273-33.527c0.486-1.344,0.98-2.494,1.471-3.498v123.72c0,5.635,4.566,10.201,10.201,10.201   c5.631,0,10.199-4.566,10.199-10.201v-63.222c-1.137-1.541-1.855-3.414-1.975-5.456   C194.357,129.27,194.178,123.411,194.178,117.976z" />
        <circle cx="198.602" cy="37.155" r="18.563" />
        <path d="M255.475,145.74c-1.953,2.197-4.643,3.5-7.58,3.674c-0.213,0.012-0.432,0.018-0.643,0.018c-1.033,0-2.031-0.149-2.98-0.418   v63.543c0,6.148,4.982,11.127,11.129,11.127c1.127,0,2.213-0.171,3.238-0.482v-83.699h-0.445   C257.972,141.807,257.042,143.977,255.475,145.74z" />
        <path d="M236.273,139.068c-0.396-6.814-0.596-13.318-0.596-19.332c0.033-27.203,3.936-46.361,11.93-58.578   c1.387-2.104,2.825-3.852,4.244-5.313h-27.053c-0.139,0-0.275,0.013-0.412,0.021c-1.633,0.033-4.289,0.397-7.307,1.92   c-3.197,1.594-6.654,4.471-9.609,8.959c-5.918,9.041-10.258,24.074-10.293,51.238c0,5.271,0.17,11,0.533,17.241   c0.223,3.827,3.398,6.78,7.182,6.78c0.141,0,0.283-0.004,0.426-0.012c3.969-0.233,7-3.639,6.768-7.608   c-0.35-5.985-0.508-11.438-0.508-16.401c-0.008-18.006,2.125-29.543,4.662-36.575c0.531-1.466,1.07-2.721,1.604-3.815v134.965   c0,6.148,4.982,11.127,11.129,11.127c6.143,0,11.127-4.979,11.127-11.127v-65.791C237.908,144.883,236.453,142.158,236.273,139.068   z" />
        <circle cx="242.186" cy="30.465" r="20.25" />
        <path d="M331.816,71.695c-4.059-10.34-9.855-16.175-15.234-18.842c-3.357-1.691-6.309-2.096-8.123-2.131   c-0.152-0.009-0.303-0.023-0.457-0.023h-38.635c-0.154,0-0.307,0.015-0.459,0.023c-1.814,0.037-4.766,0.441-8.117,2.133   c-3.553,1.77-7.394,4.968-10.678,9.953c-6.574,10.046-11.396,26.749-11.436,56.932c0,5.857,0.188,12.223,0.592,19.156   c0.248,4.253,3.777,7.534,7.98,7.534c0.156,0,0.314-0.005,0.473-0.014c4.41-0.259,7.779-4.042,7.52-8.454   c-0.389-6.649-0.564-12.708-0.564-18.223c-0.01-20.007,2.361-32.826,5.18-40.639c0.59-1.628,1.19-3.023,1.781-4.239V224.82   c0,6.83,5.537,12.363,12.365,12.363c6.826,0,12.363-5.533,12.363-12.363v-90.271h4.635v90.271c0,6.83,5.537,12.363,12.365,12.363   c6.826,0,12.363-5.533,12.363-12.363V74.824c3.467,7.1,6.998,20.704,6.963,44.916c0,5.513-0.176,11.571-0.568,18.221   c-0.258,4.412,3.107,8.195,7.52,8.456c0.158,0.009,0.316,0.014,0.475,0.014c4.205,0,7.733-3.28,7.981-7.532   c0.406-6.934,0.596-13.303,0.596-19.158C338.68,97.08,335.924,82.075,331.816,71.695z" />
        <circle cx="288.686" cy="22.5" r="22.5" />
      </g>
    </OverridenDefaultSVGStyle>
  );
}
