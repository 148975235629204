import { useState, useEffect } from "react";
import SerializedEvent from "../data_models/Event/SerializedEvent";
import events from "../static_db/events";
import findById from "../utils/findById";
import ServerErrorResponse from "../data_models/ServerErrorResponse";
import { dataByIdNotFound } from "../utils/error_builders/server_error_response_builders";
import { HookReturnValue } from "./HookReturnValue";

type ReturnValue = HookReturnValue<SerializedEvent>;

/**
 * Fetch an event by eventId from server
 * @param eventId The ID of the event to get. Can be null to allow this hook to called top-level.
 */
const useEvent = (eventId: string | null): ReturnValue => {
  const [event, setEvent] = useState<SerializedEvent | null>(null);
  const [error, setError] = useState<ServerErrorResponse | null>(null);

  useEffect(() => {
    // Don't do anything if eventId is null
    if (eventId === null) {
      return;
    }
    const tempEvent = findById(events, eventId);

    if (tempEvent === undefined) {
      setError(dataByIdNotFound(eventId));
    } else {
      setEvent(tempEvent);
    }
  }, [eventId]);

  if (error) {
    return error;
  } else if (event) {
    return event;
  } else {
    return null;
  }
};

export default useEvent;
