import getAllPermalinkedData from "./getAllPermalinkedData";
import Permalinkable from "../../data_models/Permalinkable";

const getPermalinkedData = (permalink: string): Permalinkable | undefined => {
  const allPermalinkedData = getAllPermalinkedData();

  return allPermalinkedData.find(data =>
    data.permalinks.some(dataPermalink => dataPermalink === permalink)
  );
};

export default getPermalinkedData;
