import React, { ReactNode } from "react";
import CollapsableWrapper from "../../CollapsableWrapper/CollapsableWrapper";
import CardsGrid from "../../MusicalItemsGrid/CardsGrid";
import {
  HeaderImageWithGroupedCardsViewHeaderImage,
  HeaderImageWithGroupedCardsViewGrid
} from "./HeaderImageWithGroupedCardsView.styles";
import P from "../../Copy/P";

interface IProps {
  headerImageUrl: string;
  groupsWithCards: {
    groupKey: string;
    groupHeader: string;
    cards: ReactNode[];
  }[];
  noCardsMessage?: string;
}

export default function HeaderImageWithGroupedCardsView({
  groupsWithCards,
  headerImageUrl,
  noCardsMessage
}: IProps) {
  const groupsToRender = groupsWithCards.map(
    ({ groupKey, groupHeader, cards }) => {
      return (
        <CollapsableWrapper title={groupHeader} key={groupKey}>
          <CardsGrid>{cards}</CardsGrid>
        </CollapsableWrapper>
      );
    }
  );

  const cardsOrFallbackMessage =
    groupsWithCards.length > 0
      ? groupsToRender
      : noCardsMessage && <P>{noCardsMessage}</P>;

  return (
    <div>
      <HeaderImageWithGroupedCardsViewHeaderImage src={headerImageUrl} />
      <HeaderImageWithGroupedCardsViewGrid>
        {cardsOrFallbackMessage}
      </HeaderImageWithGroupedCardsViewGrid>
    </div>
  );
}
