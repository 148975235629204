import React from "react";
import ClassNamePropType from "../../data_models/react_prop_types/ClassNamePropType";
import SvgWithDefaultStyler from "./SvgWithDefaultStyle";

export default function ArrowDown({ className }: ClassNamePropType) {
  return (
    <SvgWithDefaultStyler
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M19.355 9.67749L14 15.0208L8.645 9.67749L7 11.3225L14 18.3225L21 11.3225L19.355 9.67749Z" />
    </SvgWithDefaultStyler>
  );
}
