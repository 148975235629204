import React from "react";
import ServerErrorResponse from "../../data_models/ServerErrorResponse";
import { ApolloError } from "apollo-boost";

interface IProps {
  error: ServerErrorResponse | ApolloError;
}

export default function ServerError({ error }: IProps) {
  return (
    <div>
      <p>{error.message}</p>
    </div>
  );
}
