import React, { ReactNode } from "react";
import styled from "styled-components";

const BlankModalStyler = styled.div`
  border: 1px black solid;
  background: white;
  min-height: 104px;
  min-width: 200px;
`;

interface IProps {
  children?: ReactNode;
}

export default function BlankModal({ children }: IProps) {
  return <BlankModalStyler>{children}</BlankModalStyler>;
}
