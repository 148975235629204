import React from "react";
import ClassNamePropType from "../../data_models/react_prop_types/ClassNamePropType";
import SvgWithDefaultStyler from "./SvgWithDefaultStyle";
import styled from "styled-components";

const OverriddenDefaultSvgStyle = styled(SvgWithDefaultStyler)`
  width: 100px;
  height: 60px;
`;

export default function MovieTicket({ className }: ClassNamePropType) {
  return (
    <OverriddenDefaultSvgStyle
      viewBox="0 0 80 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M0 0V1.04348V12.5217V13.5652H0.97561C6.37522 13.5652 10.7317 18.2248 10.7317 24C10.7317 29.7754 6.37532 34.4348 0.97561 34.4348H0V35.4783V46.9565V48H0.97561H79.0244H80V46.9565V35.4783V34.4348H79.0244C73.6247 34.4348 69.2683 29.7754 69.2683 24C69.2683 18.2248 73.6248 13.5652 79.0244 13.5652H80V12.5217V1.04348V0H79.0244H0.97561H0V0ZM1.95122 2.08696H53.6585V9.3913H55.6098V2.08696H78.0488V11.6739C72.0729 12.2317 67.3171 17.4666 67.3171 24C67.3171 30.5319 72.0752 35.7333 78.0488 36.2935V45.913H55.6098V38.6087H53.6585V45.913H1.95122V36.2935C7.92478 35.7333 12.6829 30.5319 12.6829 24C12.6829 17.4666 7.92712 12.2317 1.95122 11.6739V2.08696V2.08696ZM53.6585 13.5652V21.913H55.6098V13.5652H53.6585ZM53.6585 26.087V34.4348H55.6098V26.087H53.6585Z" />
    </OverriddenDefaultSvgStyle>
  );
}
