import styled from "styled-components";
import { white, mediumGrey, lightGrey } from "../../../styles/colors";

export const ChessboardModalStyler = styled.div`
  display: grid;
  grid-template-columns: 386px 832px;
  grid-template-rows: 88px 728px;
  grid-template-areas:
    "top-left header"
    "bottom-left main-area";
`;

export const ChessboardModalTopLeftArea = styled.div`
  grid-area: "top-left";
`;

export const ChessboardModalHeaderArea = styled.div`
  grid-area: "header";
  background-color: ${white};
`;

export const ChessboardModalBottomLeftArea = styled.div`
  grid-area: "bottom-left";
  background-color: ${mediumGrey};
`;

export const ChessboardModalMainAreaArea = styled.div`
  grid-area: "main-area";
  background-color: ${lightGrey};
`;
