import SerializedArtist from "../data_models/Artist/SerializedArtist";

export const anetha = {
  name: "Anetha",
  id: "14a0e6c3-1b99-4546-8f19-30b750202f61",
  representableMixSoundcloudTrackId: 421247805
};

export const ninexnine: SerializedArtist = {
  name: "999999999",
  id: "3d7422f7-c47e-4600-a001-c17946c7c77a",
  representableMixSoundcloudTrackId: 654641150
};

export const alignment: SerializedArtist = {
  name: "Alignment",
  id: "3fb7e2b4-0c38-41ae-986c-0c100135b6eb",
  representableMixSoundcloudTrackId: 567168444
};

export const karenn: SerializedArtist = {
  name: "Karenn",
  id: "75f14e93-f456-42d8-93de-5adc1ec1ca0f",
  representableMixSoundcloudTrackId: 180563892
};

export const lenFaki: SerializedArtist = {
  name: "Len Faki",
  id: "4344fdef-81fd-4e6b-a1fb-687e9a5b7176",
  representableMixSoundcloudTrackId: 257635558
};

export const actress: SerializedArtist = {
  name: "Actress",
  id: "a0b1f940-238e-4039-8255-80188ed0026f",
  representableMixSoundcloudTrackId: null
};

export const anthro: SerializedArtist = {
  name: "Anthro",
  id: "ec878238-aac1-426c-8334-f49677fcca91",
  representableMixSoundcloudTrackId: 508069662
};

export const djRichard: SerializedArtist = {
  name: "DJ Richard",
  id: "86d7e014-49e8-42c5-a18a-07361c40afe2",
  representableMixSoundcloudTrackId: null
};

export const ignota: SerializedArtist = {
  name: "IGNOTA",
  id: "e9137753-a077-4437-b7fc-847215c29410",
  representableMixSoundcloudTrackId: 553662747
};

export const ikaDuchna: SerializedArtist = {
  name: "Ika Duchna",
  id: "7bcfa543-63cb-4200-b4a5-1e883dd1415f",
  representableMixSoundcloudTrackId: 593739546
};

export const imogen: SerializedArtist = {
  name: "IMOGEN",
  id: "70a9ac50-7bdc-414e-95ea-4329e641832b",
  representableMixSoundcloudTrackId: 594005994
};

export const konkurs: SerializedArtist = {
  name: "KONKURS",
  id: "cb8c9ae4-f423-47b4-824e-2c0ed2f5413d",
  representableMixSoundcloudTrackId: 474371784
};

export const lakker: SerializedArtist = {
  name: "Lakker",
  id: "bc483673-f5f8-4f21-833c-cf2807ddcb34",
  representableMixSoundcloudTrackId: null
};

export const lawrence: SerializedArtist = {
  name: "Lawrence",
  id: "29984b09-4122-450d-a20e-0d54a8c78a0d",
  representableMixSoundcloudTrackId: null
};

export const louisahhh: SerializedArtist = {
  name: "Louisahh",
  id: "78591231-ec0f-48f5-b9f8-21bd0c1cb920",
  representableMixSoundcloudTrackId: 321711664
};

export const sisselWincent: SerializedArtist = {
  name: "Sissel Wincent",
  id: "ab4f10a5-268a-43ca-870b-8ff1d4f089e2",
  representableMixSoundcloudTrackId: null
};

export const snts: SerializedArtist = {
  name: "SNTS",
  id: "fed6cc61-581d-4e8b-89e2-0147526e2794",
  representableMixSoundcloudTrackId: 239281399
};

export const tini: SerializedArtist = {
  name: "tINI",
  id: "f89693c2-61f6-4289-8295-0a5ffeec595e",
  representableMixSoundcloudTrackId: 203405731
};

export const wallis: SerializedArtist = {
  name: "Wallis",
  id: "426560dc-ab43-4bf2-8abf-a056e4d4de72",
  representableMixSoundcloudTrackId: 631986237
};

export const iHateModels: SerializedArtist = {
  name: "I HATE MODELS",
  id: "369deb86-4686-4587-8214-3c015a6f886e",
  representableMixSoundcloudTrackId: 518670234
};

export const emmanuel: SerializedArtist = {
  name: "Emmanuel",
  id: "13d17498-e2f7-4e5d-b61e-7e1b9114a1b5",
  representableMixSoundcloudTrackId: null
};

export const krtm: SerializedArtist = {
  name: "KRTM",
  id: "4809a7ba-c2a7-4306-b160-9583519ab849",
  representableMixSoundcloudTrackId: 468547773
};

export const geinst: SerializedArtist = {
  name: "Gëinst",
  id: "82226821-7402-4de7-8c16-3b94367bb50c",
  representableMixSoundcloudTrackId: null
};

export const linnElisabet: SerializedArtist = {
  name: "Linn Elisabet",
  id: "579984d9-c9d0-48d4-93d7-b632f2c07ae7",
  representableMixSoundcloudTrackId: 488646846
};

export const charlton: SerializedArtist = {
  name: "Charlton",
  id: "e2dd41fe-49f6-4c3d-872a-2c3367d07208",
  representableMixSoundcloudTrackId: 494733147
};

export const xiorro: SerializedArtist = {
  name: "Xiorro",
  id: "cede4b8f-c084-43a1-969b-6fbc9004beca",
  representableMixSoundcloudTrackId: 627776505
};

export const adrestia: SerializedArtist = {
  name: "Adrestia",
  id: "f93ac484-9b73-4c5f-b68b-6266c6206107",
  representableMixSoundcloudTrackId: 624175389
};

export const buzzi: SerializedArtist = {
  name: "Buzzi",
  id: "3ffc558f-0907-4166-91c1-9d73d3f46bdd",
  representableMixSoundcloudTrackId: 661857128
};

export const schwefelgelb: SerializedArtist = {
  name: "Schwefelgeld",
  id: "d4975413-1931-4f6b-ab26-384319241d51",
  representableMixSoundcloudTrackId: null
};

export const miranN: SerializedArtist = {
  name: "Miran N",
  id: "291fafb2-4538-43fd-833f-24335041249a",
  representableMixSoundcloudTrackId: 508994847
};

export const chami: SerializedArtist = {
  name: "Chami",
  id: "8c991a0c-2632-4c98-9664-0f1fc9fd3824",
  representableMixSoundcloudTrackId: 306423414
};

export const conradVanOrton: SerializedArtist = {
  name: "Conrad Van Orton",
  id: "a087b191-ddc2-4840-8183-dfa3e320804c",
  representableMixSoundcloudTrackId: null
};

export const ferdinandDreyssig: SerializedArtist = {
  name: "Ferdinand Dreyssig",
  id: "70648556-2c7f-4468-92ce-be8d46f18f75",
  representableMixSoundcloudTrackId: 444990279
};

export const analogue: SerializedArtist = {
  name: "Analogue",
  id: "9d0d624d-49a8-4824-953a-52d7c9cb5e85",
  representableMixSoundcloudTrackId: 613173993
};

export const alberty: SerializedArtist = {
  name: "Alberty",
  id: "9198eb8d-041b-4f8e-80fd-aee2cd9fbcb9",
  representableMixSoundcloudTrackId: 266404302
};

export const juliusHenze: SerializedArtist = {
  name: "Julius Henze",
  id: "8b9f412b-948c-41b1-b8f5-779ff68f2eef",
  representableMixSoundcloudTrackId: 382043459
};

export const dannySalas: SerializedArtist = {
  name: "Danny Salas",
  id: "f6ad3964-f6ff-4e3c-89df-9c9c6d0a9abd",
  representableMixSoundcloudTrackId: 471177009
};

export const jackJenson: SerializedArtist = {
  name: "Jack Jenson",
  id: "5fff610e-2bff-47b8-a385-be5bc4e47c71",
  representableMixSoundcloudTrackId: 624628659
};

export const swamThing: SerializedArtist = {
  name: "Swam:Thing",
  id: "985975b6-d5d7-4c54-98cb-2f740f1cea20",
  representableMixSoundcloudTrackId: 332480907
};

export const mothersBlacksheep: SerializedArtist = {
  name: "Mothers Blacksheep",
  id: "da08f277-32e1-4fca-9402-cf2ee2fef751",
  representableMixSoundcloudTrackId: null
};

export const bamela: SerializedArtist = {
  name: "Bämela",
  id: "0aeb4aa3-70c8-407a-993a-03741b6110e3",
  representableMixSoundcloudTrackId: null
};

export const johannesTon: SerializedArtist = {
  name: "Johannes Ton",
  id: "07a6bcb6-ecc6-4bd1-b560-7b8b97e193a2",
  representableMixSoundcloudTrackId: 468550200
};
export const esteble: SerializedArtist = {
  name: "esteble",
  id: "481468c7-2338-4a04-8b34-a976fbf1c299",
  representableMixSoundcloudTrackId: 662875916
};

export const laetizia: SerializedArtist = {
  name: "Laetizia",
  id: "8cfe5065-a6ba-461f-92f3-7340e76e78c3",
  representableMixSoundcloudTrackId: 645795351
};

export const midas104: SerializedArtist = {
  name: "Midas 104",
  id: "78fbda04-1fe2-4288-acd0-0afbcd1cafd6",
  representableMixSoundcloudTrackId: 272611680
};

export const maes: SerializedArtist = {
  name: "Maes",
  id: "90195631-2894-4d53-a8b2-e479b3c5f81f",
  representableMixSoundcloudTrackId: null
};

export const thorRixon: SerializedArtist = {
  name: "Thor Rixon",
  id: "222f299a-f7ec-4d83-858f-0268f14677ea",
  representableMixSoundcloudTrackId: 664600349
};

export const katka: SerializedArtist = {
  name: "Katka",
  id: "1dd7add4-93e4-4ffc-a1a1-b06fa66f74d9",
  representableMixSoundcloudTrackId: 647849487
};

export const niju: SerializedArtist = {
  name: "Niju",
  id: "8bafd6ff-d95d-46cb-bd3d-3325d651871a",
  representableMixSoundcloudTrackId: 646074483
};

export const amotik: SerializedArtist = {
  name: "Amotik",
  id: "119a4be7-9c04-43a4-b4ff-a180a9db60b2",
  representableMixSoundcloudTrackId: 588252339
};

export const and: SerializedArtist = {
  name: "AnD",
  id: "95193ae7-889d-43b1-8247-65be883e1d01",
  representableMixSoundcloudTrackId: 552989925
};

export const boston168: SerializedArtist = {
  name: "Boston 168",
  id: "a527099e-77ec-4710-81f9-cf130138a1a6",
  representableMixSoundcloudTrackId: 291057667
};

export const developer: SerializedArtist = {
  name: "Developer",
  id: "c450193c-e567-4a82-b29f-ecb0e24bee19",
  representableMixSoundcloudTrackId: 677116506
};

export const extrawelt: SerializedArtist = {
  id: "07ae362f-d457-4311-8449-f9debb775a42",
  name: "Extrawelt",
  representableMixSoundcloudTrackId: 56652174
};

export const thylacine: SerializedArtist = {
  id: "60d136ed-53b6-42fe-ab36-ddbb8ea6a015",
  name: "Thylacine",
  representableMixSoundcloudTrackId: 262444705
};

export const einmusik: SerializedArtist = {
  id: "5b46f6d7-fb59-4a6d-995b-027fe9aedc8a",
  name: "Einmusik",
  representableMixSoundcloudTrackId: 467233557
};

export const formatB: SerializedArtist = {
  id: "5ad2da0c-d78e-4463-93a2-c4adec363e77",
  name: "Format:B",
  representableMixSoundcloudTrackId: 541223352
};
export const mathiasKaden: SerializedArtist = {
  id: "023c5720-55dd-41a8-aef2-34acee6aff34",
  name: "Mathias Kaden",
  representableMixSoundcloudTrackId: 267270914
};
export const oliverSchories: SerializedArtist = {
  id: "f37e0f33-d6a3-4ddc-9ef5-592cb72f396e",
  name: "Olivier Schories",
  representableMixSoundcloudTrackId: 686458576
};
export const nicone: SerializedArtist = {
  id: "2029d92c-4266-4950-b32d-faa2d2030735",
  name: "Niconé",
  representableMixSoundcloudTrackId: 648618749
};
export const townshipRebellion: SerializedArtist = {
  id: "9c4b0d18-0a7b-4435-b5d8-00c88872ed82",
  name: "Township Rebellion",
  representableMixSoundcloudTrackId: 407576970
};
export const reYou: SerializedArtist = {
  id: "b9c6c3ee-a2f7-4afe-9cd6-212bf4ae28e7",
  name: "Re.You",
  representableMixSoundcloudTrackId: 156984469
};
export const animalTrainer: SerializedArtist = {
  id: "df09ae1b-c0ba-422d-b141-7c675bca21b9",
  name: "Animal Trainer",
  representableMixSoundcloudTrackId: 665116457
};
export const djT: SerializedArtist = {
  id: "9c7711a0-c30b-4e70-90c8-fc80a852051c",
  name: "DJ T.",
  representableMixSoundcloudTrackId: 668132333
};

export const muratUncuoglu: SerializedArtist = {
  id: "ba778735-4cdc-4216-81f9-08566bc13090",
  name: "Murat Uncuoglu",
  representableMixSoundcloudTrackId: 383633969
};

export const prismode: SerializedArtist = {
  id: "ffba5f40-7fc5-47f2-82da-79548eb87fdd",
  name: "Prismode",
  representableMixSoundcloudTrackId: 332726675
};
export const solvane: SerializedArtist = {
  id: "1166df01-e7dc-419b-a7bc-99dc7b17d0f6",
  name: "Solvane",
  representableMixSoundcloudTrackId: 332726675
};

export const normanWeber: SerializedArtist = {
  id: "4fa1599f-b436-4015-93cb-c72d0d8b51cf",
  name: "Norman Weber",
  representableMixSoundcloudTrackId: 586293555
};
export const matthiasSchuell: SerializedArtist = {
  id: "39e34b23-ddf1-4c91-922c-a9c7674d5d71",
  name: "Matthias Schuell",
  representableMixSoundcloudTrackId: 645160983
};
export const timAndresen: SerializedArtist = {
  id: "6cf8ee82-28c8-43cc-a4b9-721a5b423268",
  name: "Tim Andresen",
  representableMixSoundcloudTrackId: 655802741
};
export const manuelMoreno: SerializedArtist = {
  id: "b4f0b3f5-3efe-4ff9-9326-c582c7ad8a16",
  name: "Manuel Moreno",
  representableMixSoundcloudTrackId: 623728419
};
export const hrrsn: SerializedArtist = {
  id: "2166cfbe-a7e5-4af8-b1c7-a258da719220",
  name: "HRRSN",
  representableMixSoundcloudTrackId: 636211521
};
export const fejka: SerializedArtist = {
  id: "46c14acf-c453-4129-9923-7d5be6508d35",
  name: "Fejka",
  representableMixSoundcloudTrackId: 681072719
};

const artists: SerializedArtist[] = [
  anetha,
  ninexnine,
  alignment,
  karenn,
  lenFaki,
  actress,
  anthro,
  djRichard,
  ignota,
  ikaDuchna,
  imogen,
  konkurs,
  lakker,
  lawrence,
  louisahhh,
  sisselWincent,
  snts,
  tini,
  wallis,
  iHateModels,
  emmanuel,
  krtm,
  geinst,
  linnElisabet,
  charlton,
  xiorro,
  adrestia,
  buzzi,
  schwefelgelb,
  miranN,
  chami,
  conradVanOrton,
  ferdinandDreyssig,
  analogue,
  alberty,
  juliusHenze,
  dannySalas,
  jackJenson,
  swamThing,
  mothersBlacksheep,
  bamela,
  johannesTon,
  esteble,
  laetizia,
  midas104,
  maes,
  thorRixon,
  katka,
  niju,
  amotik,
  and,
  boston168,
  developer,
  extrawelt,
  thylacine,
  einmusik,
  formatB,
  mathiasKaden,
  oliverSchories,
  nicone,
  townshipRebellion,
  reYou,
  animalTrainer,
  djT,
  muratUncuoglu,
  prismode,
  solvane,
  normanWeber,
  matthiasSchuell,
  timAndresen,
  manuelMoreno,
  hrrsn,
  fejka,
  {
    id: "a0cc6183-5d1c-4f85-a86d-2b7146a91d9c",
    name: "Annett Gapstream",
    representableMixSoundcloudTrackId: 669459653
  },
  {
    id: "42bbc391-bdc1-4bcc-aed2-a93005d098b5",
    name: "Markus Klee",
    representableMixSoundcloudTrackId: 626498901
  },
  {
    id: "5bec87f0-bcb9-490c-814f-95bc2a09f242",
    name: "Marius Drescher",
    representableMixSoundcloudTrackId: 481418538
  },
  {
    id: "b3d1c214-2f50-4d01-aafc-029252b29ae4",
    name: "Felidae",
    representableMixSoundcloudTrackId: 300913083
  },
  {
    id: "af4e3798-6f59-4c7f-9d33-ad398f102462",
    name: "Maik Gyver",
    representableMixSoundcloudTrackId: 353424806
  },
  {
    id: "f2970155-d00a-4457-bb15-b9d940210cde",
    name: "Jannicke Reberg",
    representableMixSoundcloudTrackId: null
  },
  {
    id: "631c3de9-9f74-422d-bba7-5c100af3d547",
    name: "Maurice Mino",
    representableMixSoundcloudTrackId: 588355971
  },
  {
    id: "881151e2-937b-468c-a676-c22e65614acd",
    name: "Sin:port",
    representableMixSoundcloudTrackId: 588355971
  },
  {
    id: "09b6bb4e-3e2c-4e97-9580-e9a1f8daccaa",
    name: "Soul Bait",
    representableMixSoundcloudTrackId: 677165232
  },
  {
    id: "097b0b30-92db-4aa7-be4b-f3ffc82184e4",
    name: "IKAARUS",
    representableMixSoundcloudTrackId: 553700142
  },
  {
    id: "2ae72442-c24e-40a9-961a-5ca7b3b7d357",
    name: "Rrose",
    representableMixSoundcloudTrackId: 575177010
  },
  {
    id: "77ede2ee-69b4-4693-a74f-0efd393ffac8",
    name: "Aurora Halal",
    representableMixSoundcloudTrackId: 637818162
  },
  {
    id: "81c0000b-8b43-4c8d-ac0a-abab9a234921",
    name: "Cio D'Or",
    representableMixSoundcloudTrackId: 208775338
  },
  {
    id: "8bbe580a-5664-400e-8629-6e02dbcf3f2e",
    name: "Drumcell",
    representableMixSoundcloudTrackId: 192039401
  },
  {
    id: "369fb31a-a3e5-4ea0-a741-320d3f93fe15",
    name: "Etapp Kyle",
    representableMixSoundcloudTrackId: 433998129
  },
  {
    id: "14f4981c-cbc9-4776-b557-d7588468268a",
    name: "Haruka",
    representableMixSoundcloudTrackId: 594709080
  },
  {
    id: "f286ef47-29d4-48bf-8b84-4a2a6a31d344",
    name: "Kobosil",
    representableMixSoundcloudTrackId: 586270560
  },
  {
    id: "742d045e-b0e2-4d5c-a5ba-b2d551b1fcd9",
    name: "Rødhåd",
    representableMixSoundcloudTrackId: 234958577
  },
  {
    id: "e7561b15-bd10-4478-b3ee-712929df5eb5",
    name: "Terence Fixmer",
    representableMixSoundcloudTrackId: 123688723
  },
  {
    id: "acc49e74-eebb-4437-9ebe-359529de4e42",
    name: "Cosmin TRG",
    representableMixSoundcloudTrackId: 420106892
  },
  {
    id: "3ea8b85e-5134-4dfc-963f-8c810e993201",
    name: "Hannah Holland",
    representableMixSoundcloudTrackId: 344391225
  },
  {
    id: "0bc4c058-0385-4446-9e44-bfce643a978d",
    name: "Justin Cudmore",
    representableMixSoundcloudTrackId: 685035976
  },
  {
    id: "d3e4ee59-5abc-44b5-a01b-b7ac368f8258",
    name: "Midland",
    representableMixSoundcloudTrackId: 645121908
  },
  {
    id: "454760a5-9d96-4c7b-8660-dd2d26452f32",
    name: "Mike Servito",
    representableMixSoundcloudTrackId: 620661186
  },
  {
    id: "678fce06-8e4d-46e4-a9b3-eb1ff04a3638",
    name: "Or:la",
    representableMixSoundcloudTrackId: 329016461
  },
  {
    id: "2a0a72a4-956f-4d5b-a1a0-a45696adb40a",
    name: "The Carry Nation",
    representableMixSoundcloudTrackId: 281075544
  },
  {
    id: "3122d784-482e-461a-82c8-c5f9add66612",
    name: "Virginia",
    representableMixSoundcloudTrackId: 522098655
  },
  {
    id: "d8edac37-8e4d-4f38-9121-882a42254b01",
    name: "Lone",
    representableMixSoundcloudTrackId: 279350104
  },
  {
    id: "20be83b5-3da6-47e0-bff8-ca22ef4bec65",
    name: "Kamma",
    representableMixSoundcloudTrackId: 470203671
  },
  {
    id: "f6097f49-4fae-4b4c-9312-1abc5f6253ae",
    name: "Masalo",
    representableMixSoundcloudTrackId: 594013707
  },
  {
    id: "8fe0bb6f-415e-4a3d-adee-214555183ff5",
    name: "Ambien Baby",
    representableMixSoundcloudTrackId: 328952881
  },
  {
    id: "c5d51f53-5684-4b21-9a8f-e00049c77296",
    name: "Toni Yotzi",
    representableMixSoundcloudTrackId: 593619462
  },
  {
    id: "5b3bc6f7-6cda-4e87-9e2f-d753c8a41ad5",
    name: "Marlon Hoffstadt",
    representableMixSoundcloudTrackId: 529691163
  },
  {
    id: "be4568f0-47cb-47e2-98d0-e68e92a7d117",
    name: "Meggy",
    representableMixSoundcloudTrackId: 604869663
  },
  {
    id: "0914d744-4d4a-4818-a2ad-084e455c6028",
    name: "Shawn Reynaldo",
    representableMixSoundcloudTrackId: 81409156
  },
  {
    id: "a2652757-772e-43c1-b8b5-4a042dfeac11",
    name: "Philipp Shultheis",
    representableMixSoundcloudTrackId: 553059510
  },
  {
    id: "40d3f76a-3041-4b5f-b1b3-91df3ed734c0",
    name: "Double A Battery Team",
    representableMixSoundcloudTrackId: 650573741
  },
  {
    id: "d526a59f-0682-4c7e-89c9-fbf845fd808a",
    name: "Antigone",
    representableMixSoundcloudTrackId: 376542923
  },
  {
    id: "761c9b20-8cd5-4e61-83f0-d0044ced9365",
    name: "Femanyst",
    representableMixSoundcloudTrackId: 670132592
  },
  {
    id: "111e260b-1e7c-484c-a4e1-06d06e2a0248",
    name: "IDA",
    representableMixSoundcloudTrackId: 615978651
  },
  {
    id: "0d83500e-14f8-4d77-93d7-19d9cd1b4bd5",
    name: "Giordano",
    representableMixSoundcloudTrackId: 685420444
  },
  {
    id: "52ca5c9b-422c-4c37-8e61-9be0aba2f629",
    name: "Herva",
    representableMixSoundcloudTrackId: 656703329
  },
  {
    id: "a5aec2d4-4542-49c3-aa7d-4dc31f635a68",
    name: "Lawrence Kurt",
    representableMixSoundcloudTrackId: 312511924
  },
  {
    id: "8a52429e-7442-4c16-83f1-9e5f6397326b",
    name: "Monovsn",
    representableMixSoundcloudTrackId: 397751502
  },
  {
    id: "29bbe435-cf99-4af3-8fb7-d4c5ae2d71f8",
    name: "UNTDX",
    representableMixSoundcloudTrackId: 512742048
  },
  {
    id: "4ebfeaca-b23d-405f-9da8-dd699f4930b3",
    name: "Ancient Methods",
    representableMixSoundcloudTrackId: 240662593
  },
  {
    id: "0ed95dbc-b092-444b-937f-fc0e2f591094",
    name: "Regal",
    representableMixSoundcloudTrackId: 588972948
  },
  {
    id: "3c57e9ba-7b9c-4fb0-90a4-beb81199b016",
    name: "Vulkanski",
    representableMixSoundcloudTrackId: 664557242
  },
  {
    id: "ff9989a9-f634-4873-b5b9-a74283d716b9",
    name: "Ròman",
    representableMixSoundcloudTrackId: 460458885
  },
  {
    id: "36c00a7d-28b2-435b-8b31-c9bb4886479f",
    name: "Liza Rivs",
    representableMixSoundcloudTrackId: 320962731
  },
  {
    id: "886d9ad0-3cf2-42e3-bd43-ffd878985fe9",
    name: "Yanamaste",
    representableMixSoundcloudTrackId: 588973461
  },
  {
    id: "2aaf9261-eef5-45ce-97bf-74bc611cc77a",
    name: "Frequency Shifter",
    representableMixSoundcloudTrackId: 588973089
  },
  {
    id: "d0546141-575c-4ad4-a714-53f177d2149b",
    name: "DJ Plead",
    representableMixSoundcloudTrackId: 672841367
  },
  {
    id: "2d4e1838-8407-4b2f-b58f-da2798000f06",
    name: "Philip Jondo",
    representableMixSoundcloudTrackId: 644642856
  },
  {
    id: "ed4a77be-e2dd-419a-9171-f76d31a7e1ad",
    name: "DJ Brom",
    representableMixSoundcloudTrackId: 529700898
  },
  {
    id: "6f671fd2-649c-47e4-9430-7f7e5f67bfea",
    name: "41ISSA",
    representableMixSoundcloudTrackId: 476029371
  },
  {
    id: "6184d14b-06bc-46d7-ad28-ca280022a206",
    name: "Tyree Cooper",
    representableMixSoundcloudTrackId: 87436214
  },
  {
    id: "7ae6e6c5-cec7-4e30-931c-1c3102df1f99",
    name: "Sevda",
    representableMixSoundcloudTrackId: null
  },
  {
    id: "7a618213-65f5-46c0-a984-da1fd4222c40",
    name: "Autumn Tree",
    representableMixSoundcloudTrackId: 680152868
  },
  {
    id: "90a88293-29c7-4b9f-b3bc-58555abb3450",
    name: "Forsberg",
    representableMixSoundcloudTrackId: 271012840
  },
  {
    id: "fccd979b-f128-4664-8edc-6851eec4e9c6",
    name: "Pelada",
    representableMixSoundcloudTrackId: null
  },
  {
    id: "2cb8a6fd-e21c-4da7-815f-fe637caae938",
    name: "Amnesia Scanner",
    representableMixSoundcloudTrackId: 305745518
  },
  {
    id: "a2665118-5129-45fc-af84-7ea35ef5beb1",
    name: "Bill Kouligas",
    representableMixSoundcloudTrackId: 242534652
  },
  {
    id: "a54c62f9-dd95-4054-a00f-9cb9d243161d",
    name: "Crystallmess",
    representableMixSoundcloudTrackId: 652008908
  },
  {
    id: "cbd1787b-eaac-4433-9b01-4ab686dbcb51",
    name: "TOXE",
    representableMixSoundcloudTrackId: 647898309
  },
  {
    id: "7a6ea1a3-4ab6-488c-ab9a-ceecd2dceec9",
    name: "Objekt",
    representableMixSoundcloudTrackId: 160361470
  },
  {
    id: "dbbce746-1652-4fd1-93ff-c10b45a03e07",
    name: "Slikback",
    representableMixSoundcloudTrackId: 516703092
  },
  {
    id: "3f424711-1879-4a52-8536-6af343bbcd76",
    name: "Tzusing",
    representableMixSoundcloudTrackId: 265824283
  },
  {
    id: "4d2f94c1-1504-46ec-9c85-59c9217d8997",
    name: "Legowelt",
    representableMixSoundcloudTrackId: 694598572
  },
  {
    id: "905894e9-fbab-4d02-9cff-4fa242cf2372",
    name: "Alessandro Adriani",
    representableMixSoundcloudTrackId: 450677856
  },
  {
    id: "90e23b70-32be-4927-a8ae-4a85cddec0d7",
    name: "Phase Fatale",
    representableMixSoundcloudTrackId: 346026504
  },
  {
    id: "01f0f6f9-9b09-42ee-8675-dda23f7a18d0",
    name: "Identified Patient",
    representableMixSoundcloudTrackId: 497811147
  },
  {
    id: "d9b50b75-8046-404d-a1d5-4cbdaaad7a9c",
    name: "JASSS",
    representableMixSoundcloudTrackId: 472409172
  },
  {
    id: "cb42cbd6-6924-453a-9eab-7dbb1f53d473",
    name: "Dusty Kid",
    representableMixSoundcloudTrackId: null
  },
  {
    id: "0db38648-8983-4428-86a6-bb25a2d71ac4",
    name: "2000 and One",
    representableMixSoundcloudTrackId: 682486016
  },
  {
    id: "f63aa644-e7b4-41e9-bb69-c8bf60165a0d",
    name: "Flug",
    representableMixSoundcloudTrackId: 376529768
  },
  {
    id: "376f3f91-fda1-43de-a7b1-8f936d25a344",
    name: "Sutter Cane",
    representableMixSoundcloudTrackId: 542052183
  },
  {
    id: "af7e43e9-1197-4029-9a69-3020517fca4f",
    name: "Marc DePulse",
    representableMixSoundcloudTrackId: 661946294
  },
  {
    id: "25153d74-c047-4c5e-b593-da7fb485963e",
    name: "Boy Next Door",
    representableMixSoundcloudTrackId: 668095862
  },
  {
    id: "2c68bef6-1f77-453f-84c9-b9824b7c076e",
    name: "Frida Darko",
    representableMixSoundcloudTrackId: 646581885
  },
  {
    id: "64f63e56-1e15-4bb8-8398-757caac6b79b",
    name: "Söriös/Q.rios",
    representableMixSoundcloudTrackId: 544838226
  },
  {
    id: "da5512e4-a1c9-43d5-8e09-faf82e7e4286",
    name: "Anton Johnsen",
    representableMixSoundcloudTrackId: 462709743
  },
  {
    id: "2b3b10b0-afd2-4a8f-89bf-8e40d52539d6",
    name: "PATH LIVE",
    representableMixSoundcloudTrackId: null
  },
  {
    id: "84263300-6024-437a-b7ae-8d938da02f02",
    name: "Lukas Meunier",
    representableMixSoundcloudTrackId: 584239071
  },
  {
    id: "e1110d4d-c502-4039-a522-eea07a74f695",
    name: "Thomas Maschitzke",
    representableMixSoundcloudTrackId: 584244567
  },
  {
    id: "d27f617a-49b0-40f8-8dd3-6841262d501b",
    name: "HÄNS & BÄMS",
    representableMixSoundcloudTrackId: null
  },
  {
    id: "5fa74ff9-3306-40d5-a630-2fe2225ce1eb",
    name: "The Allegorist",
    representableMixSoundcloudTrackId: 631729743
  },
  {
    id: "10c7f6c4-fbd5-48a5-aaba-cf9a67752e96",
    name: "Amperia",
    representableMixSoundcloudTrackId: 614559876
  },
  {
    id: "fb802988-adf3-4899-b118-aa1196a59890",
    name: "Black Anthem Restore",
    representableMixSoundcloudTrackId: 459194406
  },
  {
    id: "c1832f1c-6633-44da-963d-2d84d02c9a85",
    name: "Isa Rude",
    representableMixSoundcloudTrackId: 573125406
  },
  {
    id: "54dcbb36-3092-48be-9297-5b9576831bb2",
    name: "Katy De Jesus",
    representableMixSoundcloudTrackId: 443812368
  },
  {
    id: "58bddd67-6e0e-4947-84ec-2ad728cc312a",
    name: "Nastya Muravyova",
    representableMixSoundcloudTrackId: 332660852
  },
  {
    id: "95fbcbf5-4acc-4533-aee2-832440066371",
    name: "Nightwave",
    representableMixSoundcloudTrackId: 488095059
  },
  {
    id: "18027e6b-33b0-4a51-8f66-b3409977e82a",
    name: "Silvia Kastel",
    representableMixSoundcloudTrackId: 423456954
  },
  {
    id: "94494b02-d09e-485f-904b-dcfd503f845b",
    name: "Colin Benders",
    representableMixSoundcloudTrackId: 296505502
  },
  {
    id: "00bb13a9-bad8-49ac-9942-a632c3d25a60",
    name: "Answer Code Request",
    representableMixSoundcloudTrackId: 131062731
  },
  {
    id: "0739af34-871a-4f47-ab59-420439389e34",
    name: "Boris",
    representableMixSoundcloudTrackId: 688446415
  },
  {
    id: "143097e5-56ad-461b-8f54-a0af2a3be15c",
    name: "Fiedel",
    representableMixSoundcloudTrackId: 389332356
  },
  {
    id: "b9ad3eb3-59e7-45c8-ab67-bd57ec1b405e",
    name: "Len Faki",
    representableMixSoundcloudTrackId: 149350075
  },
  {
    id: "4d4099cc-28dd-4744-9bf3-68bfe823a52b",
    name: "Matrixxman",
    representableMixSoundcloudTrackId: 584547852
  },
  {
    id: "3a353322-3987-4cd5-9664-b9b2ab11930c",
    name: "Noncompliant",
    representableMixSoundcloudTrackId: 343925063
  },
  {
    id: "81786516-4b7d-44ad-b6f8-e8fae61d8b02",
    name: "Silent Servant",
    representableMixSoundcloudTrackId: null
  },
  {
    id: "1e4be740-6fee-4f49-999c-8e5546b79d2c",
    name: "Somewhen",
    representableMixSoundcloudTrackId: 535618809
  },
  {
    id: "cf638f6d-c922-404a-b803-4b4da67c3dab",
    name: "Portable",
    representableMixSoundcloudTrackId: null
  },
  {
    id: "a03ec402-373a-4816-ac0e-3a39668ef185",
    name: "Carl Craig",
    representableMixSoundcloudTrackId: 181971695
  },
  {
    id: "8180a848-0c06-457d-b448-225afe241b7c",
    name: "DJ Holographic",
    representableMixSoundcloudTrackId: 370140848
  },
  {
    id: "c0ec0795-1ffd-4da5-acbd-8caa24fac0a7",
    name: "Gerd Janson",
    representableMixSoundcloudTrackId: 409916274
  },
  {
    id: "0202e968-e722-46f2-b834-a8d3ce6de010",
    name: "Monty Luke",
    representableMixSoundcloudTrackId: 144303605
  },
  {
    id: "b2efbd46-0354-4987-848b-b565dbc37e3a",
    name: "Ryan Elliott",
    representableMixSoundcloudTrackId: 162034784
  },
  {
    id: "a76ad61d-1c6b-412d-b0e7-a189908fd436",
    name: "Tama Sumo",
    representableMixSoundcloudTrackId: 660985004
  },
  {
    id: "5f9ca509-3329-428a-bbe3-e9583fb1c086",
    name: "Waajeed",
    representableMixSoundcloudTrackId: 319261534
  },
  {
    id: "add62ea8-4b9b-4854-a7ed-1f65928aa365",
    name: "Zernell",
    representableMixSoundcloudTrackId: 617517963
  },
  {
    id: "30b3faac-5e47-4df0-9bd5-e25e4ed8c139",
    name: "ANOTR",
    representableMixSoundcloudTrackId: 583263921
  },
  {
    id: "5fdc071e-ea3c-4e60-8cf7-15c0c07da722",
    name: "Fatima Hajji",
    representableMixSoundcloudTrackId: 646583301
  },
  {
    id: "ebcc62d5-92b6-4891-92bf-b933d0084d21",
    name: "Karim Soliman",
    representableMixSoundcloudTrackId: 664613333
  },
  {
    id: "45cd2230-db15-4ede-81cc-71d04acc9529",
    name: "KlangKuenstler",
    representableMixSoundcloudTrackId: 562454847
  },
  {
    id: "cf14e5a6-dcf3-4399-b60d-6c3403209caa",
    name: "Luuk van Dijk",
    representableMixSoundcloudTrackId: 650025587
  },
  {
    id: "ee6df5d9-7fed-4f5d-9df7-3c9bcf637528",
    name: "SHDW",
    representableMixSoundcloudTrackId: 711130180
  },
  {
    id: "2c1402e3-897c-4120-9bbd-67e56b7a25a7",
    name: "Obscure Shape",
    representableMixSoundcloudTrackId: 518671680
  },
  {
    id: "04f0b5db-55c0-405b-9b89-3ad7721edae1",
    name: "Prunk",
    representableMixSoundcloudTrackId: 669363101
  },
  {
    id: "089ca7eb-2728-496d-9e61-543bc86ceb57",
    name: "Chris Stussy",
    representableMixSoundcloudTrackId: 553481061
  },
  {
    id: "96a2632c-7ab4-4c35-aa65-5addcc436c90",
    name: "Wouter S",
    representableMixSoundcloudTrackId: 489441249
  },
  {
    id: "049a3cce-c7c3-4c98-b1b3-be148f540766",
    name: "Boris Werner",
    representableMixSoundcloudTrackId: 189167072
  },
  {
    id: "045dfc9c-02b7-41b9-91d8-b93c7bc73064",
    name: "Julien Simmons",
    representableMixSoundcloudTrackId: 544895733
  },
  {
    id: "f0d4aeea-7db6-4e88-9477-ade9f365d937",
    name: "Bandi",
    representableMixSoundcloudTrackId: 533563950
  },
  {
    id: "a462cf3f-f5ea-4c34-9021-3657261c3ecd",
    name: "Carista",
    representableMixSoundcloudTrackId: 497859870
  },
  {
    id: "847bc832-fbcc-4d06-8fb5-8cb5a00190f1",
    name: "Philou Louzolo",
    representableMixSoundcloudTrackId: 662856413
  },
  {
    id: "cb1b516b-848e-4ae7-8b49-25cec8be99e6",
    name: "Ferro",
    representableMixSoundcloudTrackId: 348913656
  },
  {
    id: "91892426-dfd4-46eb-8a11-24835ee58e15",
    name: "Jasper Wolff",
    representableMixSoundcloudTrackId: 672106193
  },
  {
    id: "87149f14-636a-40c9-83d9-43503e0959e0",
    name: "Pablo Discobar Soundsystem",
    representableMixSoundcloudTrackId: 311705360
  },
  {
    id: "6ad1fe84-2fec-4d83-a5fa-30672e5cb606",
    name: "Sanja",
    representableMixSoundcloudTrackId: 593991321
  },
  {
    id: "426e97b9-aed1-4983-8f54-0a794a29e2c6",
    name: "Randomer",
    representableMixSoundcloudTrackId: 497855919
  },
  {
    id: "4ea6afaa-9d94-4324-afe8-644276a920d9",
    name: "Parrish Smith",
    representableMixSoundcloudTrackId: 591106953
  },
  {
    id: "3cf4c6ad-a492-45ef-9590-1b3dcdd55dad",
    name: "Abstract Division",
    representableMixSoundcloudTrackId: 620793618
  },
  {
    id: "132ef321-909e-4c78-af58-41536d6e69ab",
    name: "TITIA",
    representableMixSoundcloudTrackId: 686469235
  },
  {
    id: "e9e5e4e8-fc40-4376-8dbe-b4dd7922b2db",
    name: "Tenzers",
    representableMixSoundcloudTrackId: 472984638
  },
  {
    id: "bbbfc91d-9368-4de9-8f7d-4630601be55b",
    name: "Orpheu The Wizard",
    representableMixSoundcloudTrackId: 696153091
  },
  {
    id: "074c14f8-6605-4567-942d-85da54141429",
    name: "Festimi Soundsystem",
    representableMixSoundcloudTrackId: 624678036
  },
  {
    id: "56487d96-124d-47e3-97bd-892e43dc1ea8",
    name: "Perdu",
    representableMixSoundcloudTrackId: 536166000
  },
  {
    id: "70f8b655-a73f-431b-9403-caf883388ead",
    name: "Tim Zwerver",
    representableMixSoundcloudTrackId: 625491777
  },
  {
    id: "5be3db2a-a626-415a-a4fa-5298fa4912de",
    name: "Kreutziger",
    representableMixSoundcloudTrackId: 678552447
  },
  {
    id: "1e2e1c32-95b9-46ef-a23c-bd9d3cc0bd28",
    name: "Herra",
    representableMixSoundcloudTrackId: 346716878
  },
  {
    id: "439a6037-9229-42a4-84b8-698cef8f1e5e",
    name: "Alinéa",
    representableMixSoundcloudTrackId: 646192653
  },
  {
    id: "1bf9fa0e-6800-46fb-ab28-6bae60da19b3",
    name: "Wesley Roberts",
    representableMixSoundcloudTrackId: 544721622
  },
  {
    id: "6f9617d5-2e40-4b20-8630-41c0aa0e9041",
    name: "Retromigration",
    representableMixSoundcloudTrackId: 519042786
  },
  {
    id: "d1eb283b-6cd5-448d-8434-dacc80ec9698",
    name: "Jackmaster",
    representableMixSoundcloudTrackId: 362923778
  },
  {
    id: "da63330a-13c8-4543-93fb-784d70183581",
    name: "William Djoko",
    representableMixSoundcloudTrackId: 230297756
  },
  {
    id: "0ad54702-d349-432e-8900-287cd3820e87",
    name: "Carlos Valdes",
    representableMixSoundcloudTrackId: 321819992
  },
  {
    id: "68ae9918-0477-47c3-a7d2-248aba30181e",
    name: "Alinka",
    representableMixSoundcloudTrackId: 543790119
  },
  {
    id: "7102643a-3999-455a-93ac-ea66a44ea771",
    name: "VNTM",
    representableMixSoundcloudTrackId: 671196158
  },
  {
    id: "056f4f14-211f-40a8-a4ed-fefa0675ff25",
    name: "Deniro",
    representableMixSoundcloudTrackId: 696153956
  },
  {
    id: "df224902-f774-4e0f-9009-53b7c75ec496",
    name: "FJAAK",
    representableMixSoundcloudTrackId: 635992518
  },
  {
    id: "c701effe-d024-4f1a-98b0-b5b39aa5cbca",
    name: "Maceo Plex",
    representableMixSoundcloudTrackId: 170333118
  },
  {
    id: "f25f88d1-2068-40d2-9953-bc962b4280e9",
    name: "Pan-Pot",
    representableMixSoundcloudTrackId: 212652437
  },
  {
    id: "16d1779b-598a-4c05-ac83-869778228140",
    name: "Reinier Zonneveld",
    representableMixSoundcloudTrackId: 212629110
  }
];

export default artists;
