import React, { ReactNode, useState } from "react";
import styled from "styled-components";
import { defaultCopyFontFamily } from "../../styles/font_styles";
import Icon, { IconKey } from "../icons/Icon";

const ANIMATION_SPEED = "0.3s";

interface IProps {
  children: ReactNode;
  title: string;
}

interface ICollapsedProp {
  isCollapsed: boolean;
}

const Title = styled.span`
  font-family: ${defaultCopyFontFamily};
  font-size: 24px;
`;

const ClickableHeader = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
`;

const ArrowContainer = styled.div`
  transform: rotate(
    ${({ isCollapsed }: ICollapsedProp) => (isCollapsed ? "-90deg" : "0deg")}
  );
  transition: transform ${ANIMATION_SPEED} ease-out;
`;

const ChildrenWrapper = styled.div<ICollapsedProp>`
  padding-top: 20px;
  max-height: ${({ isCollapsed }) => (isCollapsed ? "0px" : "1337px")};
  overflow: hidden;
  transition: max-height ${ANIMATION_SPEED} ease-in-out;
`;

const CollapsableWrapperArrowDown = styled(Icon)`
  && {
    height: 24px;
    width: 24px;
  }
`;

export default function CollapsableWrapper({ children, title }: IProps) {
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <div>
      <ClickableHeader onClick={() => setIsCollapsed(!isCollapsed)}>
        <ArrowContainer isCollapsed={isCollapsed}>
          <CollapsableWrapperArrowDown iconKey={IconKey.ArrowDown} />
        </ArrowContainer>
        <Title>{title}</Title>
      </ClickableHeader>
      <ChildrenWrapper isCollapsed={isCollapsed}>{children}</ChildrenWrapper>
    </div>
  );
}
