import React, { useState } from "react";
import moment from "moment";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import scrapeResidentAdvisorDay from "../../../api/resident_advisor_scraper/scrapeResidentAdvisorDay";
import scrapeResidentAdvisorEvent from "../../../api/resident_advisor_scraper/scrapeResidentAdvisorEvent";
import artists from "../../../static_db/artists";
import clubs from "../../../static_db/clubs";
import SerializedEvent from "../../../data_models/Event/SerializedEvent";
import SerializedArtist from "../../../data_models/Artist/SerializedArtist";
import ErrorComponent from "../../ErrorComponent/ErrorComponent";
import copyToClipboard from "../../copyToClipboard";
import SerializedCity from "../../../data_models/City/SerializedCity";
import cities from "../../../static_db/cities";
import findByIdOrThrow from "../../../utils/findByIdOrThrow";

export default function ResidentAdvisorScraperView() {
  const [pickedDate, setPickedDate] = useState<string>(moment().format());
  const [pickedCity, setPickedCity] = useState<SerializedCity>(cities[0]);
  const [isScraping, setIsScraping] = useState(false);
  const [scrapeError, setScrapeError] = useState<Error | null>(null);
  const [newEvents, setNewEvents] = useState<SerializedEvent[]>([]);
  const [newArtists, setNewArtists] = useState<SerializedArtist[]>([]);

  const runScrape = async () => {
    setIsScraping(true);
    setScrapeError(null);
    setNewEvents([]);
    setNewArtists([]);

    const residentAdvisorEventIds = await scrapeResidentAdvisorDay(
      pickedCity.nameKey,
      pickedCity.countryKey,
      moment(pickedDate)
    );
    const eventsWithNewArtistsWithNulledEvents = await Promise.all(
      residentAdvisorEventIds.map(eventId =>
        scrapeResidentAdvisorEvent(eventId, artists, clubs)
      )
    );

    const eventsWithNewArtists = eventsWithNewArtistsWithNulledEvents.filter(
      (
        element
      ): element is {
        event: SerializedEvent;
        newArtists: SerializedArtist[];
      } => element !== null
    );

    const { newEvents, allNewArtists } = eventsWithNewArtists.reduce<{
      newEvents: SerializedEvent[];
      allNewArtists: SerializedArtist[];
    }>(
      (
        { newEvents, allNewArtists },
        { event, newArtists: newArtistsFromEvent }
      ) => ({
        newEvents: [...newEvents, event],
        allNewArtists: [...allNewArtists, ...newArtistsFromEvent]
      }),
      {
        newEvents: [],
        allNewArtists: []
      }
    );

    setNewArtists(allNewArtists);
    setNewEvents(newEvents);
    setIsScraping(false);
  };

  const onGoScrapeClickHandler = () => {
    runScrape().catch(e => {
      setScrapeError(e);
      setIsScraping(false);
    });
  };

  const onNewCitySelectedById = (cityId: string) => {
    const newPickedCity = findByIdOrThrow(cities, cityId);

    setPickedCity(newPickedCity);
  };

  const newEventsJSONString = JSON.stringify(newEvents, null, 1);
  const newArtistsJSONString = JSON.stringify(newArtists, null, 1);

  // Removes the initial character '[' and last character ']' to only extract the object which can be pasted into an array
  const newEventsObjectsOnlyString = newEventsJSONString.slice(
    1,
    newEventsJSONString.length - 1
  );
  const newArtistsObjectsOnlyString = newArtistsJSONString.slice(
    1,
    newArtistsJSONString.length - 1
  );

  return (
    <div>
      <p>City:</p>
      <select
        value={pickedCity.id}
        onChange={({ target: { value } }) => onNewCitySelectedById(value)}
      >
        {cities.map(city => (
          <option value={city.id}>{city.nameKey}</option>
        ))}
      </select>
      <p>Date:</p>
      <input
        type="date"
        value={pickedDate}
        onChange={e => setPickedDate(e.target.value)}
      />
      <button onClick={onGoScrapeClickHandler} disabled={isScraping}>
        Go Scrape!
      </button>

      {scrapeError && <ErrorComponent error={scrapeError} />}
      {isScraping && <LoadingSpinner />}

      {newEvents.length !== 0 && (
        <p>
          <button onClick={() => copyToClipboard(newEventsObjectsOnlyString)}>
            Copy new events JSON to clipboard
          </button>
          <button onClick={() => copyToClipboard(newArtistsObjectsOnlyString)}>
            Copy new artists JSON to clipboard
          </button>
        </p>
      )}
    </div>
  );
}
