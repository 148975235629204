import React from "react";
import IconButton from "./IconButton";
import { IconKey } from "../icons/Icon";

interface IProps {
  isPaused: boolean;
  isDisabled: boolean;
  onClick: () => void;
  className?: string;
  iconTheme?: "plain" | "circle";
  tooltip?: string;
}

const PlayPauseButton = ({
  isPaused,
  isDisabled,
  onClick,
  className,
  iconTheme,
  tooltip
}: IProps) => {
  const playIconKey =
    iconTheme === "plain" ? IconKey.PlayPlain : IconKey.PlayCircle;
  const pauseIconKey =
    iconTheme === "plain" ? IconKey.Pause : IconKey.PauseCircle;

  return (
    <IconButton
      iconKey={isPaused ? playIconKey : pauseIconKey}
      className={className}
      isDisabled={isDisabled}
      onClick={onClick}
      tooltip={tooltip}
    />
  );
};

export default PlayPauseButton;
