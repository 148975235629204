import { SET_ERROR } from "./error_actions";
import { AnyAction } from "redux";

export interface ReduxErrorState {
  error: Error | null;
}

const initialState: ReduxErrorState = {
  error: null
};

const errorReducer = (
  state = initialState,
  action: AnyAction
): ReduxErrorState => {
  switch (action.type) {
    case SET_ERROR:
      return {
        error: action.error
      };
  }

  return state;
};

export default errorReducer;
