export const objectByIdNotFoundInArray = (id: string) =>
  new Error(`Failed to find object with ID ${id} in array.`);

export const argumentOfTypeUndefined = (argumentName: string) =>
  new Error(`Argument "${argumentName}" is of unexpected type "undefined"`);

export const itemNotPlayable = () =>
  new Error(
    "You have requested to play something that is not playable currently"
  );

export const cantPerformActionNoActivePlayerState = () =>
  new Error("Can't perform action; there is no active player state.");

export const neverTypescriptCase = () =>
  new Error(`A typing case is reached that is assumed to never happen`);
