import * as React from "react";
import {
  CardStyler,
  CardMainTitle,
  CardSubTitle,
  CardBottomStyler,
  CardPlayButton,
  CardCopyStyler
} from "./VisualCard.styles";
import ClickableImage from "../../ClickableImage/ClickableImage";

interface IProps {
  imageUrl: string;
  onImageClick?: () => void;
  onMainTitleClick?: () => void;
  playButtonProps?: {
    showPauseButton: boolean;
    onPlayButtonClick: () => void;
    isDisabled?: boolean;
    tooltip?: string;
  };
  mainTitle: string;
  subTitle?: string;
  objectFit?: "cover" | "contain";
}

function VisualCard({
  imageUrl,
  onImageClick,
  onMainTitleClick,
  playButtonProps,
  mainTitle,
  subTitle,
  objectFit
}: IProps) {
  return (
    <CardStyler>
      <ClickableImage imageUrl={imageUrl} onClick={onImageClick} objectFit={objectFit}/>
      <CardBottomStyler>
        <CardCopyStyler>
          <CardMainTitle onClick={onMainTitleClick}>{mainTitle}</CardMainTitle>
          <CardSubTitle>{subTitle}</CardSubTitle>
        </CardCopyStyler>
        {playButtonProps && (
          <CardPlayButton
            isDisabled={
              playButtonProps.isDisabled !== undefined
                ? playButtonProps.isDisabled
                : false
            }
            onClick={playButtonProps.onPlayButtonClick}
            isPaused={!playButtonProps.showPauseButton}
            tooltip={playButtonProps.tooltip}
          />
        )}
      </CardBottomStyler>
    </CardStyler>
  );
}

export default VisualCard;
