import React, { ReactNode } from "react";
import styled from "styled-components";
import IconButton from "../buttons/IconButton";
import { IconKey } from "../icons/Icon";

const IconButtonStyler = styled(IconButton)`
  && {
    width: 24px;
    height: 24px;
    position: absolute;
    right: 5px;
    top: 5px;
  }
`;

interface IProps {
  children?: ReactNode;
  onCrossClick: () => void;
}

export default function ModalContentExitCrossStyler({
  children,
  onCrossClick
}: IProps) {
  return (
    <>
      <IconButtonStyler iconKey={IconKey.Cross} onClick={onCrossClick} />
      {children}
    </>
  );
}
