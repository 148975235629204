import React from "react";
import ServerErrorResponse from "../../data_models/ServerErrorResponse";
import P from "../Copy/P";

interface IProps {
  error: ServerErrorResponse | Error;
}

export default function ErrorComponent({ error: { message } }: IProps) {
  return (
    <div>
      <P>Oh no! An error occured!</P>
      <P>{message}</P>
    </div>
  );
}
