import React from "react";
import SvgWithDefaultStyler from "./SvgWithDefaultStyle";
import ClassNamePropType from "../../data_models/react_prop_types/ClassNamePropType";

export default function ExpandIcon({ className }: ClassNamePropType) {
  return (
    <SvgWithDefaultStyler
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
    >
      <switch>
        <foreignObject
          requiredExtensions="http://ns.adobe.com/AdobeIllustrator/10.0/"
          x="0"
          y="0"
          width="1"
          height="1"
        />
        <g>
          <g>
            <path d="M96,4c-1-1-2.3-1.5-3.7-1.5L71,3.2c-2.8,0.1-5,2.4-4.9,5.2c0,1.3,0.6,2.5,1.5,3.4c0.9,0.9,2.3,1.5,3.7,1.5l8.6-0.3     L63.2,29.7c-2,2-2,5.2,0,7.1c2,2,5.2,2,7.1,0L87,20.1l-0.3,8.6c-0.1,2.8,2.1,5.1,4.9,5.2c2.8,0.1,5.1-2.1,5.2-4.9l0.7-21.3     C97.5,6.3,97,5,96,4z" />
            <path d="M29.7,63.2L13,79.9l0.3-8.6c0.1-2.8-2.1-5.1-4.9-5.2C5.6,66,3.2,68.2,3.2,71L2.5,92.3C2.5,93.7,3,95,4,96     c1,1,2.3,1.5,3.7,1.5L29,96.8c2.8-0.1,5-2.4,4.9-5.2c-0.1-2.8-2.4-5-5.2-4.9L20.1,87l16.7-16.7c2-2,2-5.2,0-7.1     C34.8,61.2,31.6,61.2,29.7,63.2z" />
            <circle cx="50" cy="50" r="11.4" />
          </g>
        </g>
      </switch>
    </SvgWithDefaultStyler>
  );
}
