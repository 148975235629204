import getPermalinkedData from "../static_db/query_helpers/getPermalinkedData";
import PermalinkDataType from "../data_models/enums/PermalinkDataType";
import Permalinkable from "../data_models/Permalinkable";
import { isSerializedCity } from "../data_models/City/SerializedCity";
import { HookReturnValue } from "./HookReturnValue";

type ReturnProps = HookReturnValue<PermalinkDataType>;

const getPermalinkedDataType = (
  permalinkableData: Permalinkable
): PermalinkDataType => {
  if (isSerializedCity(permalinkableData)) {
    return PermalinkDataType.CITY;
  }

  throw new TypeError();
};

const usePermalinkedDataType = (permalink: string): ReturnProps => {
  const permalinkedData = getPermalinkedData(permalink);

  if (!permalinkedData) {
    return PermalinkDataType.NON_EXISTENT;
  }

  const permalinkedDataType = getPermalinkedDataType(permalinkedData);

  return permalinkedDataType;
};

export default usePermalinkedDataType;
