import styled, { css } from "styled-components";
import { defaultCopyFontFamily } from "../../../styles/font_styles";
import ellipsisTextCss from "../../style_mixins/ellipsisTextCss";
import PlayPauseButton from "../../buttons/PlayPauseButton";
import generateBootstrap4MediaQueries from "../../style_mixins/bootstrap4MediaQueries";

export const CardStyler = styled.div`
  width: 100%;
  height: 128px;

  display: grid;
  grid-template-rows: 80fr 40fr;
  grid-row-gap: 10px;
`;

const baseCardBottomStylerCss = css`
  display: grid;
  grid-template-columns: 1fr 40px;
`;

const mobilePortraitCardBottomStylerCss = css`
  ${baseCardBottomStylerCss};
  padding: 0 8px;
`;

const defaultCardBottomStylerCss = css`
  ${baseCardBottomStylerCss};
  padding: 0;
`;

export const CardBottomStyler = styled.div`
  ${generateBootstrap4MediaQueries({
    extraSmallCss: mobilePortraitCardBottomStylerCss,
    mediumCss: defaultCardBottomStylerCss
  })}
`;

export const CardPlayButton = styled(PlayPauseButton)`
  && {
    flex: 0;
    width: 40px;
    height: 40px;
  }
`;

export const CardMainTitle = styled.div`
  font-family: ${defaultCopyFontFamily};
  font-size: 24px;
  min-width: 0px;
  ${ellipsisTextCss};

  cursor: ${({ onClick }) => (onClick ? "pointer" : "default")};

  :hover {
    text-decoration: ${({ onClick }) => (onClick ? "underline" : "none")};
  }
`;

export const CardSubTitle = styled.div`
  font-family: ${defaultCopyFontFamily};
  font-size: 12px;
  color: #838383;
  ${ellipsisTextCss};
`;

// Needed to be able to truncate text using 1fr grid column size definition
// See https://css-tricks.com/flexbox-truncated-text/
export const CardCopyStyler = styled.div`
  min-width: 0%;
`;
