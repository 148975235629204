import styled from "styled-components";

const UnstyledButton = styled.button`
  padding: 0;
  border: none;
  outline: none;
  font: inherit;
  color: inherit;
  background: none;

  cursor: pointer;
`;

export default UnstyledButton;
