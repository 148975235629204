import { useDispatch } from "react-redux";
import { Dispatch } from "react";
import ServerErrorResponse from "../../data_models/ServerErrorResponse";

export const CLOSE_MODAL = "CLOSE_MODAL";

interface CloseModalAction {
  type: typeof CLOSE_MODAL;
}

export const useCloseModal = () => {
  const dispatch = useDispatch<Dispatch<CloseModalAction>>();

  return () => dispatch({ type: CLOSE_MODAL });
};

export const OPEN_IMAGE_MODAL = "OPEN_IMAGE_MODAL";

interface OpenImageModalAction {
  type: typeof OPEN_IMAGE_MODAL;
  imageUrl: string;
  creditee: string;
}

export const useOpenImageModal = () => {
  const dispatch = useDispatch<Dispatch<OpenImageModalAction>>();

  return (imageUrl: string, creditee: string) =>
    dispatch({
      type: OPEN_IMAGE_MODAL,
      imageUrl,
      creditee
    });
};

export const OPEN_EVENT_INFORMATION_MODAL = "OPEN_EVENT_INFORMATION_MODAL";

interface OpenEventInformationModalAction {
  type: typeof OPEN_EVENT_INFORMATION_MODAL;
  eventId: string;
}

export const useOpenEventInformationModal = () => {
  const dispatch = useDispatch<Dispatch<OpenEventInformationModalAction>>();

  return (eventId: string) =>
    dispatch({
      type: OPEN_EVENT_INFORMATION_MODAL,
      eventId
    });
};

export const OPEN_ERROR_MODAL = "OPEN_ERROR_MODAL";

interface OpenErrorModalAction {
  type: typeof OPEN_ERROR_MODAL;
  error: ServerErrorResponse;
}

export const useOpenErrorModal = () => {
  const dispatch = useDispatch<Dispatch<OpenErrorModalAction>>();

  return (error: ServerErrorResponse) =>
    dispatch({
      type: OPEN_ERROR_MODAL,
      error
    });
};
