import { useDispatch } from "react-redux";
import { Dispatch } from "react";

export const SET_ERROR = "SET_ERROR";

export interface ReduxErrorAction {
  type: typeof SET_ERROR;
  error: Error | null;
}

export const useSetError = () => {
  const dispatch = useDispatch<Dispatch<ReduxErrorAction>>();
  return (error: Error | null) => {
    dispatch({ type: SET_ERROR, error });
  };
};

export const useClearError = () => {
  const setError = useSetError();
  return () => {
    setError(null);
  };
};
