import IDable from "../data_models/IDable";

type StringTypedDictionary<T> = { [id: string]: T };

/**
 * This helper does one loop over an array of n elements and returns a dictionary where the keys are the ID of the elements.
 * This dictionary can be useful when having to do a lot of finds by id on a big array
 * @param array The array which should be transformed into a dictionary with the element's ID as key
 */
export const createIdDictionary = <T extends IDable>(
  array: T[]
): StringTypedDictionary<T> =>
  array.reduce<StringTypedDictionary<T>>(
    (dictionaryInProgress, currentElement) => ({
      ...dictionaryInProgress,
      [currentElement.id]: currentElement
    }),
    {}
  );
