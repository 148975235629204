import { useState, useEffect } from "react";
import SerializedClub from "../data_models/Club/SerializedClub";
import clubs from "../static_db/clubs";
import ServerErrorResponse from "../data_models/ServerErrorResponse";
import { dataByIdNotFound } from "../utils/error_builders/server_error_response_builders";
import findById from "../utils/findById";
import { HookReturnValue } from "./HookReturnValue";

type ReturnValue = HookReturnValue<SerializedClub>;

/**
 * Fetch an club by clubId from server
 * @param clubId The ID of the club to get. Can be null to allow this hook to called top-level.
 */
const useClub = (clubId: string | null): ReturnValue => {
  const [club, setClub] = useState<SerializedClub | null>(null);
  const [error, setError] = useState<ServerErrorResponse | null>(null);

  useEffect(() => {
    if (clubId === null) {
      //Do nothing when clubId is null, will keep this hook in 'Loading' state
      return;
    }

    const tempClub = findById(clubs, clubId);

    if (tempClub === undefined) {
      setError(dataByIdNotFound(clubId));
    } else {
      setClub(tempClub);
    }
  }, [clubId]);

  return error || club;
};

export default useClub;
