import { SET_FOCUSED_EVENT } from "./top_level_actions";
import { ReduxAction } from "../reducers";

export const focusedEventIdReducer = (
  state: string | null = null,
  action: ReduxAction
) => {
  switch (action.type) {
    case SET_FOCUSED_EVENT:
      return action.focusedEventId;
  }
  return state;
};
