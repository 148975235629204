import React from "react";
import moment from "moment";
import SerializedCity from "../../../data_models/City/SerializedCity";
import EventCardContainer from "../../Cards/EventCard/EventCardContainer";
import groupEventsByDateTodayOrAfter from "../../../utils/groupEventsByDateTodayOrAfter";
import SerializedEvent from "../../../data_models/Event/SerializedEvent";
import HeaderImageWithGroupedCardsView from "../HeaderImageWithGroupedCardsView/HeaderImageWithGroupedCardsView";

type IProps = {
  city: SerializedCity;
  events: SerializedEvent[];
};

export default function CityView({ city, events }: IProps) {
  const now = moment();
  const tomorrow = moment().add(1, "day");
  const nextWeek = moment().add(1, "week");

  const eventsPerDate = groupEventsByDateTodayOrAfter(events);

  const groupedEvents = eventsPerDate.map(({ date, events }) => {
    const isToday = date.isSame(now, "date");
    const isTomorrow = date.isSame(tomorrow, "date");
    const isWithinAWeek = date.isSameOrBefore(nextWeek, "date");

    const dateAsText = isToday
      ? "Tonight"
      : isTomorrow
      ? "Tomorrow"
      : isWithinAWeek
      ? date.format("dddd, MMMM Do")
      : date.format("MMMM Do");

    const cards = events.map(event => (
      <EventCardContainer event={event} key={"event_" + event.id} />
    ));

    return {
      groupKey: date.unix().toString(),
      groupHeader: dateAsText,
      cards
    };
  });

  return (
    <HeaderImageWithGroupedCardsView
      headerImageUrl={city.image.url}
      groupsWithCards={groupedEvents}
      noCardsMessage="There seem to be no events to display"
    />
  );
}
