import React from "react";
import InspectImageModalContent from "./InspectImageModal";
import { useCloseModal } from "../../../redux/modal_state/modal_actions";
import { ShowImageModalState } from "../../../redux/modal_state/modalReducer";

export default function InspectImageModalContentContainer({
  imageUrl,
  creditee
}: ShowImageModalState) {
  const closeModal = useCloseModal();
  const courtesyText = (
    <span>
      Courtesy to {creditee}.{" "}
      <a href={imageUrl} target="_blank" rel="noopener noreferrer">
        Click here
      </a>{" "}
      for source.
    </span>
  );

  return (
    <InspectImageModalContent
      imageUrl={imageUrl}
      onRequestClose={closeModal}
      courtesyText={courtesyText}
    />
  );
}
