import * as React from "react";
import { HeaderStyler, HeaderTitle } from "./Header.styles";

export default function Header() {
  return (
    <HeaderStyler>
      <HeaderTitle>Who is playing?</HeaderTitle>
    </HeaderStyler>
  );
}
