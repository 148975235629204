import React from "react";
import ClassNamePropType from "../../data_models/react_prop_types/ClassNamePropType";
import SvgWithDefaultStyler from "./SvgWithDefaultStyle";

export default function PauseCircle({ className }: ClassNamePropType) {
  return (
    <SvgWithDefaultStyler
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="icon/av/pause_circle_filled_24px">
        <path
          id="icon/av/pause_circle_filled_24px_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 0C5.376 0 0 5.376 0 12C0 18.624 5.376 24 12 24C18.624 24 24 18.624 24 12C24 5.376 18.624 0 12 0ZM10.8 16.8H8.40001V7.2H10.8V16.8ZM13.2 16.8H15.6V7.2H13.2V16.8Z"
          fill="black"
        />
      </g>
    </SvgWithDefaultStyler>
  );
}
