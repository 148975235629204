import { AnyAction } from "redux";
import {
  OPEN_IMAGE_MODAL,
  CLOSE_MODAL,
  OPEN_ERROR_MODAL,
  OPEN_EVENT_INFORMATION_MODAL
} from "./modal_actions";
import ServerErrorResponse from "../../data_models/ServerErrorResponse";

export enum ModalStateType {
  ERROR = "ERROR",
  CLOSED = "CLOSED",
  SHOW_IMAGE = "SHOW_IMAGE",
  EVENT_INFORMATION = "EVENT_INFORMATION"
}

interface ClosedModalState {
  type: typeof ModalStateType.CLOSED;
}

export interface ShowImageModalState {
  type: typeof ModalStateType.SHOW_IMAGE;
  imageUrl: string;
  creditee: string;
}

export interface EventInformationModalState {
  type: typeof ModalStateType.EVENT_INFORMATION;
  eventId: string;
}

export interface ErrorModalState {
  type: typeof ModalStateType.ERROR;
  error: ServerErrorResponse;
}

const initialState: ClosedModalState = {
  type: ModalStateType.CLOSED
};

export type ReduxModalState =
  | ClosedModalState
  | ShowImageModalState
  | EventInformationModalState
  | ErrorModalState;

const modalReducer = (
  state: ReduxModalState = initialState,
  action: AnyAction
): ReduxModalState => {
  switch (action.type) {
    case OPEN_IMAGE_MODAL:
      return {
        type: ModalStateType.SHOW_IMAGE,
        imageUrl: action.imageUrl,
        creditee: action.creditee
      };
    case OPEN_ERROR_MODAL:
      return {
        type: ModalStateType.ERROR,
        error: action.error
      };
    case OPEN_EVENT_INFORMATION_MODAL:
      return {
        type: ModalStateType.EVENT_INFORMATION,
        eventId: action.eventId
      };
    case CLOSE_MODAL:
      return {
        type: ModalStateType.CLOSED
      };
  }

  return state;
};

export default modalReducer;
