export enum SoundcloudPlayerState {
  PLAYING = "playing",
  PAUSED = "paused",
  LOADING = "loading",
  ENDED = "ended",
  ERROR = "error",
  DEAD = "dead"
}

interface OnHandler {
  (eventName: "time", handler: (updatedTime: number) => void): void;
  (eventName: "play-start", handler: () => void): void;
  (eventName: "buffering_start", handler: () => void): void;
  (eventName: "buffering_end", handler: () => void): void;
  (
    eventName: "state-change",
    handler: (newState: SoundcloudPlayerState) => void
  ): void;
}

export interface SoundcloudPlayer {
  seek: (time: number) => void;
  on: OnHandler;
  pause: () => void;
  play: () => void;
  isPlaying: () => boolean;
  kill: () => void;
}
