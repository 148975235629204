import SerializedClub from "../data_models/Club/SerializedClub";

export const berghain: SerializedClub = {
  name: "Berghain",
  id: "b246a380-c3fb-4019-8536-14aabedf1696",
  firstImage: {
    url:
      "https://www.electronicbeats.net/app/uploads/2018/07/Berghain2014-01-Wiki.jpg",
    creditee: "Electronic Beats"
  },
  secondImage: {
    url:
      "https://i.pinimg.com/originals/36/fa/8b/36fa8b32b6f30b843d6c4fc81c45cecf.jpg",
    creditee: "Stefan on Pinterest"
  },
  thirdImage: {
    url: "https://www.technostation.tv/wp-content/uploads/2016/12/pbar.jpg",
    creditee: "Techno Station "
  },
  description:
    "is set in an old Soviet power plant. With their elite techno ethics they always deliver on the highest quality dj’s and crowd. Definitly worth a visit.",
  floors: [
    { id: "c8d60116-2bb6-4726-b095-4b0fffeee6ea", name: "Berghain" },
    { id: "7dc1516d-b052-46d2-ac33-804b29b592f8", name: "Panorama Bar" },
    { id: "77da36b5-5fa9-49c6-8e39-85d9331a9bc9", name: "Saule" }
  ],
  residentAdvisorId: 5031
};

export const griessmuehle: SerializedClub = {
  name: "Griessmuehle",
  id: "97e6762e-f782-42c6-8ceb-ce57e776c0b6",
  firstImage: {
    url:
      "https://djmag.com/sites/default/files/styles/djmag_landscape__691x372_/public/article/image/griessmuehle-side-color.jpg?itok=36wsP-eo",
    creditee: "DJ Mag"
  },
  secondImage: {
    url:
      "https://www.electronicbeats.net/app/uploads/2018/10/griessmuehle_silos-1200x675.jpg",
    creditee: "Electronic Beats"
  },
  thirdImage: {
    url:
      "https://i.pinimg.com/originals/22/4b/cb/224bcb793f2f8b370a8b1f408e52e6b9.jpg",
    creditee: "Naomi de Rooij on Pinterest"
  },
  description:
    "is set in an old grain mill, giving it a gritty vibe. Often hosting a wide variety of types of parties you can expect high quality audio. With the audition of the outside area this is definitly an amazing club to visit.",
  floors: [
    { id: "8a318c2b-aac9-413c-9f33-95ca739ea978", name: "R_1" },
    { id: "571bfc64-3f48-48a2-9c00-347bb34b807c", name: "R_2" },
    { id: "cc796fd5-c414-4e97-85e4-a247d279aff5", name: "Garden" }
  ],
  residentAdvisorId: 46960
};

export const katerBlau: SerializedClub = {
  name: "Kater Blau",
  id: "da8b340f-e2d7-4f73-a751-c2254464773e",
  firstImage: {
    url:
      "https://upload.wikimedia.org/wikipedia/commons/9/9d/Kater_Blau_Berlin.JPG",
    creditee: "Wikimedia"
  },
  secondImage: {
    url:
      "https://www.bz-berlin.de/data/uploads/2018/10/dsc_5541_1540192316-768x432.jpg",
    creditee: "B.Z. Berlin"
  },
  thirdImage: {
    url:
      "https://www.depaart.com/wp-content/uploads/2017/01/eyeem-92890930.jpg",
    creditee: "Depaart"
  },
  description:
    "goes back far previously known as Bar 25 and Kater Holzig. Colorful and open the club won't shy away from hard techno, african beats, mellow music and much more.",
  floors: [
    { id: "d386ce88-0c7f-423c-82ce-83aec8e3943f", name: "Big Inside" },
    { id: "bfcdd8e1-998f-45a7-bf9b-46567a4d9e8e", name: "Medium Inside" },
    { id: "13df7d20-6823-45a4-b0a8-40b1a71deb64", name: "Small Inside" }
  ],
  residentAdvisorId: 99161
};

export enum WildeRenateFloor {
  RED_ROOM = "Red Room",
  GREEN_ROOM = "Green Room",
  BLACK_ROOM = "Black Room"
}

export const wildeRenate: SerializedClub = {
  name: "Wilde Renate",
  id: "a4eab261-4477-4b9d-b068-8124d9678466",
  firstImage: {
    url:
      "http://www.deephouseamsterdam.com/wp-content/uploads/2015/07/Wilde-Renate.jpg",
    creditee: "Deep House Amsterdam"
  },
  secondImage: {
    url:
      "https://www.theclubmap.com/wp-content/uploads/2012/12/renate-garten-by-marcus-schroeder.jpg",
    creditee: "Marcus Schröder"
  },
  thirdImage: {
    url:
      "https://ak.jogurucdn.com/media/image/p25/place-2018-05-6-13-38c00afad4203930415f39281d67dd6d.jpg",
    creditee: "Trip Hobo"
  },
  description:
    "is one of the most colorful clubs. With a beautiful garden and it's close quarters inside you will see new things every time you visit.",
  floors: [
    { id: "b3195f89-c645-4150-82e0-24ac262c6ac6", name: "Red Room" },
    { id: "7fe8cd6c-cb0f-454b-a33e-10943459ebbd", name: "Green Room" },
    { id: "0999c83d-ae40-4c68-860c-0303ba8db7b0", name: "Black Room" }
  ],
  residentAdvisorId: 8556
};

export const sisyphos: SerializedClub = {
  name: "Sisyphos",
  id: "d2247605-7ca1-48c8-aa02-0e491bc86ad9",
  firstImage: {
    url: "https://media.qiez.de/uploads/2018/06/21122550/sisyphos-910x511.jpg",
    creditee: "QIEZ"
  },
  secondImage: {
    url:
      "https://i.pinimg.com/originals/ad/86/43/ad86439b91300e84e7dd06b692e4a638.jpg",
    creditee: "Marilu' Cardin on Pinterest"
  },
  thirdImage: {
    url:
      "https://clubs-reviews.com/upload/iblock/e36/e368ca5a07945e513e31754d87ce64a4.jpg",
    creditee: "@thomas.gerber"
  },
  description:
    "is more like a small festival that happens every weekend. With high variety of floors, only doing weekenders and a lake spending your entire weekend here is definitly worth it.",
  floors: [
    { id: "9127c0b1-98de-44a7-8f8a-bcc5e63c792f", name: "Hammahalle" },
    { id: "450f7453-d95e-405f-8742-02f907579eb0", name: "Beach" },
    { id: "fbf0c9c0-4554-455c-a5ae-586e6586082c", name: "Disco Tent" },
    { id: "4dd7358b-8317-434c-8721-bda51544f7d4", name: "Live Room" },
    { id: "6425791d-187f-4d59-a88b-b353938d7ed5", name: "Lounge Area" },
    { id: "ca746631-be79-4584-957c-5870a9211050", name: "Side Room" }
  ],
  residentAdvisorId: 17118
};

export const aboutBlank: SerializedClub = {
  name: "about://blank",
  id: "e57e670b-9dab-4520-9034-c3ea24d7d478",
  firstImage: {
    url:
      "https://www.residentadvisor.net/images/news/2018/about-blank-birthday.jpg",
    creditee: "Resident Advisor"
  },
  secondImage: {
    url:
      "https://taz.de/picture/1952878/948/be2911b2cdde263394d7da280b7343e9_edited_69221601_0a9a1c03af.jpeg",
    creditee: "The Cool Guide"
  },
  thirdImage: {
    url: "https://media.timeout.com/images/101285229/630/472/image.jpg",
    creditee: "Ema Discordant"
  },
  description:
    "started off as an illegal rave an grew to the well known club it is today. With a garden and gritty atmosphere, you hear hard techno till experimental sounds.",
  floors: [
    { id: "befbf81b-220e-419b-8741-ea3bb599cf8c", name: "MDF" },
    { id: "86b40349-b5c8-44f4-9204-6c48eb84a2d1", name: "Lobby" }
  ],
  residentAdvisorId: 28354
};

export const kitkat: SerializedClub = {
  name: "KitKat",
  id: "fcec0865-e892-40e3-8377-cae1ec7d26d9",
  firstImage: {
    url: "https://www.electronicbeats.net/app/uploads/2018/03/kit_kat_club.jpg",
    creditee: "Electronic Beats"
  },
  secondImage: {
    url:
      "https://www.wearelatinlive.com/media/CACHE/images/hero/the-technos-good-and-the-fuckings-good-so-why-not-do-them-together-body-image-1441104658/58100eaab580bc4b58fa11d3e45e05f1.jpg",
    creditee: "Latin Live"
  },
  thirdImage: {
    url:
      "https://i.pinimg.com/originals/b6/f5/5a/b6f55af4de6e5197336bb2847314ac27.jpg",
    creditee: "Pinterest"
  },
  description:
    "is known for it's sex-positive attitude. Raving is in the blood of KitKat where you will find not only current music but also psytrance and more.",
  floors: [],
  residentAdvisorId: 10546
};

const clubs: SerializedClub[] = [
  berghain,
  griessmuehle,
  katerBlau,
  wildeRenate,
  sisyphos,
  aboutBlank,
  kitkat,
  {
    name: "Ritter Butzke",
    id: "5776e283-a68f-42c0-963b-880248263479",
    firstImage: {
      url:
        "https://www.residentadvisor.net/images/clubs/lg/de-ritter-butzke-berlin.jpg",
      creditee: "Resident Advisor"
    },
    secondImage: {
      url:
        "https://www.berliner-silvester.de/binaries/asset/image_assets/2624276/source/1437060643/624x468/",
      creditee: "Enrico Verworner"
    },
    thirdImage: {
      url:
        "https://cdn.shortpixel.ai/client/q_glossy,ret_img/https://setoftheday.com/wp-content/gallery/ritter-butzke/ritter-butzke-club-berlin-2-2.jpg",
      creditee: "Set of the Day"
    },
    description:
      "is built in old construction halls. It hosts bigger artists as well as more overall events.",
    floors: [],
    residentAdvisorId: 6950
  },
  {
    name: "Casco Amsterdam",
    id: "273c0042-b0f2-4bf0-ad84-5e06f173704b",
    firstImage: {
      url: "https://cascoamsterdam.nl/wp-content/uploads/2019/07/cornelie1.jpg",
      creditee: "Casco Amsterdam"
    },
    secondImage: {
      url: "https://static.dnls.nl/uploads/1/104990/6JUYRyo0cu0/discobal_1.jpg",
      creditee: "DNLS"
    },
    thirdImage: {
      url:
        "https://www.residentadvisor.net/images/events/flyer/2019/12/nl-1231-1313104-1470993-back.jpg",
      creditee: "Resident Advisor"
    },
    description:
      "is located in Amsterdam North. This venue has one big hall to host parties",
    floors: [],
    residentAdvisorId: 137601
  },
  {
    name: "WesterUnie",
    id: "6a659b8c-a950-4d0a-b46a-fd1484c4654f",
    firstImage: {
      url:
        "https://www.westerunie.nl/wp-content/uploads/2019/07/westerunieWL-ADE_1920x1200.jpg",
      creditee: "WesterUnie"
    },
    secondImage: {
      url:
        "https://media-cdn.tripadvisor.com/media/photo-s/08/f1/6e/81/westerunie.jpg",
      creditee: "TripAdvisor"
    },
    thirdImage: {
      url: "https://www.residentadvisor.net/images/clubs/lg/westerunie.jpg",
      creditee: "Resident Advisor"
    },
    description:
      "is renovated as an old transformator house next to the Westergasfabriek. This venue hosts some hard techno and house parties.",
    floors: [],
    residentAdvisorId: 7211
  },
  {
    name: "De Marktkantine",
    id: "98751239-bb46-4fcc-9c3f-79375909efa9",
    firstImage: {
      url:
        "https://clubbable.blob.core.windows.net/medias/De-Marktkantine-Amsterdam?timestamp=636671712780307100",
      creditee: "Clubbable"
    },
    secondImage: {
      url:
        "https://www.yourlittleblackbook.me/wp-content/uploads/2014/11/de-marktkantine-amsterdam.jpg",
      creditee: "Your Little Black Book"
    },
    thirdImage: {
      url:
        "https://nachtvoordenacht.nl/wp-content/uploads/2018/01/marktkantine.jpg",
      creditee: "Nacht voor de Nacht"
    },
    description:
      "is hosted in a massive building with not only dance areas but also a theatre. This venue often hosts some bigger electronical music artists to play their decks.",
    floors: [],
    residentAdvisorId: 94941
  },
  {
    name: "RADION",
    id: "f4c0bd28-64a9-40cf-b65e-bd79e6b98f5a",
    firstImage: {
      url:
        "https://3voor12.vpro.nl/.imaging/stk/3v12/opengraph/dam/3voor12/nieuws/2016/ADE/161021-3v12-Radion-3543.jpg/jcr:content/161021-3v12-Radion-3543.jpg.jpg",
      creditee: "3voor12"
    },
    secondImage: {
      url:
        "https://www.selektor.nl/wp-content/uploads/2019/09/radion-amsterdam-1024x684.jpg",
      creditee: "Selektor"
    },
    thirdImage: {
      url:
        "https://radion.amsterdam/uploads/main/_w960h600/21992727_801851849976115_5506590666643102997_o.jpg",
      creditee: "Radion"
    },
    description:
      "is one of the clubs in Amsterdam with a 24h permit, hosted in the old dentistry university buildings.",
    floors: [],
    residentAdvisorId: 91202
  },
  {
    name: "Shelter Amsterdam",
    id: "dc555dab-8f88-494a-b225-5189e5a3b96c",
    firstImage: {
      url:
        "https://scontent-ams4-1.xx.fbcdn.net/v/t31.0-0/p180x540/21167128_1961266114119647_3129203491526567245_o.jpg?_nc_cat=109&_nc_oc=AQnf76bsZbo7l2UDbmFHjPsXBCMd953run5NKY0AVKQIAJbmAh3RpAL_ScfepN7HYYrR1BHs1TWFEL2Wlf7hRRfg&_nc_ht=scontent-ams4-1.xx&oh=6c1d8689fb1162b3c3f9fd43deb1dfab&oe=5E56E0ED",
      creditee: "Shelter"
    },
    secondImage: {
      url:
        "https://www.residentadvisor.net/images/reviews/2016/shelter-review-ra-3.jpg",
      creditee: "Resident Advisor"
    },
    thirdImage: {
      url:
        "https://scontent-ams4-1.xx.fbcdn.net/v/t31.0-0/p480x480/22770570_1985328501713408_2851133398525379695_o.jpg?_nc_cat=101&_nc_oc=AQnnDDI9sR9QzijqJBvIljAncPM3xv7ivJsKMgl5k9WXGazbe16TzB09uKmcNi2WbtkwdN23n2rlg4VDpL2jvNqC&_nc_ht=scontent-ams4-1.xx&oh=2969d5b3bef4f514f4b188d698cb186d&oe=5E56AD04",
      creditee: "Shelter"
    },
    description:
      "is entered through a hole in the ground just north of Central Station you'll find a venue which hosts some of the harder techno parties.",
    floors: [],
    residentAdvisorId: 124413
  },
  {
    name: "Gashouder",
    id: "a40092f5-4204-4691-8c6a-1ed28f2a9b75",
    firstImage: {
      url: "https://i.ytimg.com/vi/zyPGZDsCnlU/maxresdefault.jpg",
      creditee: "Amsterdam Electronic"
    },
    secondImage: {
      url:
        "https://westergas.nl/en/wp-content/uploads/sites/2/2019/03/Gashouder-Buiten-1672x894.jpg",
      creditee: "Westergas"
    },
    thirdImage: {
      url:
        "https://fourfourmag.com/wp-content/uploads/2017/09/gashouder-amsterdam-awakenings-adam-beyer.jpg",
      creditee: "Four/Four Magazine"
    },
    description:
      "is an old gas container that is now the ground where some of the bigger and most spectacular techno parties are hosted.",
    floors: [
      {
        id: "01e9b833-f915-4098-ae02-b22f764a4932",
        name: "Gashouder"
      }
    ],
    residentAdvisorId: 3154
  }
];

export default clubs;
