import React from "react";
import ClassNamePropType from "../../data_models/react_prop_types/ClassNamePropType";
import SvgWithDefaultStyler from "./SvgWithDefaultStyle";

export default function BirthdayCake({ className }: ClassNamePropType) {
  return (
    <SvgWithDefaultStyler
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 128 128"
      x="0px"
      y="0px"
      className={className}
    >
      <path d="M115,58h-2.12A18,18,0,0,0,104,44.44V20.08A4.09,4.09,0,0,0,99.92,16H90.08A4.09,4.09,0,0,0,86,20.08V44.44A18,18,0,0,0,77.12,58H72.85A15,15,0,0,0,66,47.33V30.08A4.09,4.09,0,0,0,61.92,26H54.08A4.09,4.09,0,0,0,50,30.08V47.33A15,15,0,0,0,43.15,58H38.82A12,12,0,0,0,34,50.27V40.08A4.09,4.09,0,0,0,29.92,36H24.08A4.09,4.09,0,0,0,20,40.08V50.27A12,12,0,0,0,15.18,58H13A13.1,13.1,0,0,0,0,71.17V126a2,2,0,0,0,2,2H126a2,2,0,0,0,2-2V71.17A13.1,13.1,0,0,0,115,58ZM90,20.08a.08.08,0,0,1,.08-.08h9.83a.08.08,0,0,1,.08.08V42.72a17.69,17.69,0,0,0-10,0ZM95,46a14,14,0,0,1,13.84,12H81.16A14,14,0,0,1,95,46ZM54,30.08a.08.08,0,0,1,.08-.08h7.83a.08.08,0,0,1,.08.08V45.56a14.61,14.61,0,0,0-8,0ZM58,49a11,11,0,0,1,10.81,9H47.19A11,11,0,0,1,58,49ZM24,40.08a.08.08,0,0,1,.08-.08h5.83a.08.08,0,0,1,.08.08v8.31a11.59,11.59,0,0,0-6,0ZM27,52a8,8,0,0,1,7.74,6H19.26A8,8,0,0,1,27,52ZM4,84.83H24.8V91.5a10,10,0,0,1-10,10H14a10,10,0,0,1-10-10ZM124,124H4V101.28a14,14,0,0,0,10,4.22h.8a14,14,0,0,0,14-14V84.9a10.4,10.4,0,1,1,20.8,0v6.6a14,14,0,0,0,14,14h.8a14,14,0,0,0,14-14V84.9a10.4,10.4,0,1,1,20.8,0v6.6a14,14,0,0,0,14,14h.8a14,14,0,0,0,10-4.22ZM53.6,91.5V84.83H74.4V91.5a10,10,0,0,1-10,10h-.8A10,10,0,0,1,53.6,91.5Zm70.4,0a10,10,0,0,1-10,10h-.8a10,10,0,0,1-10-10V84.83H124Zm0-10.67H102.61a14.39,14.39,0,0,0-27.61,0H53a14.39,14.39,0,0,0-27.61,0H4V71.17A9.1,9.1,0,0,1,13,62H115a9.1,9.1,0,0,1,9,9.17Z" />
      <path d="M95,0,92.17,2.93a4.25,4.25,0,0,0,0,5.86,3.9,3.9,0,0,0,5.66,0,4.25,4.25,0,0,0,0-5.86Z" />
      <path d="M58,10l-2.83,2.93a4.25,4.25,0,0,0,0,5.86,3.9,3.9,0,0,0,5.66,0,4.25,4.25,0,0,0,0-5.86Z" />
      <path d="M27,20l-2.83,2.93a4.25,4.25,0,0,0,0,5.86,3.9,3.9,0,0,0,5.66,0,4.25,4.25,0,0,0,0-5.86Z" />
      <path d="M39,78a7,7,0,1,0,7,7A7,7,0,0,0,39,78Zm0,10a3,3,0,1,1,3-3A3,3,0,0,1,39,88Z" />
      <path d="M89,78a7,7,0,1,0,7,7A7,7,0,0,0,89,78Zm0,10a3,3,0,1,1,3-3A3,3,0,0,1,89,88Z" />
    </SvgWithDefaultStyler>
  );
}
