import cheerio from "cheerio";
import { Moment } from "moment";
import corsResistantFetch from "../corsResistantFetch";

const createCityDayUrl = (
  residentAdvisorCityName: string,
  residentAdvisorCountryKey: string,
  date: Moment
) =>
  `https://www.residentadvisor.net/events/${residentAdvisorCountryKey}/${residentAdvisorCityName}/day/${date.format(
    "YYYY-MM-DD"
  )}`;

const eventHrefEventIdRegex = /\/events\/([0-9]+)/;

const scrapeResidentAdvisorDay = async (
  residentAdvisorCityName: string,
  residentAdvisorCountryKey: string,
  date: Moment
): Promise<number[]> => {
  const dayUrl = createCityDayUrl(
    residentAdvisorCityName,
    residentAdvisorCountryKey,
    date
  );

  const site = await corsResistantFetch(dayUrl);
  const siteBody = await site.text();

  const siteDomNavigator = cheerio.load(siteBody);
  const eventItems = siteDomNavigator("article.event-item a[itemprop='url']");

  const eventIds: number[] = [];

  eventItems.each((index, element) => {
    const eventHref = element.attribs["href"];
    const eventIdMatch = eventHrefEventIdRegex.exec(eventHref);

    if (eventIdMatch === null) {
      throw new Error(`Failed to extract event id out of href "${eventHref}"`);
    }

    // eventId is in first group equaling position 1
    const eventId = parseInt(eventIdMatch[1]);

    eventIds.push(eventId);
  });

  return eventIds;
};

export default scrapeResidentAdvisorDay;
