import React from "react";
import {
  LineupDisplayerIconCopyRow,
  LineupDisplayerHeaderPlayButton,
  LineupDisplayerHeaderCopyStyler,
  LineupDisplayerSetItemPlayButton,
  LineupDisplayerSetItemCopyStyler,
  LineupDisplayerStyler
} from "./LineupDisplayer.styles";
import P from "../../Copy/P";

interface IProps {
  eventSets: {
    setId: string;
    setTitle: string;
    isPlayable: boolean;
  }[];

  focusedSetId: string | null;
  isPlayerPaused: boolean;

  onAllPlayClick: () => void;
  onSetPlayClick: (setId: string) => void;
}

export default function LineupDisplayer({
  eventSets,

  focusedSetId,
  isPlayerPaused,

  onAllPlayClick,
  onSetPlayClick
}: IProps) {
  const focusedEventSet = eventSets.find(set => set.setId === focusedSetId);
  const alphabeticallySortedEventSets = eventSets.sort((eventSet1, eventSet2) =>
    eventSet1.setTitle.localeCompare(eventSet2.setTitle)
  );
  const isAnEventSetFocused = focusedEventSet !== undefined;
  const isThereAtLeastOnePlayableSet = eventSets.some(
    ({ isPlayable }) => isPlayable
  );

  const lineUpHeader = (
    <LineupDisplayerIconCopyRow>
      <LineupDisplayerHeaderPlayButton
        iconTheme="circle"
        isPaused={!isAnEventSetFocused || isPlayerPaused}
        onClick={onAllPlayClick}
        isDisabled={!isThereAtLeastOnePlayableSet}
        tooltip={
          !isThereAtLeastOnePlayableSet
            ? "No preview available"
            : !isAnEventSetFocused
            ? "Listen to what kind of music will be playing at this event"
            : isPlayerPaused
            ? "Resume listening to event previews"
            : "Pause event previews"
        }
      ></LineupDisplayerHeaderPlayButton>
      <LineupDisplayerHeaderCopyStyler>Lineup</LineupDisplayerHeaderCopyStyler>
    </LineupDisplayerIconCopyRow>
  );

  const setRow = alphabeticallySortedEventSets.map(set => (
    <LineupDisplayerIconCopyRow key={`linup_set_item_${set.setId}`}>
      <LineupDisplayerSetItemPlayButton
        iconTheme="circle"
        isPaused={focusedSetId !== set.setId || isPlayerPaused}
        onClick={() => onSetPlayClick(set.setId)}
        isDisabled={!set.isPlayable}
        tooltip={
          !set.isPlayable
            ? "No preview available"
            : focusedSetId !== set.setId
            ? "Listen to what kind of music you can expect at this set"
            : isPlayerPaused
            ? "Resume listening to event previews"
            : "Pause event previews"
        }
      />
      <LineupDisplayerSetItemCopyStyler>
        {set.setTitle}
      </LineupDisplayerSetItemCopyStyler>
    </LineupDisplayerIconCopyRow>
  ));

  return (
    <LineupDisplayerStyler>
      {lineUpHeader}
      {setRow.length > 0 ? setRow : <P>TBA</P>}
    </LineupDisplayerStyler>
  );
}
