import soundcloud from "soundcloud";
import ReactGA from "react-ga";
import { createBrowserHistory } from "history";
import initializeSegment from "./initializeSegment";

const initializeApp = () => {
  soundcloud.initialize({
    client_id: process.env.REACT_APP_SOUNDCLOUD_CLIENT_ID
  });

  const gaTrackingId = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID;
  const segmentWriteKey = process.env.REACT_APP_SEGMENT_WRITE_KEY;

  if (gaTrackingId) {
    ReactGA.initialize(gaTrackingId);
    // Track initial page view
    ReactGA.pageview(window.location.pathname + window.location.search);

    // Set up tracking any future route changes to be tracked as well
    const history = createBrowserHistory();
    history.listen(location => {
      ReactGA.set({ page: location.pathname + location.search });
      ReactGA.pageview(location.pathname + location.search);
    });
  }

  if (segmentWriteKey) {
    initializeSegment(segmentWriteKey);
  }
};

export default initializeApp;
