import React, { ReactNode } from "react";
import VisualCard from "../../Cards/VisualCard/VisualCard";
import {
  EventMainInformationDisplayerTicketTitleStyler,
  EventMainInformationDisplayerTicketPriceStyler,
  EventMainInformationDisplayerStyler,
  EventMainInformationDisplayerHighlightsTicketsStyler,
  EventMainInformationDisplayerHeaderAndContentStyler,
  EventMainInformationDisplayerHeaderCopyStyler,
  EventMainInformationDisplayerHighlightedSetsStyler,
  EventMainInformationDisplayerTicketHeaderAndContentStyler,
  EventMainInformationDisplayerTicketsStyler,
  EventMainInformationDisplayerVenueImagesStyler,
  EventMainInformationDisplayerVenueDescriptionStyler
} from "./EventMainInformationDisplayer.styles";

interface IProps {
  venueName: string;
  venueDescription: string;
  highlightedSets: {
    setId: string;
    setImageUrl: string;
    setTitle: string;
    isPlayable: boolean;
  }[];
  onlineTicketInformation?: {
    url: string;
    price: number;
  };
  doorTicketPrice?: number;

  venueImageComponents: ReactNode[];

  setIdThatIsFocused: string | null;
  isPlayerFocusedAndPaused: boolean;
  onSetPlayClick: (setId: string) => void;
}

export default function EventMainInformationDisplayer({
  venueName,
  venueDescription,
  highlightedSets,
  onlineTicketInformation,
  doorTicketPrice,

  venueImageComponents,

  setIdThatIsFocused,
  isPlayerFocusedAndPaused,
  onSetPlayClick
}: IProps) {
  const onlineTicketInformationDisplayer = onlineTicketInformation && (
    <div>
      <EventMainInformationDisplayerTicketTitleStyler>
        Online
      </EventMainInformationDisplayerTicketTitleStyler>
      <EventMainInformationDisplayerTicketPriceStyler
        href={onlineTicketInformation.url}
      >
        €{onlineTicketInformation.price}
      </EventMainInformationDisplayerTicketPriceStyler>
    </div>
  );
  const doorTicketInformationDisplayer = (
    <div>
      <EventMainInformationDisplayerTicketTitleStyler>
        Doorsale {!onlineTicketInformation && "only"}
      </EventMainInformationDisplayerTicketTitleStyler>
      <EventMainInformationDisplayerTicketPriceStyler>
        {doorTicketPrice ? `€${doorTicketPrice}` : "TBA"}
      </EventMainInformationDisplayerTicketPriceStyler>
    </div>
  );

  return (
    <EventMainInformationDisplayerStyler>
      <EventMainInformationDisplayerHighlightsTicketsStyler>
        <EventMainInformationDisplayerHeaderAndContentStyler>
          <EventMainInformationDisplayerHeaderCopyStyler>
            Highlights
          </EventMainInformationDisplayerHeaderCopyStyler>
          <EventMainInformationDisplayerHighlightedSetsStyler>
            {highlightedSets.map(set => (
              <VisualCard
                imageUrl={set.setImageUrl}
                mainTitle={set.setTitle}
                objectFit="contain"
                playButtonProps={{
                  onPlayButtonClick: () => onSetPlayClick(set.setId),
                  isDisabled: !set.isPlayable,
                  showPauseButton:
                    setIdThatIsFocused === set.setId &&
                    !isPlayerFocusedAndPaused,
                  tooltip: !set.isPlayable
                    ? "No preview available"
                    : setIdThatIsFocused !== set.setId
                    ? "Listen to what this set will sound like"
                    : isPlayerFocusedAndPaused
                    ? "Resume preview"
                    : "Pause preview"
                }}
              />
            ))}
          </EventMainInformationDisplayerHighlightedSetsStyler>
        </EventMainInformationDisplayerHeaderAndContentStyler>

        <EventMainInformationDisplayerTicketHeaderAndContentStyler>
          <EventMainInformationDisplayerHeaderCopyStyler>
            Tickets
          </EventMainInformationDisplayerHeaderCopyStyler>
          <EventMainInformationDisplayerTicketsStyler>
            {onlineTicketInformationDisplayer}
            {doorTicketInformationDisplayer}
          </EventMainInformationDisplayerTicketsStyler>
        </EventMainInformationDisplayerTicketHeaderAndContentStyler>
      </EventMainInformationDisplayerHighlightsTicketsStyler>

      <EventMainInformationDisplayerHeaderAndContentStyler>
        <EventMainInformationDisplayerHeaderCopyStyler>
          {venueName}
        </EventMainInformationDisplayerHeaderCopyStyler>
        <EventMainInformationDisplayerVenueImagesStyler>
          {venueImageComponents}
        </EventMainInformationDisplayerVenueImagesStyler>
        <EventMainInformationDisplayerVenueDescriptionStyler>
          <b>{venueName}</b> {venueDescription}
        </EventMainInformationDisplayerVenueDescriptionStyler>
      </EventMainInformationDisplayerHeaderAndContentStyler>
    </EventMainInformationDisplayerStyler>
  );
}
