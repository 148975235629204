import React, { ReactNode } from "react";
import Modal from "react-modal";
import styled, { css } from "styled-components";

function ModalAdapter({
  className,
  modalClassName,
  ...props
}: Omit<Omit<Modal.Props, "portalClassName">, "className"> & {
  modalClassName?: string;
  className?: string;
  children?: ReactNode;
}) {
  return (
    <Modal className={modalClassName} portalClassName={className} {...props} />
  );
}

/** To make Modal not overlap Player component we assign the Modal to grid rows & columns */
const occupyModalAreaCss = css`
  grid-row: pre-header / pre-footer;
  grid-column: pre-single-column / post-single-column;
`;

const StyledModal = styled(ModalAdapter).attrs({
  overlayClassName: "Overlay",
  modalClassName: "Modal"
})`
  /* The ModalAdapter should only take up the grid areas when open, since otherwise it blocks onClick events on items below */
  ${({ isOpen }) => (isOpen ? occupyModalAreaCss : "")}

  position: relative;

  .Modal {
    position: relative;
  }
  .Overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(240, 240, 240, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export default StyledModal;
