import { failedToFindHTMLElementById } from "./error_builders/client_error_builders";

const getElementByIdOrThrow = (id: string): HTMLElement => {
  const supposedElement = document.getElementById(`${id}`);

  if (supposedElement === null) {
    throw failedToFindHTMLElementById(id);
  }

  return supposedElement;
};

export default getElementByIdOrThrow;
